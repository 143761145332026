import { getBuildingTheme } from 'store/theme/actions';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from './use-search-params.hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import { defaultTheme, DefaultThemeWithFonts } from '@hqo/react-components-library/dist/molecules/theme';
import { hqoTheme } from '@hqo/react-components-library';

export const useBuildingTheme = (): DefaultThemeWithFonts => {
  const dispatch = useDispatch();
  const brandTheme = useSelector(selectBrandTheme);

  const { buildingUuid } = useSearchParams();

  useEffect(() => {
    if (buildingUuid) {
      dispatch(getBuildingTheme.request(buildingUuid as string));
    }
  }, [dispatch, getBuildingTheme, buildingUuid]);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...defaultTheme.colors,
          $primary: brandTheme.primary_color,
          $secondary: brandTheme.secondary_color,
          primary: brandTheme.primary_color,
          secondary: brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      } as unknown as DefaultThemeWithFonts;
    }
    return defaultTheme;
  }, [brandTheme, defaultTheme, buildingUuid]);
};
