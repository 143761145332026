import { CheckoutPage, ErrorPage, HistoricalReceiptPage, QuickCheckout, ReceiptPage, RedirectPage } from 'pages';
import {
  ERROR_PATH,
  HISTORICAL_RECEIPT_PATH,
  QUICK_CHECKOUT_PATH,
  RECEIPT_PATH,
  REDIRECT_PATH,
  ROOT_PATH,
} from 'shared/consts';
import { Route, Switch } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { DefaultRedirect } from './default-redirect';
import { PaymentIFrame } from 'components/paymentIFrame';
import { PrivateRoute } from 'components/private-route';
import React from 'react';
import { CancelBookingPage } from 'pages/cancelBookingPage';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Switch>
      <PrivateRoute path={RECEIPT_PATH} component={ReceiptPage} exact />
      <PrivateRoute path={HISTORICAL_RECEIPT_PATH} component={HistoricalReceiptPage} exact />
      <Route path="/cancel-booking" component={CancelBookingPage} />
      <PrivateRoute path={`${REDIRECT_PATH}`} component={RedirectPage} />
      <PrivateRoute path={ROOT_PATH} component={CheckoutPage} />
      <PrivateRoute path={`${ROOT_PATH}/:cartId`} component={CheckoutPage} />
      <PrivateRoute path={`${QUICK_CHECKOUT_PATH}`} component={QuickCheckout} />
      <PrivateRoute path="/iframe" component={PaymentIFrame} />
      <Route path={ERROR_PATH} component={ErrorPage} />
      <Route component={DefaultRedirect} />
    </Switch>
  </AppContentWrapper>
);
