import React from 'react';
import { useIntl } from 'react-intl';
import { ResourceFeature } from 'store/transactions/types';
import { AmenityContainer, DefaultAmenityDescription } from './styles';
import { UrlAmenity } from './url-amenity';

interface AmenityProps {
  amenity: ResourceFeature;
}

export const Amenity = ({ amenity }: AmenityProps): JSX.Element => {
  const intl = useIntl();
  const { cta_type, name } = amenity;
  if (cta_type) {
    return <UrlAmenity amenity={amenity} />;
  }

  return (
    <AmenityContainer>
      <DefaultAmenityDescription>{intl.formatMessage({ id: name })}</DefaultAmenityDescription>
    </AmenityContainer>
  );
};
