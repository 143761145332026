import { ACTION_STATUSES } from 'shared/consts';
import { BOOKING_STATUSES } from 'shared/consts/booking-statuses';

export const getReceiptTitle = (bookingStatus: string, cancellationStatus: ACTION_STATUSES) => {
  if (cancellationStatus === ACTION_STATUSES.FULFILLED) {
    return 'resource_cancelled';
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return 'booking_confirmed';
  }
  if (bookingStatus === BOOKING_STATUSES.PENDING) {
    return 'resource_requested';
  }
  return 'default_receipt';
};
