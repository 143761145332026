import { BOOKING_STATUSES } from 'shared/consts/booking-statuses';
import { ACTION_STATUSES } from '../shared/consts';

export const getReceiptSubtitle = (bookingStatus: string, cancellationStatus: ACTION_STATUSES): string => {
  if (cancellationStatus === ACTION_STATUSES.FULFILLED) {
    return 'resource_booking_cancelled_text';
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return 'resource_booking_confirmation_text';
  }
  if (bookingStatus === BOOKING_STATUSES.PENDING) {
    return 'resource_requested_subtitle';
  }
  return 'default_receipt';
};
