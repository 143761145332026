import { IFRAME_WIDTH } from 'shared/consts';
import { ModalWrapper } from '@hqo/react-components-library';
import styled from 'styled-components';

interface ModalContainerProps {
  withButton?: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 56px;
  height: 490px;

  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    width: 100%;
    height: calc(100vh - 48px);
    padding: 24px 0;
    align-items: flex-start;

    ${({ withButton }) =>
      withButton &&
      `
    .right-pane {
      height: 64vh;
    }
    `}
  }
`;

export const StyledModalWrapper = styled(ModalWrapper)`
  width: ${IFRAME_WIDTH};
  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    width: 100%;
    .modal-wrapper-exit-button {
      z-index: 15;
    }
  }
`;
