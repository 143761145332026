export const formatCurrency = (
  currencyValue: number,
  currencyType: string,
  isDiscount = false,
  locale: string,
): string => {
  const value = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyType }).format(currencyValue);

  return isDiscount ? `-${value}` : value;
};
