import { PromoCodeCartRequestParams, CompleteCartDto, Order, SubmitOrderDto, TransactionObject } from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';
import { PaymentError } from 'shared/consts/payment-error-types';

export const getCart = createAsyncAction('GET_CART_REQUEST', 'GET_CART_SUCCESS', 'GET_CART_FAILURE')<
  string,
  Order,
  FailureActionPayload
>();

export const submitCart = createAsyncAction('SUBMIT_CART_REQUEST', 'SUBMIT_CART_SUCCESS', 'SUBMIT_CART_FAILURE')<
  SubmitOrderDto,
  Order,
  PaymentError
>();

export const complete3DSCart = createAsyncAction(
  'COMPLETE_3DS_CART_REQUEST',
  'COMPLETE_3DS_CART_SUCCESS',
  'COMPLETE_3DS_CART_FAILURE',
)<CompleteCartDto, TransactionObject, FailureActionPayload>();

export const resetComplete3DSCart = createAction('COMPLETE_3DS_CART_RESET')<void>();

export const applyPromoCodeToCart = createAsyncAction(
  'APPLY_PROMO_CODE_TO_CART_REQUEST',
  'APPLY_PROMO_CODE_TO_CART_SUCCESS',
  'APPLY_PROMO_CODE_TO_CART_FAILURE',
)<PromoCodeCartRequestParams, Order, FailureActionPayload>();

export const removePromoCodeFromCart = createAsyncAction(
  'REMOVE_PROMO_CODE_FROM_CART_REQUEST',
  'REMOVE_PROMO_CODE_FROM_CART_SUCCESS',
  'REMOVE_PROMO_CODE_FROM_CART_FAILURE',
)<PromoCodeCartRequestParams, Order, FailureActionPayload>();
