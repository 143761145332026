import { ACTION_STATUSES } from 'shared/consts';
import React from 'react';
import { ITEM_TYPE, TransactionDetails } from 'store/transactions/types';
import { CancelBookingPage } from 'pages/cancelBookingPage';
import { SwipeHistoricalReceiptContent } from './swipe-historical-receipt-content';

interface SwipeModalContentProps {
  isShown: boolean;
  itemId: string;
  itemType: ITEM_TYPE;
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SwipeModalContent = ({
  isShown,
  isSwipeModalContent,
  itemId,
  itemType,
  isCancellationExpiredState,
  onCancelBookingClick,
  patchTransactionStatus,
  transaction,
  isCancelBookingStep,
  setIsCancelBookingStep,
}: SwipeModalContentProps) => {
  return isCancelBookingStep ? (
    <CancelBookingPage setIsCancelBookingStep={setIsCancelBookingStep} />
  ) : (
    <SwipeHistoricalReceiptContent
      isShown={isShown}
      itemId={itemId}
      itemType={itemType}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionStatus={patchTransactionStatus}
      isCancellationExpiredState={isCancellationExpiredState}
      isSwipeModalContent={isSwipeModalContent}
    />
  );
};
