import { ButtonContainer, ContentContainer, ModalContainer, TextContainer, TitleContainer } from '../components/styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';

import { ACTION_STATUSES } from 'shared/consts';
import AddCard from 'components/add-card';
import { CancellationPolicy } from '../components/cancellationPolicy';
import { Cart } from 'components/cart';
import { CheckoutButton } from 'components/checkout-button/checkoutButton';
import { Container } from 'hocs/shared-styles';
import { EditPayment } from 'components/edit-payment';
import { Order } from 'store/cart/types';
import React from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';

export interface Props {
  cart: Order;
  paymentMethods: Array<SavedPaymentMethod>;
  currentPaymentMethodId: string;
  submittedPaymentMethodId?: number;
  onRemovePaymentMethod: VoidFunction;
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  isCartFulfilled: boolean;
  showAddCardForm: boolean;
  onCancelAddCardClick: VoidFunction;
  currentPaymentError?: boolean;
  cartId: string;
  isSwipeModalContent?: boolean;
}

export const CheckoutMobile = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  onRemovePaymentMethod,
  onPayClick,
  submitCartStatus,
  isCartFulfilled,
  showAddCardForm,
  currentPaymentError,
  onCancelAddCardClick,
  cartId,
  isSwipeModalContent = false,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { currencyType, total } = formatSummaryValues(cart?.total_summary);

  return (
    <ModalContainer isSwipeModalContent={isSwipeModalContent}>
      {!cart || !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <ContentContainer isSwipeModalContent={isSwipeModalContent}>
          <TitleContainer isSwipeModalContent={isSwipeModalContent}>
            <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
          </TitleContainer>
          {isCartFulfilled && <Cart cart={cart} isCheckoutView isSwipeModalContent={isSwipeModalContent} />}
          <EditPayment
            isSwipeModalContent={isSwipeModalContent}
            isCheckoutMobile
            enabledPaymentMethods={cart.ui_enablements}
            paymentMethods={paymentMethods}
            selectedPaymentMethodId={currentPaymentMethodId}
            submittedPaymentMethodId={submittedPaymentMethodId}
            onRemovePaymentMethod={onRemovePaymentMethod}
            onAddNewCard={onAddPaymentMethodClick}
            currentPaymentError={currentPaymentError}
            cartId={cartId}
            currencyType={currencyType}
            total={total}
          />
          {showAddCardForm && (
            <AddCard
              onCancel={onCancelAddCardClick}
              spreedlyEnvKey={cart.ui_enablements.spreedly_env_token}
              cartId={cartId}
            />
          )}
          {cart.items[0].display_info?.cancellation_description && (
            <TextContainer showAddCardForm={showAddCardForm}>
              <CancellationPolicy cart={cart} />
            </TextContainer>
          )}
          <ButtonContainer isSwipeModalContent={isSwipeModalContent}>
            <CheckoutButton
              text={
                cart.total_summary?.total
                  ? intl.formatMessage({ id: 'confirm_and_pay' })
                  : intl.formatMessage({ id: 'confirm_and_book' })
              }
              isDisabled={!currentPaymentMethodId || submitCartStatus === ACTION_STATUSES.PENDING || showAddCardForm}
              isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
              onClick={onPayClick}
            />
          </ButtonContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
};
