import React, { useEffect } from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import {
  selectGetRedirectTransactionStatus,
  selectGetTransactionStatus,
  selectRedirectTransaction,
  selectTransaction,
} from 'store/transactions/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, PATCH_STATUSES_3DS, PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { Container } from 'hocs/shared-styles';
import { getRedirectTransaction, getTransaction } from 'store/transactions/actions';
import { replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useCart } from 'hooks/use-cart.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const RedirectPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { cartId, transaction_token, paymentId, isMobileDevice } = useSearchParams();
  const { isPaymentMethodsFullfiled } = usePaymentMethods(cartId as string);
  const { isCartFulfilled, cart } = useCart(cartId as string);
  const getRedirectTransactionStatus = useSelector(selectGetRedirectTransactionStatus);
  const redirectTransaction = useSelector(selectRedirectTransaction);
  const { showMiniappPaymentsNavigation } = useFlags();
  const transactionStatus = useSelector(selectGetTransactionStatus);
  const transaction = useSelector(selectTransaction);

  useEffect(() => {
    if (transaction_token) {
      dispatch(getRedirectTransaction.request(transaction_token as string));
    }
  }, [dispatch, transaction_token]);

  useEffect(() => {
    if (showMiniappPaymentsNavigation && isMobileDevice === 'true' && cart?.transaction_uuid) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, showMiniappPaymentsNavigation, isMobileDevice]);

  useEffect(() => {
    if (
      getRedirectTransactionStatus === ACTION_STATUSES.FULFILLED &&
      isPaymentMethodsFullfiled &&
      isCartFulfilled &&
      cart?.transaction_uuid
    ) {
      if (redirectTransaction?.succeeded) {
        window.parent.postMessage(
          {
            message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_STATUS_UPDATE}`,
            value: { transaction_id: cart.transaction_id, status_3ds: PATCH_STATUSES_3DS.SUCCEEDED },
          },
          '*',
        );
        window.parent.postMessage(
          {
            message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_SUCCEEDED}`,
            value: cart.transaction_uuid,
          },
          '*',
        );
        if (showMiniappPaymentsNavigation && transactionStatus === ACTION_STATUSES.FULFILLED) {
          window.parent.postMessage(
            {
              message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_REDIRECT_SUCCEEDED}`,
              transactionId: transaction?.details.id,
            },
            '*',
          );
        } else if (isMobileDevice !== 'true' || !showMiniappPaymentsNavigation) {
          dispatch(replace(`/receipt${location.search}`));
        }
      } else {
        window.parent.postMessage(
          {
            message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_STATUS_UPDATE}`,
            value: { transaction_id: cart.transaction_id, status_3ds: PATCH_STATUSES_3DS.FAILED },
          },
          '*',
        );
        window.parent.postMessage(
          { message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_ERROR}`, value: cart.transaction_uuid },
          '*',
        );
        if (showMiniappPaymentsNavigation) {
          window.parent.postMessage(
            { message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_REDIRECT_ERROR}`, paymentId },
            '*',
          );
        }
        dispatch(replace(`/checkout${location.search}&error=true`));
      }
    }
  }, [
    transactionStatus,
    transaction,
    cart?.transaction_uuid,
    cart?.transaction_id,
    dispatch,
    getRedirectTransactionStatus,
    isCartFulfilled,
    isPaymentMethodsFullfiled,
    location.search,
    redirectTransaction,
    showMiniappPaymentsNavigation,
  ]);

  return (
    <Container data-testid="loading-container" isLoading>
      <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
    </Container>
  );
};
