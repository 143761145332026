import { ACTION_STATUSES } from 'shared/consts';
import React from 'react';
import { CheckoutMobile } from './checkoutMobile';
import { StyledModalWrapper } from 'pages/checkout/components/styles';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';

interface CheckoutMobileContentProps {
  isSwipeModalContent?: boolean;
  cart: Order;
  onRemovePaymentMethod: VoidFunction;
  defaultPaymentMethod: string;
  paymentError: boolean;
  paymentMethods: SavedPaymentMethod[];
  onBackClick: VoidFunction;
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  isCartFulfilled: boolean;
  showAddCardForm: boolean;
  onCancelAddCardClick: VoidFunction;
  cartId: string;
  submittedPaymentMethodId: number;
  onCloseClick: VoidFunction;
}

export const CheckoutMobileContent = ({
  isSwipeModalContent,
  cart,
  cartId,
  defaultPaymentMethod,
  paymentError,
  isCartFulfilled,
  onAddPaymentMethodClick,
  onBackClick,
  onCancelAddCardClick,
  onPayClick,
  onRemovePaymentMethod,
  paymentMethods,
  showAddCardForm,
  submitCartStatus,
  submittedPaymentMethodId,
  onCloseClick,
}: CheckoutMobileContentProps): JSX.Element => {
  return isSwipeModalContent ? (
    <CheckoutMobile
      isSwipeModalContent={isSwipeModalContent}
      cart={cart}
      onRemovePaymentMethod={onRemovePaymentMethod}
      currentPaymentMethodId={defaultPaymentMethod}
      submittedPaymentMethodId={submittedPaymentMethodId}
      currentPaymentError={paymentError}
      paymentMethods={paymentMethods}
      onAddPaymentMethodClick={onAddPaymentMethodClick}
      onPayClick={onPayClick}
      submitCartStatus={submitCartStatus}
      isCartFulfilled={isCartFulfilled}
      showAddCardForm={showAddCardForm}
      onCancelAddCardClick={onCancelAddCardClick}
      cartId={cartId}
    />
  ) : (
    <StyledModalWrapper
      visible
      withBackButton
      withExitButton
      onPressBack={onBackClick}
      content={
        <CheckoutMobile
          cart={cart}
          onRemovePaymentMethod={onRemovePaymentMethod}
          currentPaymentMethodId={defaultPaymentMethod}
          submittedPaymentMethodId={submittedPaymentMethodId}
          currentPaymentError={paymentError}
          paymentMethods={paymentMethods}
          onAddPaymentMethodClick={onAddPaymentMethodClick}
          onPayClick={onPayClick}
          submitCartStatus={submitCartStatus}
          isCartFulfilled={isCartFulfilled}
          showAddCardForm={showAddCardForm}
          onCancelAddCardClick={onCancelAddCardClick}
          cartId={cartId as string}
        />
      }
      onClose={onCloseClick}
    />
  );
};
