export enum TRACK_EVENT_TYPES {
  IMPRESSION = 'impression',
  ACTION = 'action',
}

export enum TRACK_EVENTS {
  QUICK_CHECKOUT_IMPRESSION = 'QUICK_CHECKOUT_IMPRESSION',
  FULL_CHECKOUT_IMPRESSION = 'FULL_CHECKOUT_IMPRESSION',
  CHECKOUT_CLICK = 'CHECKOUT_CLICK',
  RECEIPT_IMPRESSION = 'RECEIPT_IMPRESSION',
  HISTORICAL_RECEIPT_IMPRESSION = 'HISTORICAL_RECEIPT_IMPRESSION',
}
