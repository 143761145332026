import { DIMENSIONS } from '@hqo/react-components-library';
import { ItemContainerTitle } from './receiptDetails.interface';
import styled from 'styled-components';

export const CartContainer = styled.div`
  margin-top: 29px;
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    margin-top: 0px;
  }
`;

export const ItemsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.$greyLight};
  padding: 25px 16px 1px 16px;
`;

export const ItemsContainerTitle = styled.div<ItemContainerTitle>`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
  ${({ isCheckoutView }) =>
    `${
      !isCheckoutView &&
      `
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    display: none;
  }
  `
    }`};
`;

export const PriceContainer = styled.div<ItemContainerTitle>`
  padding: 20px 16px;
  display: grid;
  gap: 20px;
  ${({ theme, isCheckoutView }) => !isCheckoutView && `border-bottom: 1px solid ${theme.colors.greys.sharedMediumGrey}`}
  color: ${({ theme }) => theme.colors.fontColor};
`;
