import React from 'react';
import { useIntl } from 'react-intl';
import { CollapsableBlock } from 'components/collapsable-block';
import { Text } from './styles';

interface CancellationInformationProps {
  cancellationRule: string;
}

export const CancellationInformation = ({ cancellationRule }: CancellationInformationProps): JSX.Element => {
  const intl = useIntl();

  return (
    <CollapsableBlock title={intl.formatMessage({ id: 'cancellation_policy' })}>
      <Text
        dangerouslySetInnerHTML={{
          __html: `${cancellationRule}`,
        }}
      />
    </CollapsableBlock>
  );
};
