import { TRANSACTION_TYPES } from 'shared/consts';
import { TransactionDetails, TransactionDetailsServiceTypes } from 'store/transactions/types';
import { formatDate, getEventDuration, getFormattedEventDuration } from './formatDate';

interface ReceiptInfo {
  title: string;
  eventBlockSubtitle?: string;
}

// eslint-disable-next-line max-lines-per-function
export const getReceiptInfoFromTransaction = (
  transaction: TransactionDetails,
  locale: string,
  timezone?: string,
): ReceiptInfo => {
  if (
    transaction.type === TRANSACTION_TYPES.SERVICES_BOOKING &&
    transaction.details.service.type === TransactionDetailsServiceTypes.APPOINTMENT
  ) {
    return {
      title: 'appointment_details',
      eventBlockSubtitle: `${
        formatDate(transaction.details.start_at, locale as string, timezone).formatEventStartTime
      } / ${getEventDuration(null, transaction)} min`,
    };
  }

  if (
    transaction.type === TRANSACTION_TYPES.SERVICES_BOOKING &&
    transaction.details.service.type === TransactionDetailsServiceTypes.CLASS
  ) {
    return {
      title: 'class_details',
      eventBlockSubtitle: getFormattedEventDuration(
        transaction.details.start_at,
        transaction.details.end_at,
        locale as string,
        timezone,
      ),
    };
  }

  switch (transaction.type) {
    case TRANSACTION_TYPES.MEMBERSHIP:
      return { title: 'summary' };
    case TRANSACTION_TYPES.RESOURCES_BOOKING:
      return {
        title: 'details',
        eventBlockSubtitle: getFormattedEventDuration(
          transaction.details.start_at as string,
          transaction.details.end_at as string,
          locale as string,
          timezone,
        ),
      };
    default:
      return { title: 'default_details' };
  }
};
