import {
  AmenitiesDescription,
  AmenitiesDescriptionContainer,
  Container,
  ListContainer,
  ReadMoreButton,
  ReadMoreContainer,
  Title,
} from './styles';
import React, { useRef } from 'react';

import { Amenity } from './amenity';
import { ResourceFeature } from 'store/transactions/types';
import { useAmenities } from 'hooks/use-amenities.hook';
import { useIntl } from 'react-intl';

interface AmenitiesProps {
  amenities: Array<ResourceFeature>;
  amenitiesDescription?: string;
  isMobileDevice?: boolean;
}

export const Amenities = ({ amenities, amenitiesDescription, isMobileDevice }: AmenitiesProps): JSX.Element => {
  const intl = useIntl();
  const ref = useRef(null);
  const { isExpanded, isOverflowing, onExpand } = useAmenities(isMobileDevice, ref);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: 'amenities' })}</Title>
      <AmenitiesDescriptionContainer>
        <AmenitiesDescription ref={ref} isOverflowing={isOverflowing} isExpanded={isExpanded}>
          {amenitiesDescription}
        </AmenitiesDescription>
        {isOverflowing && (
          <ReadMoreContainer>
            <ReadMoreButton onClick={onExpand}>
              {isExpanded ? intl.formatMessage({ id: 'read_less' }) : intl.formatMessage({ id: 'read_more' })}
            </ReadMoreButton>
          </ReadMoreContainer>
        )}
      </AmenitiesDescriptionContainer>
      <ListContainer>
        {amenities
          .sort((a, b) => {
            const ctaTypeA = a.cta_type ? 1 : 0;
            const ctaTypeB = b.cta_type ? 1 : 0;

            return ctaTypeB - ctaTypeA;
          })
          .map(amenity => (
            <Amenity key={amenity.id} amenity={amenity} />
          ))}
      </ListContainer>
    </Container>
  );
};
