import React, { useCallback } from 'react';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { BottomModal } from '@hqo/react-components-library/dist/molecules/modals/bottomModal';
import { ErrorWrapper, Icon, IconWrapper } from './styles';
import { goBack } from 'connected-react-router';
import { hideGenericErrorNotifications } from 'store/errors/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { ContentModal } from '../checkout/components/contentModal';
import { BUTTON_VARIANT, useBooleanState } from '@hqo/react-components-library';
import { PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';

interface ErrorPageProps {
  onClick?: VoidFunction;
}

export const ErrorPage = ({ onClick }: ErrorPageProps): JSX.Element => {
  const { action } = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { value: showConfirmCloseModal, toggle: toggleConfirmCloseModal } = useBooleanState(false);

  const onLeaveClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE}`, '*');
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  const onStayClick = useCallback(() => {
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  const buttons = [
    {
      title: intl.formatMessage({ id: 'stay' }),
      handler: onStayClick,
    },
    {
      title: intl.formatMessage({ id: 'leave' }),
      handler: onLeaveClick,
      variant: BUTTON_VARIANT.SECONDARY,
    },
  ];

  const onCloseClick = useCallback(() => {
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  const handleClick = useCallback(() => {
    if (action !== 'POP') {
      dispatch(goBack());
      dispatch(hideGenericErrorNotifications());
    }
  }, [action, dispatch]);

  return (
    <ErrorWrapper>
      <IconWrapper data-testid="close-button-testid" onClick={onCloseClick}>
        <Icon icon={['fal', 'times']} />
      </IconWrapper>
      <BottomModal isVisible={showConfirmCloseModal} buttons={buttons} content={ContentModal()} showCloseIcon={false} />
      <ErrorNotification
        title={intl.formatMessage({ id: 'something_went_wrong' })}
        description={intl.formatMessage({ id: 'unexpected_error_occurred' })}
        buttonText={intl.formatMessage({ id: 'go_back' })}
        onClick={onClick || handleClick}
      />
    </ErrorWrapper>
  );
};
