import React from 'react';
import { ReceiptContent } from './components/receipt-content';
import { SwipeModalContent } from './components/swipe-modal-content';
import useReceipt from './use-receipt.hook';

export const ReceiptPage = () => {
  const {
    isShown,
    cart,
    paymentMethods,
    isCancellationExpiredState,
    isSwipeModalContent,
    onCancelBookingClick,
    onCloseClick,
    patchTransactionStatus,
    transaction,
  } = useReceipt();

  return isSwipeModalContent ? (
    <SwipeModalContent
      isShown={isShown}
      cart={cart}
      paymentMethods={paymentMethods}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionStatus={patchTransactionStatus}
      isCancellationExpiredState={isCancellationExpiredState}
      isSwipeModalContent={isSwipeModalContent}
    />
  ) : (
    <ReceiptContent
      isShown={isShown}
      cart={cart}
      paymentMethods={paymentMethods}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionStatus={patchTransactionStatus}
      isCancellationExpiredState={isCancellationExpiredState}
      onCloseClick={onCloseClick}
    />
  );
};
