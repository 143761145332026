import { ORDER_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';
import { TRANSACTION_TYPES } from 'shared/consts/transaction-types';
import { TransactionDetails } from 'store/transactions/types';
import moment from 'moment';

export const formatDate = (value: string, locale: string, timezone?: string) => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const eventDateMonth = new Date(value).toLocaleString(locale, { month: 'short', timeZone });
  const eventDateMonthNum = new Date(value).toLocaleString(locale, { month: 'numeric', timeZone });
  const eventDateDay = new Date(value).toLocaleString(locale, { day: 'numeric', timeZone });
  const eventDateMonthLong = new Date(value).toLocaleString(locale, { month: 'long', timeZone });
  const eventDateYear = new Date(value).toLocaleString(locale, { year: 'numeric', timeZone });
  const eventWeekDay = new Date(value).toLocaleString(locale, { weekday: 'long', timeZone });
  const formatEventStartTime = new Date(value).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });

  return {
    eventDateMonth,
    eventDateMonthNum,
    eventDateDay,
    eventDateMonthLong,
    eventDateYear,
    formatEventStartTime,
    eventWeekDay,
  };
};

export const getEventDuration = (cart?: Order, transaction?: TransactionDetails): string => {
  let eventStart;
  let eventEnd;

  if (!cart) {
    if (transaction?.type === TRANSACTION_TYPES.SERVICES_BOOKING) {
      eventStart = moment(transaction?.details.start_at);
      eventEnd = moment(transaction?.details.end_at);
      return eventEnd.diff(eventStart, 'minutes').toString();
    }
  }

  switch (cart?.type) {
    case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
    case ORDER_TYPES.SERVICE_BOOKING_CLASS:
      eventStart = moment(cart?.items[0].service_booking?.start_at);
      eventEnd = moment(cart?.items[0].service_booking?.end_at);
      break;
    case ORDER_TYPES.RESOURCE_BOOKING:
      eventStart = moment(cart?.items[0].resource_booking?.start_at);
      eventEnd = moment(cart?.items[0].resource_booking?.end_at);
      break;
    default:
      eventStart = moment(cart?.items[0].resource_booking?.start_at);
      eventEnd = moment(cart?.items[0].resource_booking?.end_at);
      break;
  }

  return eventEnd.diff(eventStart, 'minutes').toString();
};

export const getBookingStart = (cart: Order): string => {
  let bookingStart;

  switch (cart.type) {
    case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
    case ORDER_TYPES.SERVICE_BOOKING_CLASS:
      bookingStart = cart.items[0].service_booking?.start_at;
      break;
    case ORDER_TYPES.RESOURCE_BOOKING:
      bookingStart = cart.items[0].resource_booking?.start_at;
      break;
    default:
      bookingStart = cart.items[0].resource_booking?.start_at;
      break;
  }

  return bookingStart;
};

export const getBookingStartFromTransaction = (transaction: TransactionDetails): string => {
  let bookingStart;

  switch (transaction?.type) {
    case TRANSACTION_TYPES.RESOURCES_BOOKING:
      bookingStart = transaction?.details.start_at as string;
      break;
    default:
      bookingStart = transaction?.details?.start_at as string;
      break;
  }

  return bookingStart;
};

export const getFormattedEventDuration = (
  startDate: string,
  endDate: string,
  locale: string,
  timezone?: string,
): string => {
  const timeZone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventStartTime = new Date(startDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });
  const eventEndTime = new Date(endDate).toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone,
  });

  return `${eventStartTime} - ${eventEndTime}`;
};
