import { Background, QuickCheckoutWrapper } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { select3DSecure, selectComplete3DSCartError } from 'store/cart/selectors';

import { Container } from './components/container';
import { DefaultLoader } from 'components/default-loader';
import { ThreeDSModal } from 'components/modals/3dsModal/component';
import { useBooleanState } from '@hqo/react-components-library';
import { useCart } from 'hooks/use-cart.hook';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';

const renderLoading = (isCartLoading: boolean, isPaymentMethodsLoading: boolean) =>
  (isCartLoading || isPaymentMethodsLoading) && <DefaultLoader />;

export const QuickCheckout = () => {
  const { cartId } = useSearchParams();
  const { isCartLoading, isCartFulfilled, cart } = useCart(cartId as string);
  const { isPaymentMethodsLoading, paymentMethods, isPaymentMethodsFullfiled } = usePaymentMethods(cartId as string);
  const { is3DSecure } = useSelector(select3DSecure);
  const [show3DS, setShow3DS] = useState<boolean>(false);
  const [currentOffsetHeight, setOffsetHeight] = useState<number>(null);
  const complete3DSCartError = useSelector(selectComplete3DSCartError);

  const { value: isProcessPaymentFailed, setTrue: setProcessPaymentFailed } = useBooleanState();

  useEffect(() => {
    if (complete3DSCartError) {
      setProcessPaymentFailed();
    }
  }, [complete3DSCartError]);

  const setPaymentError = useCallback(
    (value: boolean) => {
      if (value) {
        setProcessPaymentFailed();
      }
    },
    [setProcessPaymentFailed],
  );

  const paymentMethod = paymentMethods?.length
    ? paymentMethods?.find(method => method.default) || paymentMethods[0]
    : null;

  return (
    <>
      {!show3DS && (
        <>
          <QuickCheckoutWrapper>
            <>
              {renderLoading(isCartLoading, isPaymentMethodsLoading)}
              {isCartFulfilled && isPaymentMethodsFullfiled && (
                <Container
                  cart={cart}
                  isProcessPaymentFailed={isProcessPaymentFailed}
                  setProcessPaymentFailed={setProcessPaymentFailed}
                  paymentMethods={paymentMethods}
                  currentPaymentMethodId={paymentMethod?.id?.toString()}
                  setOffsetHeight={setOffsetHeight}
                />
              )}
            </>
          </QuickCheckoutWrapper>
          <Background />
        </>
      )}
      {is3DSecure && (
        <ThreeDSModal
          setPaymentError={setPaymentError}
          setIs3DSVisible={setShow3DS}
          transactionToken={cart?.transaction?.token}
          spreedlyToken={cart?.ui_enablements.spreedly_env_token}
          currentOffsetHeight={currentOffsetHeight}
          isQuickCheckout
        />
      )}
    </>
  );
};
