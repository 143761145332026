import * as userActions from './actions';

import { UnauthorizedError } from 'store/user/errors';
import { UserState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

const AUTH_TOKEN_LS_KEY = 'authToken';

export const initialState: UserState = {
  user: null,
  loading: false,
  token: localStorage.getItem(AUTH_TOKEN_LS_KEY),
  error: null,
};

const loading = createReducer(initialState.loading)
  .handleAction(userActions.externalLogin.request, () => true)
  .handleAction(userActions.externalLogin.success, () => false)
  .handleAction(userActions.externalLogin.failure, () => false)
  .handleAction(userActions.getCurrentUser.request, () => true)
  .handleAction(userActions.getCurrentUser.success, () => false)
  .handleAction(userActions.getCurrentUser.failure, () => false);

const user = createReducer(initialState.user)
  .handleAction(userActions.externalLogin.success, (_state, action) => action.payload.user)
  .handleAction(userActions.externalLogin.failure, () => null)
  .handleAction(userActions.getCurrentUser.success, (_state, action) => action.payload.user)
  .handleAction(userActions.getCurrentUser.failure, () => null);

const token = createReducer(initialState.token)
  .handleAction(userActions.externalLogin.success, (_state, action) => {
    const { token: authToken } = action.payload;
    localStorage.setItem(AUTH_TOKEN_LS_KEY, authToken);

    return authToken;
  })
  .handleAction(userActions.externalLogin.failure, () => {
    localStorage.removeItem(AUTH_TOKEN_LS_KEY);

    return null;
  })
  .handleAction(userActions.getCurrentUser.failure, (_state, action) => {
    if (action.payload.error instanceof UnauthorizedError) {
      localStorage.removeItem(AUTH_TOKEN_LS_KEY);
    }

    return null;
  })
  .handleAction(userActions.postMessageLogin, (_state, action) => {
    const { authToken } = action.payload;
    localStorage.setItem(AUTH_TOKEN_LS_KEY, authToken);

    return authToken;
  });

const error = createReducer(initialState.error)
  .handleAction(userActions.externalLogin.request, () => null)
  .handleAction(userActions.externalLogin.success, () => null)
  .handleAction(userActions.externalLogin.failure, (_state, action) => action.payload.error)
  .handleAction(userActions.getCurrentUser.request, () => null)
  .handleAction(userActions.getCurrentUser.success, () => null)
  .handleAction(userActions.getCurrentUser.failure, (_state, action) => action.payload.error);

export default combineReducers({
  loading,
  user,
  token,
  error,
});
