import { ACTION_STATUSES, ORDER_TYPES } from 'shared/consts';
import { ReceiptContainer, StyledDoublePaneModal } from '../styles';
import { CancellationExpiredText } from 'pages/historicalReceipt/styles';
import React from 'react';
import { DefaultLoader } from 'components/default-loader';
import { TransactionDetails } from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from 'components/cancel-button';
import { ReceiptOverview } from './receipt-overview';
import { Summary } from './summary';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';

interface ReceiptContentProps {
  isShown: boolean;
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
}

export const ReceiptContent = ({
  isShown,
  isCancellationExpiredState,
  onCancelBookingClick,
  onCloseClick,
  patchTransactionStatus,
  transaction,
  cart,
  paymentMethods,
}: ReceiptContentProps) => {
  const intl = useIntl();

  return (
    <>
      {!isShown ? (
        <DefaultLoader />
      ) : (
        <ReceiptContainer className="receipt-page" data-testid="receipt-page">
          {cart && paymentMethods && (
            <StyledDoublePaneModal
              contentStatus={ACTION_STATUSES.FULFILLED}
              isVisible
              onClose={onCloseClick}
              LeftPaneContent={<ReceiptOverview />}
              RightPaneContent={<Summary />}
              Button={
                cart.type === ORDER_TYPES.RESOURCE_BOOKING && (
                  <CancelButtonBlock
                    onCancel={onCancelBookingClick}
                    status={patchTransactionStatus}
                    expired={isCancellationExpiredState}
                    isReceiptCTA
                  />
                )
              }
              ButtonLabel={
                transaction?.details?.cancellation_window_end_minutes >= 0 &&
                transaction?.details?.cancellation_window_end_minutes !== null && (
                  <CancellationExpiredText>
                    {intl.formatMessage(
                      { id: 'notice_required' },
                      { hours: transaction?.details?.cancellation_window_end_minutes / 60 },
                    )}
                  </CancellationExpiredText>
                )
              }
            />
          )}
        </ReceiptContainer>
      )}
    </>
  );
};
