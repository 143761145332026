import { ROOT_PATH } from 'shared/consts';
/* eslint-disable no-restricted-globals */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from 'hooks/use-current-user.hook';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const [user, loading] = useCurrentUser();

  if (user || loading) {
    return <Redirect to={`${ROOT_PATH}${location.search}`} />;
  }

  return <Redirect to="/error" />;
};
