import * as altBuildingsActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { AltBuildingsState } from './types';

export const initialState: AltBuildingsState = {
  altBuildings: null,
  loading: false,
  error: null,
};

const getAltBuildingsRequestHandler = (state: AltBuildingsState) => ({
  ...state,
  loading: true,
});

const getAltBuildingsSuccessHandler = (
  state: AltBuildingsState,
  { payload }: ActionType<typeof altBuildingsActions.getAltBuildings.success>,
): AltBuildingsState => ({
  ...state,
  altBuildings: payload,
  loading: false,
  error: null,
});

const getAltBuildingsFailureHandler = (
  state: AltBuildingsState,
  { payload: error }: ActionType<typeof altBuildingsActions.getAltBuildings.failure>,
): AltBuildingsState => ({
  ...state,
  loading: false,
  error: error.error,
});

const altBuildingsReducer = createReducer(initialState)
  .handleAction(altBuildingsActions.getAltBuildings.request, getAltBuildingsRequestHandler)
  .handleAction(altBuildingsActions.getAltBuildings.success, getAltBuildingsSuccessHandler)
  .handleAction(altBuildingsActions.getAltBuildings.failure, getAltBuildingsFailureHandler);

export default altBuildingsReducer;
