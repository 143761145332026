import * as addCardActions from 'store/add-card/actions';
import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as buildingActions from 'store/building/actions';
import * as cartActions from 'store/cart/actions';
import * as paymentActions from 'store/payment/actions';
import * as paymentsConfigActions from 'store/payments-config/actions';
import * as themeActions from 'store/theme/actions';
import * as transactionsActions from 'store/transactions/actions';
import * as userActions from 'store/user/actions';

import { ERROR_PATH, IFRAME_ERROR_SPREEDLY, SPREEDLY_ERROR } from 'shared/consts';
import { IFrameErrorSpreedly, SpreedlyError } from '../add-card/types';
import { concatMap, filter, map } from 'rxjs/operators';
import { showErrorNotification, showGenericErrorNotification } from './actions';

import { Epic } from 'redux-observable';
import { FailureActionPayload } from 'store/errors/types';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { push } from 'connected-react-router';

export const parseGenericError = (
  errorPayload: FailureActionPayload | IFrameErrorSpreedly | SpreedlyError[],
): Error => {
  const genericError: Error = {
    name: '',
    message: '',
  };

  if (Object.getOwnPropertyDescriptor(errorPayload, 'msg')) {
    genericError.name = IFRAME_ERROR_SPREEDLY;
    genericError.message = (errorPayload as IFrameErrorSpreedly).msg;
    return genericError;
  }

  if (Array.isArray(errorPayload)) {
    genericError.name = SPREEDLY_ERROR;
    genericError.message = (errorPayload as SpreedlyError[]).length ? errorPayload[0].message : SPREEDLY_ERROR;
    return genericError;
  }

  return (errorPayload as FailureActionPayload).error;
};

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    // Add failure actions to trigger error modal here and in the test file
    filter(isActionOf([transactionsActions.patchTransaction.failure])),
    map(action =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
      }),
    ),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    // Add failure actions to trigger error page redirects here and in the test file
    filter(
      isActionOf([
        buildingActions.getBuilding.failure,
        themeActions.getBuildingTheme.failure,
        userActions.externalLogin.failure,
        userActions.getCurrentUser.failure,
        altBuildingsActions.getAltBuildings.failure,
        paymentActions.getPaymentMethods.failure,
        paymentsConfigActions.getPaymentsConfig.failure,
        cartActions.getCart.failure,
        addCardActions.tokenizeCreditCardSpreedly.failure,
        addCardActions.initializeSpreedly.failure,
        addCardActions.reloadSpreedly.failure,
        transactionsActions.getTransaction.failure,
      ]),
    ),
    concatMap(action =>
      of(
        showGenericErrorNotification({
          action: action.type,
          error: parseGenericError(action.payload),
        }),
        push(ERROR_PATH),
      ),
    ),
  );
