import { usePendoAnalytics } from 'hooks/use-pendo-analytics.hook';
import { useEffect } from 'react';
import { initializePendo } from 'utils/pendo';
import { initializeWebTracking } from '@hqo/web-tracking';
import { useWebTrackingAnalytics } from './use-web-tracking.hook';

export const usePendo = (): void => {
  const analytics = usePendoAnalytics();
  const webTrackingData = useWebTrackingAnalytics();

  useEffect(() => {
    if (window.pendo && analytics) {
      initializePendo(window.pendo, analytics);
    }
  }, [analytics]);

  useEffect(() => {
    if (webTrackingData?.token && webTrackingData?.buildingUuid) {
      initializeWebTracking(
        webTrackingData?.token,
        webTrackingData?.buildingUuid,
        webTrackingData?.appUuid,
        navigator?.userAgent,
        webTrackingData?.apiUrl,
      );
    }
  }, [webTrackingData?.appUuid, webTrackingData?.buildingUuid, webTrackingData?.token, webTrackingData?.apiUrl]);
};
