import * as addCardActions from 'store/add-card/actions';
import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as buildingActions from 'store/building/actions';
import * as cartActions from 'store/cart/actions';
import * as configActions from 'store/config/actions';
import * as errorsActions from 'store/errors/actions';
import * as featureFlagActions from 'store/feature-flags/actions';
import * as paymentActions from 'store/payment/actions';
import * as paymentsConfigActions from 'store/payments-config/actions';
import * as themeActions from 'store/theme/actions';
import * as transactionsActions from 'store/transactions/actions';
import * as userActions from 'store/user/actions';

import { CallHistoryMethodAction, routerActions } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { ActionType } from 'typesafe-actions';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  user: userActions,
  theme: themeActions,
  config: configActions,
  errors: errorsActions,
  altBuildings: altBuildingsActions,
  payment: paymentActions,
  payemtnsConfig: paymentsConfigActions,
  cart: cartActions,
  addCard: addCardActions,
  transactions: transactionsActions,
  building: buildingActions,
  featureFlag: featureFlagActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
