export const DEFAULT_MODAL_WIDTH = '425px';
export const DEFAULT_MODAL_HEIGHT = '299px';
export const DEFAULT_MODAL_PADDINGS = ['48px', '32px', '48px', '32px'];

export const DEFAULT_OWNER_TYPE = 'building';

export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';

export const REGISTERED_SIGN = '\u00ae';

export const EXPDATE_LENGTH = 5;
export const MIN_USERNAME_LENGTH = 1;
export const MIN_ZIP_CODE_LENGTH = 5;

export const TOKENIZATION_METHOD = 'Spreedly';
export const DEFAULT_3DSECURE_VERSION = '2';
export const SPREEDLY_CARD_REF = 'number';
export const SPREEDLY_CVV_REF = 'cvv';
export const SPREEDLY_CARD_ID = 'spreedly-credit-card';
export const SPREEDLY_CVV_ID = 'spreedly-cvv';
