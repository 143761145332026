import { Button, FormField } from '@hqo/react-components-library';
import styled from 'styled-components';

export const StyledFormField = styled(FormField)`
  width: 100%;

  span {
    margin-bottom: -22px;
    padding-left: 0px;
  }
`;

export const PromoCodeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 12px;
`;

export const ApplyButton = styled(Button)`
  width: 90px;
  height: 48px;
  margin-top: 22px;
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  -webkit-tap-highlight-color: transparent;
`;
