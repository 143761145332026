import { Epic } from 'redux-observable';
import { filter, withLatestFrom, switchMap, catchError, map } from 'rxjs/operators';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import * as actions from './actions';
import { RootDependencies } from 'store/dependencies';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

export const getPaymentsConfigEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPaymentsConfig.request)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      return apiClient(state)
        .getPaymentsConfig()
        .pipe(
          map(ajaxResponse => {
            return actions.getPaymentsConfig.success(ajaxResponse.response.data.config);
          }),
          catchError((error: Error) => of(actions.getPaymentsConfig.failure({ error }))),
        );
    }),
  );
