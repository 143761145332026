import { Button, FormField, hqoTheme, Select } from '@hqo/react-components-library';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const AddCardContainer = styled.div`
  padding: 24px 16px 16px 16px;
  display: grid;
  grid-template-columns: 1fr;
`;
export const BorderColors = {
  default: hqoTheme.colors.greys.adminGreyMedium,
  hover: defaultTheme.colors.$darkGrayishBlue,
  focus: defaultTheme.colors.$sharedGreyDark,
  error: defaultTheme.colors.$adminPink,
};

export const InputBorderStyles = {
  default: `1px solid ${BorderColors.default}`,
  hover: `1px solid ${BorderColors.hover}`,
  focus: `1px solid ${BorderColors.focus}`,
  error: `1px solid ${BorderColors.error}`,
};

export const CardIcon = styled(FontAwesomeIcon)`
  font-size: 26px;
  height: 48px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const SpreedlyIFrameContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border: ${InputBorderStyles.default};
  padding: 13px 16px;
  background-color: transparent;
  border-radius: 4px;
  transition: border-color 0.15s ease-out;
  outline: none;
  margin-bottom: 38px;
  color: ${({ theme }) => theme.colors.fontColor};

  &:hover {
    border: ${InputBorderStyles.hover};
  }
`;

export const StyledFormField = styled(FormField)`
  margin-bottom: 24px;
`;

export const StyledCountryLabel = styled.div`
  font: normal 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$greyDark};
`;

export const CancelButton = styled(Button)`
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
  height: 38px;
  width: 97px;
  color: ${({ theme }) => theme.colors.$primary};
`;

export const SaveButton = styled(Button)`
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
  -webkit-tap-highlight-color: transparent;
  height: 38px;
  width: 97px;
`;

export const SaveCancelButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AddPaymentMethodError = styled.div`
  font: italic 12px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const AddPaymentMethodErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const Label = styled.div`
  font: normal 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$greyDark};
`;

export const RequiredIcon = styled.span`
  font: normal 14px/22px ${({ theme }) => theme.fonts.join()};
  padding-left: 4px;
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const SpreedlyErrorLabel = styled.div`
  font: italic 12px/22px ${({ theme }) => theme.fonts.join()};
  margin: -38px 0 24px;
  padding: 2px 0px 0px 16px;
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 24px;
`;

export const CommonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${StyledFormField} {
    width: 100%;
  }
`;

export const SpreedlyCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${CardIcon} {
    align-items: center;
    margin-right: 14px;
  }
`;

export const SpreedlyCVVWrapper = styled.div`
  width: 100%;
`;

export const SpaceBetween = styled.div`
  width: 20px;
  margin-bottom: 24px;
`;
