import { Button } from '@hqo/react-components-library';
import { IFRAME_WIDTH } from 'shared/consts';
import styled from 'styled-components';

export const CancelButton = styled(Button)<{ isSwipeModalContent: boolean }>`
  align-self: flex-end;
  width: 100%;
  height: 48px;
  margin-top: 0;
  padding: 4px 23px;
  ${({ isSwipeModalContent }) => !isSwipeModalContent && `border: none;`};
`;

export const ButtonContainer = styled.div`
  width: 215px;
  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    width: 100%;
  }
`;
