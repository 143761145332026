import { ACTION_STATUSES, ORDER_TYPES, TRANSACTION_TYPES } from 'shared/consts';
import {
  CustomIconCancel,
  CustomIconConfirmed,
  OverviewContainer,
  Subtitle,
  TitleContainer,
  StyledStatusIconTitle,
} from './styles';
import { LabelBlock } from '@hqo/react-components-library/dist/molecules/label-block/labelBlock';
import { IconContentProps } from '@hqo/react-components-library/dist/icons';
import { formatDate, getBookingStart, getBookingStartFromTransaction } from 'utils/formatDate';

import { BoldText } from 'hocs/bold-text';
import React from 'react';
import { getReceiptIcon } from 'utils/getReceiptIcon';
import { getReceiptSubtitle } from 'utils/getReceiptSubtitle';
import { getReceiptTitle } from 'utils/getReceiptTitle';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import {
  selectPatchTransactionsStatus,
  selectTransaction,
  selectTransactionDisplay,
} from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { TransactionDetailsServiceTypes } from 'store/transactions/types';

interface ReceiptOverviewProps {
  isSwipeModalContent?: boolean;
}

export const ReceiptOverview = ({ isSwipeModalContent = false }: ReceiptOverviewProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const cart = useSelector(selectCart);
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);

  const bookingStart = transactionUuid ? getBookingStartFromTransaction(transaction) : getBookingStart(cart);

  const { eventWeekDay, eventDateMonthLong, eventDateDay } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  const getOverViewTitleFromTransaction = () => {
    if (
      transaction.type === TRANSACTION_TYPES.SERVICES_BOOKING &&
      transaction.details.service.type === TransactionDetailsServiceTypes.APPOINTMENT
    ) {
      return {
        title: 'appointment_booked',
        subtitle: intl.formatMessage(
          { id: 'timekit_confirmation_text' },
          {
            serviceName: <BoldText>{transactionDisplay.items[0].display_info.title}</BoldText>,
            serviceTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
          },
        ),
      };
    }
    if (
      transaction.type === TRANSACTION_TYPES.SERVICES_BOOKING &&
      transaction.details.service.type === TransactionDetailsServiceTypes.CLASS
    ) {
      // Copied from the original function getOverviewTitle
      return {
        title: 'class_booked',
        subtitle: intl.formatMessage(
          { id: 'timekit_confirmation_text' },
          {
            serviceName: <BoldText>{transactionDisplay.items[0].display_info.title}</BoldText>,
            serviceTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
          },
        ),
      };
    }

    if (transaction.type === TRANSACTION_TYPES.MEMBERSHIP) {
      return {
        title: 'membership_activated',
        subtitle: intl.formatMessage(
          { id: 'you_are_member' },
          {
            membership: transactionDisplay.vendor.name,
          },
        ),
      };
    }
    return {
      title: 'default_receipt',
      subtitle: null,
    };
  };

  const getOverviewTitle = (itemType: ORDER_TYPES, cancellationStatus: ACTION_STATUSES) => {
    switch (itemType) {
      case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
        return {
          title: 'membership_activated',
          subtitle: intl.formatMessage(
            { id: 'you_are_member' },
            {
              membership: cart?.vendor.name,
            },
          ),
        };
      case ORDER_TYPES.SERVICE_BOOKING_CLASS:
        return {
          title: 'class_booked',
          subtitle: intl.formatMessage(
            { id: 'timekit_confirmation_text' },
            {
              serviceName: <BoldText>{cart?.items[0].display_info.title}</BoldText>,
              serviceTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
            },
          ),
        };
      case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
        return {
          title: 'appointment_booked',
          subtitle: intl.formatMessage(
            { id: 'timekit_confirmation_text' },
            {
              serviceName: <BoldText>{cart?.items[0].display_info.title}</BoldText>,
              serviceTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
            },
          ),
        };
      case ORDER_TYPES.RESOURCE_BOOKING: {
        const booking_status = cart?.items[0].status;
        return {
          title: getReceiptTitle(booking_status, cancellationStatus),
          subtitle: intl.formatMessage(
            { id: getReceiptSubtitle(booking_status, cancellationStatus) },
            {
              resourceName: <BoldText>{cart?.items[0].resource_booking?.name}</BoldText>,
              bookingTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
            },
          ),
        };
      }
      default:
        return {
          title: 'default_receipt',
          subtitle: null,
        };
    }
  };

  let icon;
  if (cart?.type === 'RESOURCE_BOOKING') {
    icon = getReceiptIcon(cart?.items[0].status, patchTransactionStatus);
  } else {
    icon = patchTransactionStatus === ACTION_STATUSES.FULFILLED ? <CustomIconCancel /> : <CustomIconConfirmed />;
  }

  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="resource-booking-receipt-overview">
      <TitleContainer>
        <StyledStatusIconTitle
          title={intl.formatMessage({
            id: transactionUuid
              ? getOverViewTitleFromTransaction().title
              : getOverviewTitle(cart?.type, patchTransactionStatus).title,
          })}
          icon={icon as unknown as React.FC<IconContentProps>}
          hideIcon={transactionUuid ? transaction.details.status === 'Pending' : cart?.items[0].status === 'Pending'}
        />
      </TitleContainer>
      <Subtitle>
        {transactionUuid
          ? getOverViewTitleFromTransaction().subtitle
          : getOverviewTitle(cart?.type, patchTransactionStatus).subtitle}
      </Subtitle>
      {(transaction?.type === TRANSACTION_TYPES.MEMBERSHIP ||
        cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) && (
        <LabelBlock
          title={intl.formatMessage({ id: 'your_plan' })}
          subtitle={
            transactionUuid ? transactionDisplay.items[0].display_info.title : cart?.items[0].display_info.title
          }
        />
      )}
    </OverviewContainer>
  );
};
