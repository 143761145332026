import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import {
  IconBlockContainer,
  PaymentMethodContainer,
  PaymentMethodTitle,
  ServiceDetails,
  StyledSummarySubtitle,
  StyledTitleBlock,
  SummaryContainer,
} from './styles';
import { formatDate, getBookingStart, getBookingStartFromTransaction } from 'utils/formatDate';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';

import { AdditionalInformation } from 'components/additional-information';
import { Amenities } from 'components/amenities';
import { CancellationInformation } from 'components/cancellation-information';
import { Cart } from 'components/cart';
import { ORDER_TYPES, TRANSACTION_TYPES } from 'shared/consts';
import { PaymentMethodReceipt } from 'components/payment';
import React from 'react';
import { SupportedPaymentMethods } from 'store/transactions/types';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { getReceiptInfo } from 'utils/getReceiptInfo';
import { selectCart } from 'store/cart/selectors';
import { selectPaymentMethods } from 'store/payment/selectors';
import { selectTransaction, selectTransactionDisplay } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';
import { formatSavedPaymentMethodLabelFromTransaction } from 'utils/formatSavedPaymentMethodLabelFromTransaction';

interface SummaryProps {
  isSwipeModalContent?: boolean;
}

export const Summary = ({ isSwipeModalContent = false }: SummaryProps) => {
  const intl = useIntl();
  const cart = useSelector(selectCart);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethod = paymentMethods?.find(method => method.default);
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const isMobileDevice = useIsSmallViewportWidth();
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);

  const { capitalizedBrand, formattedExpMonth, formattedExpYear } =
    transaction?.payment_method?.type?.toUpperCase() === SupportedPaymentMethods.CREDIT_CARD &&
    (transactionUuid
      ? formatSavedPaymentMethodLabelFromTransaction(transaction?.payment_method)
      : formatSavedPaymentMethodLabel(paymentMethod));

  const bookingStart = transactionUuid ? getBookingStartFromTransaction(transaction) : getBookingStart(cart);
  const { eventWeekDay, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  const isResourceBookingType = transactionUuid
    ? transaction?.type === TRANSACTION_TYPES.RESOURCES_BOOKING
    : cart?.type === ORDER_TYPES.RESOURCE_BOOKING;

  const isServiceBookingMemberType = transactionUuid
    ? transaction?.type === TRANSACTION_TYPES.MEMBERSHIP
    : cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const vendorFormattedAddress = transactionUuid
    ? transactionDisplay?.vendor?.formatted_address
    : cart?.vendor?.formatted_address;

  const vendorName = transactionUuid ? transactionDisplay?.vendor?.name : cart?.vendor?.name;

  return (
    <SummaryContainer>
      {!isServiceBookingMemberType && (
        <ServiceDetails isSwipeModalContent={isSwipeModalContent}>
          <StyledSummarySubtitle>
            {intl.formatMessage({
              id: transactionUuid
                ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).title
                : getReceiptInfo(cart, locale as string, buildingTimeZone).title,
            })}
          </StyledSummarySubtitle>
          {!isResourceBookingType && (
            <StyledTitleBlock
              spacing={12}
              title={
                transactionUuid ? transactionDisplay?.items[0]?.display_info?.title : cart?.items[0].display_info.title
              }
              subtitle={intl.formatMessage(
                { id: 'with' },
                {
                  instructorName: transactionUuid
                    ? transactionDisplay?.items[0]?.display_info?.description1
                    : cart?.items[0].display_info.description1,
                },
              )}
            />
          )}
          <IconBlockContainer>
            <IconBlock
              icon="calendar-alt"
              iconType="fal"
              title={`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
              subtitle={
                transactionUuid
                  ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).eventBlockSubtitle
                  : getReceiptInfo(cart, locale as string, buildingTimeZone).eventBlockSubtitle
              }
            />
            {!isResourceBookingType && (
              <IconBlock
                icon="map-marker-alt"
                linkText={vendorFormattedAddress ? 'Open in maps' : ''}
                linkRef={`https://www.google.com/maps?q=${vendorFormattedAddress}`}
                iconType="fal"
                title={vendorName ?? 'default_building_name'}
                subtitle={vendorFormattedAddress ?? ''}
              />
            )}
          </IconBlockContainer>
          {isResourceBookingType && cart?.items[0]?.resource_booking?.form_responses?.length > 0 && (
            <AdditionalInformation formResponses={cart?.items[0]?.resource_booking?.form_responses} />
          )}
          {transaction?.details?.cancellation_description && (
            <CancellationInformation
              cancellationRule={transaction?.details?.cancellation_description.replace(/\n/g, '<br/>')}
            />
          )}
          {!!transaction?.details?.resource?.features?.length && (
            <Amenities
              amenitiesDescription={transaction?.details.resource?.amenities_description}
              amenities={transaction?.details?.resource?.features}
              isMobileDevice={isMobileDevice}
            />
          )}
        </ServiceDetails>
      )}
      {!!transaction?.payment_method?.type && (
        <>
          <Cart cart={cart} isCheckoutView={false} />
          <PaymentMethodContainer>
            <PaymentMethodTitle>{intl.formatMessage({ id: 'payment' })}</PaymentMethodTitle>
            <PaymentMethodReceipt
              title={intl.formatMessage(
                {
                  id: 'saved_payment_method_option',
                },
                {
                  cardBrand: capitalizedBrand,
                  cardLastFour: transaction?.payment_method?.last_four,
                  cardExpMonth: formattedExpMonth,
                  cardExpYear: formattedExpYear,
                },
              )}
              paymentMethod={transaction?.payment_method.type?.toUpperCase()}
              creditsAmount={formatCurrency(
                (transaction?.payment_method?.remaining_points || 0) / 100,
                transaction?.currency_type,
                false,
                buildingLocale,
              )}
              total={transactionUuid ? transactionDisplay?.total_summary?.total : cart?.total_summary?.total}
            />
          </PaymentMethodContainer>
        </>
      )}
    </SummaryContainer>
  );
};
