import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled from 'styled-components';

export const QuickCheckoutWrapper = styled.div`
  background: ${({ theme }) => theme.colors.universal.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 14;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${CHECKOUT_BREAK_POINT}px) {
    width: 100vw;
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: 13;
`;
