import { ACTION_STATUSES, ORDER_TYPES } from 'shared/consts';
import { ReceiptContainer, ButtonContainer, DoublePane, LeftPane, RightPane } from '../styles';
import { CancellationExpiredText } from 'pages/historicalReceipt/styles';
import React from 'react';
import { DefaultLoader } from 'components/default-loader';
import { TransactionDetails } from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from 'components/cancel-button';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';
import { ReceiptOverview } from './receipt-overview';
import { Summary } from './summary';
import { useSearchParams } from 'hooks/use-search-params.hook';

interface SwipeModalContentProps {
  isShown: boolean;
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
}

export const SwipeModalContent = ({
  isShown,
  isSwipeModalContent,
  cart,
  paymentMethods,
  isCancellationExpiredState,
  onCancelBookingClick,
  patchTransactionStatus,
  transaction,
}: SwipeModalContentProps) => {
  const { transactionUuid } = useSearchParams();
  const intl = useIntl();
  const isCancellationExpiredTextVisible: boolean =
    transaction?.details?.cancellation_window_end_minutes >= 0 &&
    transaction?.details?.cancellation_window_end_minutes !== null;

  const shouldDisplayReceipt = (cart && paymentMethods) || (transactionUuid && transaction);

  return (
    <>
      {!isShown ? (
        <DefaultLoader />
      ) : (
        <ReceiptContainer data-testid="receipt-swipe-modal">
          {shouldDisplayReceipt && (
            <>
              <DoublePane isCancellationExpiredTextVisible={isCancellationExpiredTextVisible}>
                <LeftPane className="left-pane">
                  <ReceiptOverview isSwipeModalContent={isSwipeModalContent} />
                </LeftPane>
                <RightPane className="right-pane">
                  <Summary isSwipeModalContent={isSwipeModalContent} />
                </RightPane>
              </DoublePane>
              <ButtonContainer isButtonLabelVisible className="button-container" data-testid="double-pane-button">
                {isCancellationExpiredTextVisible && (
                  <CancellationExpiredText>
                    {intl.formatMessage(
                      { id: 'notice_required' },
                      { hours: transaction.details?.cancellation_window_end_minutes / 60 },
                    )}
                  </CancellationExpiredText>
                )}
                {cart?.type === ORDER_TYPES.RESOURCE_BOOKING && (
                  <CancelButtonBlock
                    onCancel={onCancelBookingClick}
                    status={patchTransactionStatus}
                    expired={isCancellationExpiredState}
                    isReceiptCTA
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </ReceiptContainer>
      )}
    </>
  );
};
