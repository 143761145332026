import React from 'react';
import { useIntl } from 'react-intl';
import { FormResponse } from 'store/cart/types';
import { UserResponse } from 'components/user-response';
import { CollapsableBlock } from 'components/collapsable-block';

interface AdditionalInformationProps {
  formResponses: Array<FormResponse>;
}

export const AdditionalInformation = ({ formResponses }: AdditionalInformationProps): JSX.Element => {
  const intl = useIntl();

  return (
    <CollapsableBlock title={intl.formatMessage({ id: 'additional_information' })}>
      {formResponses?.map(response => (
        <UserResponse key={response.question} response={response} />
      ))}
    </CollapsableBlock>
  );
};
