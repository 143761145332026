import { ModalContainer, StyledModalWrapper } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { Container } from 'hocs/shared-styles';
import { PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { useIframeParams } from 'hooks/use-iFrame-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';

export interface PaymentIFrameProps {
  reverseAnimation: boolean;
  receiptOnClose: () => void;
}

export const PaymentIFrame = ({ reverseAnimation, receiptOnClose }: PaymentIFrameProps) => {
  const { cartId } = useSearchParams();

  const [isCheckoutDesktop, setIsCheckoutDesktop] = useState<boolean>(false);
  const [isQuickCheckout, setIsQuickCheckout] = useState<boolean>(false);
  const [is3DSChallenge, setIs3DSChallenge] = useState<boolean>(false);

  const [isContentLoading, setContentLoading] = useState<boolean>(true);
  const [isReceipt, setIsReceipt] = useState<boolean>(false);

  const quickCheckout = true;
  const url = useIframeParams(cartId as string, quickCheckout);

  const onMessage = (event: MessageEvent): void => {
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE) {
      if (isReceipt) {
        receiptOnClose();
      }
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_RECEIPT) {
      setIsQuickCheckout(false);
      setIsCheckoutDesktop(false);
      setIsReceipt(true);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_MOBILE) {
      setIsQuickCheckout(false);
      setIsReceipt(true);
    }
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_DESKTOP) {
      setIsQuickCheckout(false);
      setIsCheckoutDesktop(true);
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT) {
      setIsQuickCheckout(true);
      setIs3DSChallenge(false);
    }
    if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT_3DS_CHALLENGE) {
      setIs3DSChallenge(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const handleIframeLoading = useCallback(() => {
    setContentLoading(false);
  }, [setContentLoading]);

  const buildContent = (): JSX.Element => (
    <ModalContainer
      quickCheckout={quickCheckout}
      isCheckoutDesktop={isCheckoutDesktop}
      isQuickCheckout={isQuickCheckout}
      is3DSChallenge={is3DSChallenge}
    >
      {isContentLoading ? (
        <Container isLoading isIFrameLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : null}
      <iframe
        frameBorder="0"
        src={url}
        title="Iframe SAML"
        height="100%"
        width={isContentLoading ? '0%' : '100%'}
        data-testid="iframe_testid"
        sandbox="allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
        onLoad={handleIframeLoading}
      />
    </ModalContainer>
  );

  return (
    <StyledModalWrapper
      reverseAnimation={reverseAnimation}
      quickCheckout={quickCheckout}
      isReceipt={isReceipt}
      content={buildContent()}
      isCheckoutDesktop={isCheckoutDesktop}
      isQuickCheckout={isQuickCheckout}
    />
  );
};
