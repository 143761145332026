import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import {
  AppThemeProvider,
  defaultTheme,
  DefaultThemeWithFonts,
} from '@hqo/react-components-library/dist/molecules/theme';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_STATUSES } from 'shared/consts';
import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { Container } from 'hocs/shared-styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';
import { selectBuildingStatus } from 'store/building/selectors';
import { setFeatureFlags } from 'store/feature-flags/actions';
import { useBuilding } from 'hooks/use-building.hook';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useViewParams } from 'hooks/use-view-params.hook';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const dispatch = useDispatch();
  const params = useViewParams();
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useBuildingTheme();
  const building = useBuilding();
  const lDFlags = useFlags();
  dispatch(setFeatureFlags(lDFlags));

  const buildingStatusFinished =
    buildingStatus === ACTION_STATUSES.FULFILLED || buildingStatus === ACTION_STATUSES.REJECTED;
  const hasBuilding = building || params.buildingUuid;

  if (theme && (buildingStatusFinished || !hasBuilding)) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as DefaultThemeWithFonts}>{children}</AppThemeProvider>
      </>
    );
  }

  return (
    <Container data-testid="loading-container" isLoading>
      <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
    </Container>
  );
};
