import { ACTION_STATUSES } from 'shared/consts';
import * as paymentsConfigActions from 'store/payments-config/actions';
import { PaymentsConfigState } from 'store/payments-config/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: PaymentsConfigState = {
  paymentsConfig: null,
  getPaymentsConfig: {
    status: null,
    error: null,
  },
};

const getPaymentsConfigRequestHandler = (state: PaymentsConfigState): PaymentsConfigState => ({
  ...state,
  getPaymentsConfig: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getPaymentsConfigSuccessHandler = (
  state: PaymentsConfigState,
  { payload }: ActionType<typeof paymentsConfigActions.getPaymentsConfig.success>,
): PaymentsConfigState => ({
  ...state,
  paymentsConfig: payload,
  getPaymentsConfig: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getPaymentsConfigFailureHandler = (
  state: PaymentsConfigState,
  { payload: error }: ActionType<typeof paymentsConfigActions.getPaymentsConfig.failure>,
): PaymentsConfigState => ({
  ...state,
  getPaymentsConfig: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const paymentsConfigReducer = createReducer(initialState)
  .handleAction(paymentsConfigActions.getPaymentsConfig.request, getPaymentsConfigRequestHandler)
  .handleAction(paymentsConfigActions.getPaymentsConfig.success, getPaymentsConfigSuccessHandler)
  .handleAction(paymentsConfigActions.getPaymentsConfig.failure, getPaymentsConfigFailureHandler);

export default paymentsConfigReducer;
