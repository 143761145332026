import styled from 'styled-components';

export const AmenityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AmenityDescription = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const AmenityLink = styled.a`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.secondary};
`;

export const DefaultAmenityDescription = styled.div`
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;
