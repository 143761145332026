import { Text, Title } from './styles';

import { ORDER_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';
import React from 'react';
import { useIntl } from 'react-intl';

export interface CancellationPolicyProps {
  cart: Order;
}

export const CancellationPolicy = ({ cart }: CancellationPolicyProps) => {
  const intl = useIntl();

  return (
    cart.type === ORDER_TYPES.RESOURCE_BOOKING && (
      <>
        <Title>{intl.formatMessage({ id: 'cancellation_title' })}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: `${cart.items[0].display_info?.cancellation_description?.replace(/\n/g, '<br/>')}`,
          }}
        />
      </>
    )
  );
};
