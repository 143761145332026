import { Background, Container, Content } from './styles';
import React, { FunctionComponent, useEffect } from 'react';

import ReactDOM from 'react-dom';

export interface Props {
  content: JSX.Element;
  onClose?: VoidFunction;
  backgroundColor?: string;
  withExitButton?: boolean;
  onPressBack?: VoidFunction;
  withBackButton?: boolean;
  className?: string;
  contentStyles?: React.CSSProperties | React.StyleHTMLAttributes<HTMLElement>;
}

const IframeModalWrapper: FunctionComponent<Props> = ({ content, backgroundColor, className, contentStyles }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const modal = (
    <div className={className}>
      <Background className="iframe-background" />
      <Container backgroundColor={backgroundColor} data-testid="iframe-modal-wrapper" className="iframe-wrapper">
        <Content style={contentStyles} className="iframe-modal-content">
          {content}
        </Content>
      </Container>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default IframeModalWrapper;
