/* eslint-disable max-lines-per-function */
import { formatDate, getEventDuration, getFormattedEventDuration } from './formatDate';

import { ORDER_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';

export const getReceiptInfo = (cart: Order, locale: string, timezone?: string) => {
  switch (cart.type) {
    case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
      return { title: 'summary' };
    case ORDER_TYPES.SERVICE_BOOKING_CLASS:
      return {
        title: 'class_details',
        eventBlockSubtitle: getFormattedEventDuration(
          cart.items[0].service_booking.start_at,
          cart.items[0].service_booking.end_at,
          locale as string,
          timezone,
        ),
      };
    case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
      return {
        title: 'appointment_details',
        eventBlockSubtitle: `${
          formatDate(cart.items[0].service_booking?.start_at, locale as string, timezone).formatEventStartTime
        } / ${getEventDuration(cart)} min`,
      };
    case ORDER_TYPES.RESOURCE_BOOKING:
      return {
        title: 'details',
        eventBlockSubtitle: getFormattedEventDuration(
          cart.items[0].resource_booking.start_at,
          cart.items[0].resource_booking.end_at,
          locale as string,
          timezone,
        ),
      };
    default:
      return { title: 'default_details' };
  }
};
