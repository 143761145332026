import { CallHistoryMethodAction, replace } from 'connected-react-router';
import qs from 'qs';

export const replaceSearchString = (
  searchString: string,
  pathName: string,
): CallHistoryMethodAction<[string, unknown?]> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { authToken, apiUrl, ...restSearch } = qs.parse(searchString.replace(/^\?/, ''));
  const newSearchString = qs.stringify(restSearch);

  return replace(newSearchString ? `${pathName}?${newSearchString}` : pathName);
};
