import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Icon = styled(FontAwesomeIcon)`
  position: relative;
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 1.18em;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 22px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 17px;
  right: 17px;
  cursor: pointer;
`;
