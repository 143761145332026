import styled from 'styled-components';

import { DoublePaneModal } from 'components/modals/doublePaneModal/doublePaneModal';
import { IFRAME_WIDTH } from 'shared/consts';

export const ReceiptContainer = styled.div``;

export const StyledDoublePaneModal = styled(DoublePaneModal)`
  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    .button-container {
      border-top: 1px solid ${({ theme }) => theme.colors.greys.adminGreyLight};
    }

    .right-pane {
      height: calc(100vh - 150px);
    }

    width: auto;
    height: 100vh;
    padding: 24px 0;
    align-items: flex-start;
    position: absolute;
  }
`;

export const ButtonContainer = styled.div<{ isButtonLabelVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  @media only screen and (max-width: ${IFRAME_WIDTH}px) {
    background: ${({ theme }) => theme.colors.$white};
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 30px);
    ${({ isButtonLabelVisible }) => (isButtonLabelVisible ? `height: auto;` : 'height: 48px;')};
    padding: 10px 15px;
  }
`;

export const DoublePane = styled.div<{ isCancellationExpiredTextVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ isCancellationExpiredTextVisible }) =>
    isCancellationExpiredTextVisible ? 'calc(100vh - 92px)' : '100vh'};
  gap: 20px;
  flex-grow: 1;
`;

export const RightPane = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.$transparent};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.$sharedGreyLight};
    border-radius: 4px;
  }
`;

export const LeftPane = styled.div``;
