import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'components/intl-provider';
import { Provider } from 'react-redux';
import React from 'react';
import { RootState } from 'store';
import { createStore } from 'redux';
import { ACTION_STATUSES } from 'shared/consts';
import { BuildingState } from 'store/building/types';
import { ConfigState } from 'store/config/types';
import { mock } from 'test-utils/mock';

interface TestWrapperProps {
  children?: React.ReactNode;
  state: Partial<RootState>;
}

const MOCKED_CONFIG = {
  apiUrl: 'url',
} as ConfigState;

const MOCKED_BUILDING = {
  building: {
    uuid: '1',
  },
  getBuilding: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
} as BuildingState;

export const TestWrapper: React.FC<TestWrapperProps> = ({ children, state }: TestWrapperProps): JSX.Element => {
  const MOCKED_STATE = mock<RootState>({
    ...state,
  });

  MOCKED_STATE.config = state?.config || MOCKED_CONFIG;
  MOCKED_STATE.building = state?.building || MOCKED_BUILDING;
  const store = createStore(() => MOCKED_STATE);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <IntlProvider>
          <AppThemeProvider theme={defaultTheme}>{children}</AppThemeProvider>
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  );
};
