import * as altBuildingsEpics from 'store/alt-buildings/epics';
import { handleGenericErrors, handleFailedRequests } from 'store/errors/epics';
import * as themeEpics from 'store/theme/epics';
import * as userEpics from 'store/user/epics';
import * as paymentsConfigEpics from 'store/payments-config/epics';
import * as paymentMethodsEpics from 'store/payment/epics';
import * as cartEpics from 'store/cart/epics';
import * as addCardEpics from 'store/add-card/epics';
import * as transactionsEpics from 'store/transactions/epics';
import * as buildingEpics from 'store/building/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(themeEpics),
  handleFailedRequests,
  handleGenericErrors,
  ...Object.values(altBuildingsEpics),
  ...Object.values(paymentMethodsEpics),
  ...Object.values(paymentsConfigEpics),
  ...Object.values(cartEpics),
  ...Object.values(addCardEpics),
  ...Object.values(transactionsEpics),
  ...Object.values(buildingEpics),
);
