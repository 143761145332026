import { ACTION_STATUSES, PAYMENT_TYPE } from 'shared/consts';
import { getPaymentMethods, setCurrentPaymentMethodId } from 'store/payment/actions';
import {
  selectCurrentPaymentMethodId,
  selectGetPaymentMethodsStatus,
  selectPaymentMethods,
} from 'store/payment/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { SavedPaymentMethod } from 'store/payment/types';
import { findCurrentPaymentMethod } from 'utils/findCurrentPaymentMethod';
import { useEffect } from 'react';

export interface UsePaymentMethodsInterface {
  isPaymentMethodsLoading: boolean;
  paymentMethods: Array<SavedPaymentMethod>;
  isPaymentMethodsFullfiled: boolean;
  currentPaymentMethodId: string;
}

export const usePaymentMethods = (cartId: string): UsePaymentMethodsInterface => {
  const currentPaymentMethodId = useSelector(selectCurrentPaymentMethodId);
  const paymentMethodsStatus = useSelector(selectGetPaymentMethodsStatus);
  const paymentMethods = useSelector(selectPaymentMethods);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPaymentMethods.request(cartId));
  }, [dispatch, cartId]);

  useEffect(() => {
    const isExternalPaymentMethod =
      currentPaymentMethodId === PAYMENT_TYPE.APPLE_PAY ||
      currentPaymentMethodId === PAYMENT_TYPE.GOOGLE_PAY ||
      currentPaymentMethodId === PAYMENT_TYPE.EXTERNAL;

    if (
      paymentMethods &&
      paymentMethods.length > 0 &&
      (!currentPaymentMethodId ||
        (!findCurrentPaymentMethod(paymentMethods, currentPaymentMethodId) && !isExternalPaymentMethod))
    ) {
      dispatch(setCurrentPaymentMethodId(paymentMethods[paymentMethods.length - 1]?.id?.toString()));
    }
  }, [paymentMethods, currentPaymentMethodId]);

  const isPaymentMethodsLoading = paymentMethodsStatus === ACTION_STATUSES.PENDING;
  const isPaymentMethodsFullfiled = paymentMethodsStatus === ACTION_STATUSES.FULFILLED;

  return { isPaymentMethodsLoading, paymentMethods, isPaymentMethodsFullfiled, currentPaymentMethodId };
};
