import { SavedPaymentMethod } from '../store/payment/types';
import { CREDIT_POINTS, INVOICE } from 'shared/consts/payment-method-types';

export const formatSavedPaymentMethodLabel = (
  savedPaymentMethod: SavedPaymentMethod,
): { formattedExpMonth: string; capitalizedBrand: string; formattedExpYear: string } | null => {
  if (
    savedPaymentMethod?.payment_method_type === INVOICE ||
    savedPaymentMethod?.payment_method_type === CREDIT_POINTS
  ) {
    return null;
  }
  const capitalizedBrand =
    savedPaymentMethod &&
    `${savedPaymentMethod?.card_brand.charAt(0).toUpperCase()}${savedPaymentMethod?.card_brand.slice(1)}`;

  const formattedExpMonth =
    savedPaymentMethod &&
    (savedPaymentMethod?.card_exp_month.length < 2
      ? `0${savedPaymentMethod.card_exp_month}`
      : savedPaymentMethod.card_exp_month);

  const formattedExpYear = savedPaymentMethod && savedPaymentMethod?.card_exp_year.slice(2);

  return { capitalizedBrand, formattedExpMonth, formattedExpYear };
};
