import { externalLogin } from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './use-search-params.hook';
import { currentUser } from 'store/user/selectors';
import { AuthParams } from 'shared/types';

export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const { authToken, apiUrl } = useSearchParams<AuthParams>();
  const user = useSelector(currentUser);
  const shouldWaitForLogin = !!authToken && !!apiUrl;

  useEffect(() => {
    const getUserTimeout = setTimeout(() => {
      if (!user && shouldWaitForLogin) {
        dispatch(externalLogin.request({ authToken, apiUrl }));
      }
    }, 1000);

    return () => {
      clearTimeout(getUserTimeout);
    };
  }, [shouldWaitForLogin, user, dispatch]);

  return shouldWaitForLogin;
};
