import styled from 'styled-components';

export const IframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .SpreedlyIFrameStyle {
    height: 300px;
    padding-left: 50px;
    border: 0px;
  }
`;
