import { DoublePaneModal } from 'components/modals/doublePaneModal/doublePaneModal';
import styled from 'styled-components';
import { IFRAME_WIDTH } from 'shared/consts';
import { Image } from '@hqo/react-components-library';

export const StyledDoublePaneModal = styled(DoublePaneModal)`
  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    width: auto;
    height: calc(100vh - 48px);
    padding: 24px 0;
    align-items: flex-start;
  }
`;

export const CheckoutWrapper = styled.div``;

export const TitleContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 215px;
  object-fit: cover;
`;

export const RightPaneContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.$greyLight};
  height: 100%;
`;

export const LeftPaneContainer = styled.div`
  margin-top: 62px;
  margin-left: 16px;
`;

export const ContentContainer = styled.div`
  height: 550px;
  overflow-y: scroll;
`;

export const DesktopModalContainer = styled.div`
  width: 872px;
`;

export const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.$white};
  position: fixed;
  left: 32px;
  bottom: 32px;
  width: 160px;
  height: 36px;
`;
