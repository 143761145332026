import { AddNewCardButtonContainer, AddNewCardButtonText, PlusIcon } from '../styles';

import { INVOICE } from 'shared/consts/payment-method-types';
import React from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { useIntl } from 'react-intl';

export interface AddCardButtonProps {
  paymentMethods: Array<SavedPaymentMethod>;
  onAddNewCard: VoidFunction;
}

export const AddCardButton = ({ paymentMethods, onAddNewCard }: AddCardButtonProps) => {
  const intl = useIntl();

  if (paymentMethods?.length === 1 && paymentMethods?.[0].payment_method_type === INVOICE) return null;
  return (
    <AddNewCardButtonContainer data-testid="add-new-card-button" onClick={onAddNewCard}>
      <PlusIcon icon={['far', 'plus']} />
      <AddNewCardButtonText>
        {intl.formatMessage({
          id: 'add_new_card',
        })}
      </AddNewCardButtonText>
    </AddNewCardButtonContainer>
  );
};
