import { StateType } from 'typesafe-actions';
import altBuildingsReducer from 'store/alt-buildings/reducer';
import buildingReducer from 'store/building/reducer';
import cartReducer from 'store/cart/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import featureFlagReducer from 'store/feature-flags/reducer';
import paymentReducer from 'store/payment/reducer';
import paymentsConfigReducer from 'store/payments-config/reducer';
import routerReducer from 'store/router/reducer';
import spreedlyReducer from 'store/add-card/reducers';
import themeReducer from 'store/theme/reducer';
import transactionsReducer from 'store/transactions/reducer';
import userReducer from 'store/user/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  router: routerReducer,
  user: userReducer,
  theme: themeReducer,
  errors: errorsReducer,
  altBuildings: altBuildingsReducer,
  payment: paymentReducer,
  paymentsConfig: paymentsConfigReducer,
  cart: cartReducer,
  spreedly: spreedlyReducer,
  transaction: transactionsReducer,
  transactions: transactionsReducer,
  building: buildingReducer,
  featureFlag: featureFlagReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
