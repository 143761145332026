import { ACTION_STATUSES, PAYMENT_TYPE, PLATFORM_TYPE, REGISTERED_SIGN } from 'shared/consts';
import { CREDIT_POINTS, INVOICE } from 'shared/consts/payment-method-types';
import { EditPaymentContainer, EditPaymentContainerTitle } from './styles';
import { ExternalPaymentLabel, UIEnablements } from 'store/cart/types';
import React, { useCallback, useEffect, useState } from 'react';
import { getPaymentMethods, localUpdatePaymentMethod, updatePaymentMethod } from 'store/payment/actions';
import { selectComplete3DSCartError, selectSubmitCartError, selectSubmitCartStatus } from 'store/cart/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { AddCardButton } from './components/AddCardButton';
import { RadioFormContent } from './components/RadioFormContent';
import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { CREDIT_POINTS_METHOD_ID, SavedPaymentMethod } from 'store/payment/types';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import {
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
  selectUpdatePaymentMethodStatus,
} from 'store/payment/selectors';
import { useIntl } from 'react-intl';
import { selectBuildingLocale } from 'store/building/selectors';

export interface EditPaymentProps {
  isCheckoutMobile?: boolean;
  formTitle?: string;
  enabledPaymentMethods: UIEnablements;
  paymentMethods: Array<SavedPaymentMethod>;
  selectedPaymentMethodId?: string;
  onRemovePaymentMethod: VoidFunction;
  onAddNewCard: VoidFunction;
  submittedPaymentMethodId?: number;
  currentPaymentError?: boolean;
  cartId: string;
  currencyType?: string;
  total?: number;
  isSwipeModalContent?: boolean;
}

export const EditPayment = ({
  isCheckoutMobile = false,
  formTitle,
  enabledPaymentMethods,
  paymentMethods,
  selectedPaymentMethodId,
  onRemovePaymentMethod,
  onAddNewCard,
  submittedPaymentMethodId,
  currentPaymentError,
  cartId,
  currencyType,
  total,
  isSwipeModalContent = false,
}: EditPaymentProps) => {
  const intl = useIntl();
  const [errorPaymentId, setErrorPaymentId] = useState<number>(undefined);
  const dispatch = useDispatch();

  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const cartError = useSelector(selectSubmitCartError);
  const updatePaymentMethodStatus = useSelector(selectUpdatePaymentMethodStatus);
  const complete3DSCartError = useSelector(selectComplete3DSCartError);
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);

  // TODO: Add error message if submitCartStatus is fullfilled, but transcation failed. HELIX fix https://ventureapp.atlassian.net/browse/SONIC-564
  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED || complete3DSCartError || currentPaymentError) {
      setErrorPaymentId(submittedPaymentMethodId);
    } else {
      setErrorPaymentId(undefined);
    }
  }, [submitCartStatus, submittedPaymentMethodId, complete3DSCartError, currentPaymentError]);

  const buildExternalPaymentLabel = useCallback(
    (platformType: PLATFORM_TYPE) => {
      if (platformType === PLATFORM_TYPE.ANDROID) {
        return `${intl.formatMessage({
          id: PAYMENT_TYPE.GOOGLE_PAY,
        })}${REGISTERED_SIGN}`;
      }
      return `${intl.formatMessage({
        id: PAYMENT_TYPE.APPLE_PAY,
      })}${REGISTERED_SIGN}`;
    },
    [intl],
  );

  const buildSavedPaymentLabel = useCallback(
    (paymentMethod: SavedPaymentMethod) => {
      const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod);

      return intl.formatMessage(
        {
          id: 'saved_payment_method_option',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod.card_last_four,
          cardExpMonth: formattedExpMonth,
          cardExpYear: formattedExpYear,
        },
      );
    },
    [intl],
  );

  const buildExternalPaymentLabels = useCallback(() => {
    const externalPaymentLabels: ExternalPaymentLabel[] = [];

    // TODO: Add logic back from https://ventureapp.atlassian.net/browse/MAIN-19201 once external payments are supported via SDK

    return externalPaymentLabels;
  }, [buildExternalPaymentLabel, enabledPaymentMethods?.apple_pay_enabled, enabledPaymentMethods?.google_pay_enabled]);

  const buildSavedPaymentLabels = useCallback(() => {
    return paymentMethods?.map(paymentMethod => {
      const errorMessage = intl.formatMessage({ id: getCartErrorMessage(cartError) });
      const notEnoughCredits = paymentMethod?.points_balance / 100 < total;
      let label;
      let subtitle;
      if (paymentMethod?.payment_method_type === INVOICE) {
        label = intl.formatMessage({ id: 'invoice' });
        subtitle = intl.formatMessage({ id: 'invoice_text' });
      } else if (paymentMethod?.payment_method_type === CREDIT_POINTS) {
        label = intl.formatMessage({ id: 'pay_with_credits' });
        subtitle = notEnoughCredits
          ? intl.formatMessage({ id: 'not_enough_credits' })
          : intl.formatMessage(
              { id: 'credits_remaining' },
              {
                remainingCredits: `${formatCurrency(
                  paymentMethod?.points_balance / 100 - total,
                  currencyType,
                  false,
                  buildingLocale,
                )}`,
              },
            );
      } else {
        label = buildSavedPaymentLabel(paymentMethod);
        subtitle = null;
      }

      return {
        label,
        subtitle,
        value: paymentMethod?.id.toString(),
        canRemoveOption:
          paymentMethod?.payment_method_type !== INVOICE && paymentMethod?.payment_method_type !== CREDIT_POINTS,
        errorMessage: paymentMethod?.id === errorPaymentId ? errorMessage : undefined,
        disabled: paymentMethod?.payment_method_type === CREDIT_POINTS && notEnoughCredits,
      };
    });
  }, [paymentMethods, buildSavedPaymentLabel, errorPaymentId, intl]);

  const onPressHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== CREDIT_POINTS_METHOD_ID) {
        if (selectedPaymentMethodId === CREDIT_POINTS_METHOD_ID) {
          dispatch(getPaymentMethods.request(cartId));
        } else {
          dispatch(
            updatePaymentMethod.request({
              default: true,
              card_id: event.target.value,
              cartId,
            }),
          );
        }
      } else {
        dispatch(localUpdatePaymentMethod(+CREDIT_POINTS_METHOD_ID));
      }
    },
    [dispatch, cartId, selectedPaymentMethodId],
  );

  return (
    <EditPaymentContainer data-testid="edit-payment" isSwipeModalContent={isSwipeModalContent}>
      {isCheckoutMobile && (
        <EditPaymentContainerTitle data-testid="edit-payment-title">
          {intl.formatMessage({
            id: 'payment',
          })}
        </EditPaymentContainerTitle>
      )}
      <RadioFormContent
        paymentMethods={paymentMethods}
        options={buildSavedPaymentLabels() as RadioOption[]}
        title={formTitle}
        onPress={onPressHandler}
        selectedPaymentMethodId={selectedPaymentMethodId}
        onRemovePaymentMethod={onRemovePaymentMethod}
        disabled={
          (updatePaymentMethodStatus && updatePaymentMethodStatus !== ACTION_STATUSES.FULFILLED) ||
          getPaymentMethodStatus === ACTION_STATUSES.PENDING ||
          deletePaymentMethodsStatus === ACTION_STATUSES.PENDING ||
          savePaymentMethodStatus === ACTION_STATUSES.PENDING
        }
      />
      <RadioForm
        data-testid="external-payment-buttons"
        options={buildExternalPaymentLabels() as RadioOption[]}
        onPress={onPressHandler}
        selected={selectedPaymentMethodId}
      />
      <AddCardButton paymentMethods={paymentMethods} onAddNewCard={onAddNewCard} />
    </EditPaymentContainer>
  );
};
