import { ACTION_STATUSES, IMG_URL } from 'shared/consts';
import {
  ButtonContainer,
  CheckoutWrapper,
  ContentContainer,
  DesktopModalContainer,
  LeftPaneContainer,
  RightPaneContainer,
  StyledImage,
  TitleContainer,
} from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { TwoColumnContent } from '@hqo/react-components-library/dist/molecules/twoColumnContent';

import AddCard from 'components/add-card';
import { CancellationPolicy } from '../components/cancellationPolicy';
import { Cart } from 'components/cart';
import { CheckoutButton } from 'components/checkout-button/checkoutButton';
import { Container } from 'hocs/shared-styles';
import { EditPayment } from 'components/edit-payment';
import { Order } from 'store/cart/types';
import React from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { TextContainer } from '../components/styles';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';

interface CheckoutDesktopProps {
  cart: Order;
  paymentMethods: Array<SavedPaymentMethod>;
  currentPaymentMethodId: string;
  submittedPaymentMethodId?: number;
  currentPaymentError?: boolean;
  onRemovePaymentMethod: VoidFunction;
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  showAddCardForm: boolean;
  onCancelAddCardClick: VoidFunction;
  cartId: string;
}

export const CheckoutDesktop = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  currentPaymentError,
  onRemovePaymentMethod,
  onPayClick,
  submitCartStatus,
  showAddCardForm,
  onCancelAddCardClick,
  cartId,
}: CheckoutDesktopProps) => {
  const intl = useIntl();
  const { currencyType, total } = formatSummaryValues(cart?.total_summary);

  return (
    <DesktopModalContainer>
      {!cart && !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <CheckoutWrapper>
          <TwoColumnContent
            isCheckoutDesktop
            isButtonVisible={false}
            LeftPaneContent={
              <LeftPaneContainer>
                <TitleContainer>
                  <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
                </TitleContainer>
                <ContentContainer>
                  <EditPayment
                    isCheckoutMobile
                    enabledPaymentMethods={cart.ui_enablements}
                    paymentMethods={paymentMethods}
                    submittedPaymentMethodId={submittedPaymentMethodId}
                    currentPaymentError={currentPaymentError}
                    selectedPaymentMethodId={currentPaymentMethodId}
                    onRemovePaymentMethod={onRemovePaymentMethod}
                    onAddNewCard={onAddPaymentMethodClick}
                    cartId={cartId}
                    currencyType={currencyType}
                    total={total}
                  />
                  {showAddCardForm && (
                    <AddCard
                      onCancel={onCancelAddCardClick}
                      spreedlyEnvKey={cart.ui_enablements?.spreedly_env_token}
                      cartId={cartId}
                    />
                  )}
                  {cart.items[0].display_info?.cancellation_description && (
                    <TextContainer showAddCardForm={showAddCardForm}>
                      <CancellationPolicy cart={cart} />
                    </TextContainer>
                  )}
                </ContentContainer>
                <ButtonContainer>
                  <CheckoutButton
                    text={
                      cart.total_summary?.total
                        ? intl.formatMessage({ id: 'confirm_and_pay' })
                        : intl.formatMessage({ id: 'confirm_and_book' })
                    }
                    isDisabled={
                      !currentPaymentMethodId || submitCartStatus === ACTION_STATUSES.PENDING || showAddCardForm
                    }
                    isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
                    onClick={onPayClick}
                  />
                </ButtonContainer>
              </LeftPaneContainer>
            }
            RightPaneContent={
              <RightPaneContainer>
                <StyledImage src={IMG_URL + cart.vendor?.logo_url} alt="service_logo" />
                <Cart isCheckoutView cart={cart} />
              </RightPaneContainer>
            }
          />
        </CheckoutWrapper>
      )}
    </DesktopModalContainer>
  );
};
