import styled from 'styled-components';

interface ContainerProps {
  isLoading: boolean;
  isModal?: boolean;
  isIFrameLoading?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => isLoading && 'center'};
  height: 100vh;
  height: ${({ isModal, isIFrameLoading }) => (isModal || isIFrameLoading) && '100%'};
`;
