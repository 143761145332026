import React from 'react';
import useHistoricalReceipt from './use-historical-receipt.hook';
import { HistoricalReceiptContent } from './components/historical-receipt-content';
import { SwipeModalContent } from './components/swipe-modal-content';

export const HistoricalReceiptPage = () => {
  const {
    isShown,
    itemId,
    itemType,
    isCancellationExpiredState,
    isSwipeModalContent,
    onCancelBookingClick,
    onCloseClick,
    patchTransactionStatus,
    transaction,
    isCancelBookingStep,
    setIsCancelBookingStep,
  } = useHistoricalReceipt();

  return isSwipeModalContent ? (
    <SwipeModalContent
      isShown={isShown}
      itemId={itemId}
      itemType={itemType}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionStatus={patchTransactionStatus}
      isCancellationExpiredState={isCancellationExpiredState}
      isSwipeModalContent={isSwipeModalContent}
      isCancelBookingStep={isCancelBookingStep}
      setIsCancelBookingStep={setIsCancelBookingStep}
    />
  ) : (
    <HistoricalReceiptContent
      isShown={isShown}
      itemId={itemId}
      itemType={itemType}
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionStatus={patchTransactionStatus}
      isCancellationExpiredState={isCancellationExpiredState}
      onCloseClick={onCloseClick}
    />
  );
};
