import { CHECKOUT_PATH, QUICK_CHECKOUT_PATH } from 'shared/consts';

import { configSelector } from 'store/config/selectors';
import { getMicroFrontendParams } from 'utils';
import { useSelector } from 'react-redux';
import { userState } from 'store/user/selectors';

export const useIframeParams = (cartId: string, isQuickCheckout: boolean): string => {
  const stateUser = useSelector(userState);
  const config = useSelector(configSelector);
  const params = getMicroFrontendParams(
    stateUser.user,
    config.apiUrl,
    stateUser.token,
    config.buildingUuid,
    cartId,
    config.locale,
  );
  const checkoutPath = isQuickCheckout ? QUICK_CHECKOUT_PATH : CHECKOUT_PATH;

  return `${process.env.REACT_APP_PAYMENTS_MINIAPP_URL}${checkoutPath}/${cartId}?${params}`;
};
