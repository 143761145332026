import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

export const getTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getTransaction(payload)
        .pipe(
          map(xhrPayload => {
            return actions.getTransaction.success(xhrPayload.response.data);
          }),
          catchError((error: Error) => of(actions.getTransaction.failure({ error }))),
        );
    }),
  );

export const getRedirectTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getRedirectTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getRedirectTransaction(payload)
        .pipe(
          map(xhrPayload => {
            return actions.getRedirectTransaction.success(xhrPayload.response.data.transaction);
          }),
          catchError((error: Error) => of(actions.getRedirectTransaction.failure({ error }))),
        );
    }),
  );

export const patchTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.patchTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .patchTransaction(payload)
        .pipe(
          map(() => {
            return actions.patchTransaction.success();
          }),
          catchError((error: Error) => of(actions.patchTransaction.failure({ error }))),
        );
    }),
  );

export const getUserTransactionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getUserTransaction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getUserTransaction(payload)
        .pipe(
          map(xhrPayload => {
            return actions.getUserTransaction.success(xhrPayload.response.data);
          }),
          catchError((error: Error) => of(actions.getUserTransaction.failure({ error }))),
        );
    }),
  );
