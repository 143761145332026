import * as cartActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { CartState } from './types';

export const initialState: CartState = {
  cart: null,
  transaction: null,
  getCart: {
    status: null,
    error: null,
  },
  submitCart: {
    status: null,
    error: null,
  },
  complete3DSCart: {
    status: null,
    error: null,
  },
  applyPromoCodeToCart: {
    status: null,
    error: null,
  },
  removePromoCodeFromCart: {
    status: null,
    error: null,
  },
};

const getCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  getCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof cartActions.getCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  getCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof cartActions.getCart.failure>,
): CartState => ({
  ...state,
  getCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const complete3DSCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof cartActions.complete3DSCart.success>,
): CartState => ({
  ...state,
  transaction: payload,
  complete3DSCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const complete3DSCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof cartActions.complete3DSCart.failure>,
): CartState => ({
  ...state,
  complete3DSCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const complete3DSCartResetHandler = (state: CartState): CartState => ({
  ...state,
  transaction: null,
  complete3DSCart: {
    error: null,
    status: null,
  },
});

const submitCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const submitCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof cartActions.submitCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  submitCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const submitCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof cartActions.submitCart.failure>,
): CartState => ({
  ...state,
  submitCart: {
    error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const applyPromoCodeToCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  applyPromoCodeToCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const applyPromoCodeToCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof cartActions.applyPromoCodeToCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  applyPromoCodeToCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const applyPromoCodeToCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof cartActions.applyPromoCodeToCart.failure>,
): CartState => ({
  ...state,
  applyPromoCodeToCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const removePromoCodeFromCartRequestHandler = (state: CartState): CartState => ({
  ...state,
  removePromoCodeFromCart: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const removePromoCodeFromCartSuccessHandler = (
  state: CartState,
  { payload }: ActionType<typeof cartActions.removePromoCodeFromCart.success>,
): CartState => ({
  ...state,
  cart: payload,
  removePromoCodeFromCart: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const removePromoCodeFromCartFailureHandler = (
  state: CartState,
  { payload: error }: ActionType<typeof cartActions.removePromoCodeFromCart.failure>,
): CartState => ({
  ...state,
  removePromoCodeFromCart: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const cartReducer = createReducer(initialState)
  .handleAction(cartActions.getCart.request, getCartRequestHandler)
  .handleAction(cartActions.getCart.success, getCartSuccessHandler)
  .handleAction(cartActions.getCart.failure, getCartFailureHandler)
  .handleAction(cartActions.complete3DSCart.request, complete3DSCartRequestHandler)
  .handleAction(cartActions.complete3DSCart.success, complete3DSCartSuccessHandler)
  .handleAction(cartActions.complete3DSCart.failure, complete3DSCartFailureHandler)
  .handleAction(cartActions.resetComplete3DSCart, complete3DSCartResetHandler)
  .handleAction(cartActions.submitCart.request, submitCartRequestHandler)
  .handleAction(cartActions.submitCart.success, submitCartSuccessHandler)
  .handleAction(cartActions.submitCart.failure, submitCartFailureHandler)
  .handleAction(cartActions.applyPromoCodeToCart.request, applyPromoCodeToCartRequestHandler)
  .handleAction(cartActions.applyPromoCodeToCart.success, applyPromoCodeToCartSuccessHandler)
  .handleAction(cartActions.applyPromoCodeToCart.failure, applyPromoCodeToCartFailureHandler)
  .handleAction(cartActions.removePromoCodeFromCart.request, removePromoCodeFromCartRequestHandler)
  .handleAction(cartActions.removePromoCodeFromCart.success, removePromoCodeFromCartSuccessHandler)
  .handleAction(cartActions.removePromoCodeFromCart.failure, removePromoCodeFromCartFailureHandler);

export default cartReducer;
