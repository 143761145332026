import { StyledFormField } from 'components/promo-code-input/styles';
import React, { ChangeEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';

interface PromoCodeInputContentProps {
  dataTestId?: string;
  handleOnEnter: () => void;
  hasError?: boolean;
  promoCode: string;
  updatePromoCode: (text: string) => void;
}

export const PromoCodeInputContent = ({
  dataTestId,
  handleOnEnter,
  hasError,
  promoCode,
  updatePromoCode,
}: PromoCodeInputContentProps): JSX.Element => {
  const intl = useIntl();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updatePromoCode(event.target.value);
    },
    [updatePromoCode],
  );

  const handleKeyboardEnter = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter' && promoCode.trim().length) {
        handleOnEnter();
      }
    },
    [handleOnEnter, promoCode],
  );

  return (
    <StyledFormField
      autoFocus
      data-testid={dataTestId}
      errorText={hasError && intl.formatMessage({ id: 'promo_code_is_invalid' })}
      fieldType="input"
      label={intl.formatMessage({ id: 'promo_code' })}
      onChange={handleOnChange}
      onKeyDown={handleKeyboardEnter}
      value={promoCode}
    />
  );
};
