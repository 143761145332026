import { ActionType, createReducer } from 'typesafe-actions';

import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { setFeatureFlags } from './actions';

export const initialState: LDFlagSet = null;
const setFeatureFlagsHandler = (state: LDFlagSet, { payload }: ActionType<typeof setFeatureFlags>): LDFlagSet => ({
  ...state,
  ...payload,
});
const featureFlagsReducer = createReducer(initialState).handleAction(setFeatureFlags, setFeatureFlagsHandler);
export default featureFlagsReducer;
