import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { RadioForm } from '@hqo/react-components-library';

export const EditPaymentContainer = styled.div<{ isSwipeModalContent: boolean }>`
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const EditPaymentContainerTitle = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const EditPaymentContainerSubtitle = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const AddNewCardButtonContainer = styled.div`
  width: auto;
  display: flex;
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  cursor: pointer;
`;

export const PlusIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px/24px;
  margin-right: 13px;
`;

export const AddNewCardButtonText = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledRadioForm = styled(RadioForm)`
  .error-message-label {
    padding-right: 10px;
  }
`;
