import { PATCH_STATUSES, TRANSACTION_STATES } from 'shared/consts';

import { DiscountTypes, FormResponse, Item, OrderTotal } from '../cart/types';
import { TRANSACTION_TYPES } from 'shared/consts/transaction-types';

export interface PatchTransactionDto {
  transactionId: string;
  status: PATCH_STATUSES;
}

export enum SupportedPaymentMethods {
  INVOICE = 'INVOICE',
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_POINTS = 'CREDIT_POINTS',
}

export interface TransactionState {
  transactionId: string;
  transaction: TransactionDetails;
  getTransaction: AsyncActionTypes;
  patchTransaction: AsyncActionTypes;
  getRedirectTransaction: AsyncActionTypes;
  redirectTransaction: RedirectTransactionDetails;
}

export interface GetTransactionResponse {
  data: TransactionDetails;
}
export interface RedirectTransactionResponse {
  data: { transaction: RedirectTransactionDetails };
}

export interface RedirectTransactionDetails {
  token: string;
  succeeded: boolean;
  message: string;
  transaction_type: string;
  state: TRANSACTION_STATES;
}

export interface TransactionDetails {
  uuid: string;
  id: string;
  transaction_platform: string;
  payment_platform: string;
  total_price: number;
  total_charge: number;
  total_redeemed: number;
  total_discount?: number;
  total_tax: number;
  currency_type: string;
  type: TRANSACTION_TYPES;
  details: TransactionBookingDetails;
  content: TransactionContent;
  payment_method?: PaymentMethod;
  payment_method_type?: SupportedPaymentMethods;
}

export interface TransactionDisplayDetails extends TransactionDetails {
  total_summary: OrderTotal;
  items: Array<Item>;
}

export enum BookingStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  DENIED_UNAVAILABLE = 'Denied_Unavailable',
  DENIED_AVAILABLE = 'Denied_Available',
  CANCELED = 'Canceled',
}

export enum OwnerType {
  BUILDING = 'building',
  BUILDING_COMPANY = 'building_company',
  COMPANY = 'company',
}

export interface TransactionBookingDetails {
  discount_applied: number;
  name: string;
  id: string;
  uuid: string;
  resource: {
    id: string;
    name: string;
    features?: Array<ResourceFeature>;
    amenities_description?: string;
  };
  resource_id: string;
  owner: {
    owner_type: OwnerType;
    owner_uuid: string;
  };
  user_id: number;
  start_at: string;
  end_at: string;
  created_at: string;
  status: BookingStatus;
  canceled_at?: string;
  cancellation_description: string;
  cancellation_window_end_minutes: number;
  user_info?: Array<FormResponse>;
  service?: TransactionDetailsService;
  membership_id?: string;
  user_membership_id?: string;
  service_id?: string;
  description?: string;
  price?: number;
  discount_summary?: {
    promo_code_name?: string | null;
    promo_code?: string;
    promo_code_amount?: number;
    calculated_discount?: number;
    promo_code_type: DiscountTypes;
  };
  service_provider?: {
    service_provider_address?: string | null;
    service_provider_logo_url?: string | null;
    service_provider_name?: string | null;
  };
}

export interface TransactionDetailsService {
  duration: number;
  name: string;
  price: number;
  resource_name?: string;
  service_provider?: {
    service_provider_address?: string | null;
    service_provider_logo_url?: string | null;
    service_provider_name?: string | null;
  };
  type: TransactionDetailsServiceTypes;
}

export enum TransactionDetailsServiceTypes {
  APPOINTMENT = 'APPOINTMENT',
  CLASS = 'CLASS',
}

export interface ResourceBookingDetails {
  uuid: string;
  start_at: Date;
  end_at: Date;
  status: string;
  resource: {
    name: string;
    description: string;
    image_url: string;
  };
}

export interface ServiceBookingDetails {
  uuid: string;
  start_at: Date;
  end_at: Date;
  status: string;
  service: ServiceDetails;
}

export interface ServiceDetails {
  name: string;
  duration: number;
  price: number;
  type: string;
  logo_url: string;
  provider_name: string;
}

export type TransactionContent = ServiceBookingDetails | ResourceBookingDetails;

export interface GetUserTransactionPayload {
  itemType: ITEM_TYPE;
  itemId: string;
}

export enum ITEM_TYPE {
  EVENT_POSTS = 'event_posts',
  RESOURCE_BOOKING = 'resources_booking',
  SERVICES_BOOKING = 'services_booking',
  ORDERING = 'ordering',
  MEMBERSHIP = 'membership',
}

export interface PaymentMethod {
  type?: SupportedPaymentMethods;
  card_type?: string;
  last_four?: string;
  exp_month?: number;
  exp_year?: number;
  remaining_points?: number;
}

export interface ResourceFeature {
  translations?: Record<string, ResourceFeatureTranslationsData>;
  name?: string | null;
  description?: string | null;
  default_type?: DefaultTypeEnum;
  icon?: string | null;
  cta_type?: CTATypeEnum;
  cta_value?: string | null;
  active?: boolean;
  created_at?: Date | null;
  deleted_at?: Date | null;
  updated_at?: Date | null;
  description_uuid?: string | null;
  id?: number | null;
  name_uuid?: string | null;
  resource_id?: number | null;
  uuid?: string | null;
  to_delete?: boolean | null;
}

export enum CTATypeEnum {
  URL = 'URL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum DefaultTypeEnum {
  TV = 'TV',
  WIFI = 'WIFI',
  PHONE = 'PHONE',
}

interface ResourceFeatureTranslationsData {
  name: string;
  description: string;
}
