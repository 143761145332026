import { ButtonContainer, CancelButton } from './styles';

import { ACTION_STATUSES } from 'shared/consts';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface CancelButtonBlockProps {
  onCancel: VoidFunction;
  status: ACTION_STATUSES;
  expired?: boolean;
  isReceiptCTA?: boolean;
}

export const CancelButtonBlock = ({ onCancel, status, expired, isReceiptCTA }: CancelButtonBlockProps) => {
  const intl = useIntl();
  const { showMiniappPaymentsNavigation: isSwipeModalContent } = useFlags();
  const resolveButtonText = () => {
    if (isReceiptCTA) return 'cancel_booking';

    switch (status) {
      case ACTION_STATUSES.FULFILLED:
        return 'cancelled';
      case ACTION_STATUSES.PENDING:
        return 'cancel_booking';
      case ACTION_STATUSES.REJECTED:
        return 'cancellation_denied';
      default:
        return 'yes_cancel';
    }
  };

  return (
    <ButtonContainer>
      <CancelButton
        isSwipeModalContent={isSwipeModalContent}
        data-testid="cancel-button"
        text={intl.formatMessage({ id: resolveButtonText() })}
        variant={isSwipeModalContent ? 'secondary' : 'primary'}
        onClick={onCancel}
        loading={status === ACTION_STATUSES.PENDING}
        disabled={expired || !!status}
      />
    </ButtonContainer>
  );
};
