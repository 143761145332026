import { Order } from 'store/cart/types';
import { ORDER_TYPES } from 'shared/consts';
import { formatCurrency } from './formatCurrency';
import { formatSummaryValues } from './formatSummaryValues';

export const getPrice = (isFreeCart: boolean, cart: Order, freeText: string, locale: string): string => {
  const { currencyType, total, subtotal } = formatSummaryValues(cart?.total_summary);
  if (!total && subtotal && cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP)
    return formatCurrency(subtotal, currencyType, false, locale);

  return !isFreeCart && currencyType
    ? formatCurrency(total, currencyType, false, locale)
    : cart.type !== ORDER_TYPES.RESOURCE_BOOKING && freeText;
};
