import { EditPaymentContainerSubtitle, StyledRadioForm } from '../styles';
import React, { ChangeEvent } from 'react';

import { INVOICE } from 'shared/consts/payment-method-types';
import { RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { SavedPaymentMethod } from 'store/payment/types';
import { useIntl } from 'react-intl';

export interface RadioFormContentProps {
  title: string;
  paymentMethods: Array<SavedPaymentMethod>;
  selectedPaymentMethodId: string;
  onRemovePaymentMethod: VoidFunction;
  options: RadioOption[];
  onPress: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

export const RadioFormContent = ({
  title,
  paymentMethods,
  selectedPaymentMethodId,
  onRemovePaymentMethod,
  options,
  onPress,
  disabled,
}: RadioFormContentProps): JSX.Element | null => {
  const intl = useIntl();

  if (paymentMethods?.length === 1 && paymentMethods?.[0].payment_method_type === INVOICE) {
    return (
      <EditPaymentContainerSubtitle data-testid="edit-payment-subtitle">
        {intl.formatMessage({ id: 'invoice_text' })}
      </EditPaymentContainerSubtitle>
    );
  }
  if (paymentMethods?.length) {
    return (
      <StyledRadioForm
        data-testid="saved-payment-buttons"
        options={options}
        title={title}
        onPress={onPress}
        selected={selectedPaymentMethodId}
        canRemove
        onRemove={onRemovePaymentMethod}
        errorMessageLabelClassName="error-message-label"
        disabled={disabled}
      />
    );
  }
  return null;
};
