import { ACTION_STATUSES, PAYMENT_MINIAPP_POST_MESSAGES, TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { BUTTON_VARIANT, useBooleanState } from '@hqo/react-components-library';
import { CheckoutContainer, StyledBottomMobileModal } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { resetComplete3DSCart } from '../../store/cart/actions';
import { deletePaymentMethod, getPaymentMethods } from 'store/payment/actions';
import {
  select3DSecure,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
  selectCartDiscountSummary,
} from 'store/cart/selectors';
import {
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
} from 'store/payment/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { CheckoutDesktop } from './desktop/CheckoutDesktop';
import { CheckoutDesktopModalWrapper } from 'components/modals/checkoutModalWrapper';
import { ContentModal } from './components/contentModal';
import { ThreeDSModal } from 'components/modals/3dsModal/component';
import { replace } from 'connected-react-router';
import { submitCart } from 'store/cart/actions';
import { useCart } from 'hooks/use-cart.hook';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { useLocation } from 'react-router-dom';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { CREDIT_PAYMENT_TYPE_ID } from 'shared/consts/payment-method-types';
import { track } from '@hqo/web-tracking';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { selectBuildingLocale } from 'store/building/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CheckoutMobileContent } from './mobile/checkout-mobile-content';
import { getTransaction } from 'store/transactions/actions';
import { selectGetTransactionStatus, selectTransaction } from 'store/transactions/selectors';
import { ITEM_TYPE } from 'store/transactions/types';
import qs from 'qs';
import { VERTICAL_API_CODES } from 'store/cart/types';

/**
 * Replace paymentId and remove transaction_token and error from location search string in case user has already submitted
 * and Fallback 3DS2 => 3DS1 authentication failed
 */
export const massageLocationSearchWithCurrentPayment = (currentPaymentId: string, locationSearch?: string) => {
  if (locationSearch?.length) {
    const searchParams = locationSearch.split('&');
    const paymentIdIndex = searchParams.findIndex(word => word.startsWith('paymentId'));
    if (paymentIdIndex !== -1) {
      searchParams.splice(paymentIdIndex, 1, `paymentId=${currentPaymentId}`);
    } else {
      searchParams.push(`paymentId=${currentPaymentId}`);
    }
    const transactionTokenIndex = searchParams.findIndex(word => word.startsWith('transaction_token'));
    if (transactionTokenIndex !== -1) {
      searchParams.splice(transactionTokenIndex, 1);
    }
    const errorIndex = searchParams.findIndex(word => word.startsWith('error'));
    if (errorIndex !== -1) {
      searchParams.splice(errorIndex, 1);
    }
    return searchParams.join('&');
  }
  return '';
};

export const CheckoutPage = (): JSX.Element => {
  const intl = useIntl();
  const location = useLocation();
  const [submittedPaymentMethodId, setSubmittedPaymentMethodId] = useState<number>(undefined);

  const { cartId, expandAddCard, error, paymentId, locationId } = useSearchParams();
  const [paymentError, setPaymentError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const { forPayment, ...restQueryParams } = useSearchParams();
  const { isCartFulfilled, cart } = useCart(cartId as string);
  const { paymentMethods } = usePaymentMethods(cartId as string);
  const [show3DS, setShow3DS] = useState<boolean>(false);
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const { is3DSecure } = useSelector(select3DSecure);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);

  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);

  const discountSummary = useSelector(selectCartDiscountSummary);
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>(null);
  const { value: showConfirmCloseModal, toggle: toggleConfirmCloseModal } = useBooleanState(false);
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const { value: showAddCardForm, toggle: toggleAddCardForm } = useBooleanState(!!expandAddCard);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && forPayment === 'true';
  const transaction = useSelector(selectTransaction);
  const transactionStatus = useSelector(selectGetTransactionStatus);
  const cartItemsIds = formatCartItemsIds(cart);

  const findCurrentPaymentMethod = useCallback(
    () => paymentMethods?.find(paymentMethod => paymentMethod.default),
    [getPaymentMethodStatus, savePaymentMethodStatus, paymentMethods],
  );

  useEffect(() => {
    if (paymentId) {
      setSubmittedPaymentMethodId(Number(paymentId));
    }
  }, [paymentId]);

  useEffect(() => {
    setDefaultPaymentMethod(findCurrentPaymentMethod()?.id.toString());
  }, [paymentMethods, findCurrentPaymentMethod]);

  useEffect(() => {
    if (error === 'true') {
      setPaymentError(true);
    }
  }, [error]);

  const onStayClick = useCallback(() => {
    toggleReverseAnimation();
    setTimeout(() => {
      toggleConfirmCloseModal();
      toggleReverseAnimation();
    }, 300);
  }, [toggleConfirmCloseModal]);

  const onLeaveClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE}`, '*');
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  const onBackClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_GO_BACK}`, '*');
  }, [toggleConfirmCloseModal]);

  useEffect(() => {
    if (!isMobileDevice || expandAddCard) {
      window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_DESKTOP}`, '*');
    }
    if (isMobileDevice) {
      window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_MOBILE}`, '*');
    }
  }, [isMobileDevice]);

  const onPayClick = useCallback(() => {
    track(
      TRACK_EVENTS.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: findCurrentPaymentMethod().payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
    setPaymentError(false);
    setSubmittedPaymentMethodId(findCurrentPaymentMethod().id);
    dispatch(resetComplete3DSCart());

    const paymentInfo = {
      cart_id: cartId as string,
      payment_method_id: findCurrentPaymentMethod().id.toString(),
      locationId: locationId as string,
    };

    const updatedLocationSearch = massageLocationSearchWithCurrentPayment(
      paymentInfo.payment_method_id,
      location.search,
    );

    dispatch(
      submitCart.request(
        submit3DSecure && findCurrentPaymentMethod()?.id.toString() !== CREDIT_PAYMENT_TYPE_ID
          ? {
              ...paymentInfo,
              browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
              attempt_3dsecure: true,
              three_ds_version: '2',
              callback_url: `${window.location.origin}/redirect${updatedLocationSearch}`,
              redirect_url: `${window.location.origin}/redirect${updatedLocationSearch}`,
            }
          : paymentInfo,
      ),
    );
  }, [
    findCurrentPaymentMethod,
    total,
    currencyType,
    buildingLocale,
    discountSummary,
    cart,
    dispatch,
    cartId,
    cartItemsIds,
    locationId,
    location.search,
    submit3DSecure,
  ]);

  const onAddPaymentMethodClick = useCallback(() => {
    toggleAddCardForm();
  }, [toggleAddCardForm]);

  const onCloseClick = useCallback(() => {
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  useEffect(() => {
    const hasSuccessfullyPurchasedWithCreditCard =
      (!submit3DSecure || findCurrentPaymentMethod()?.id.toString() === CREDIT_PAYMENT_TYPE_ID) &&
      submitCartStatus === ACTION_STATUSES.FULFILLED;

    if (hasSuccessfullyPurchasedWithCreditCard && showMiniappPaymentsNavigation) {
      dispatch(getTransaction.request(cart.transaction_uuid));
      window.parent.postMessage(
        {
          message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_FULL_CHECKOUT_SUBMIT_CLICK}`,
        },
        '*',
      );
    }

    const shouldNavigateToReceipt =
      !(showMiniappPaymentsNavigation && isMobileDevice) ||
      cart?.config?.vertical?.api_code === VERTICAL_API_CODES.SERVICE_BOOKING;

    if (hasSuccessfullyPurchasedWithCreditCard && shouldNavigateToReceipt) {
      dispatch(replace(`/receipt${location.search}`));
    }
  }, [
    dispatch,
    is3DSecure,
    submitCartStatus,
    location.search,
    isMobileDevice,
    showMiniappPaymentsNavigation,
    cart?.transaction_uuid,
  ]);

  useEffect(() => {
    const shouldNavigateToHistoricalReceipt =
      transactionStatus === ACTION_STATUSES.FULFILLED &&
      showMiniappPaymentsNavigation &&
      isMobileDevice &&
      cart?.config?.vertical?.api_code === VERTICAL_API_CODES.RESOURCE_BOOKING;

    if (
      (!submit3DSecure || findCurrentPaymentMethod()?.id.toString() === CREDIT_PAYMENT_TYPE_ID) &&
      shouldNavigateToHistoricalReceipt
    ) {
      const queryParams = {
        ...restQueryParams,
        itemId: transaction?.details.id,
        itemType: ITEM_TYPE.RESOURCE_BOOKING,
        forPayment,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`/historical-receipt?${queryString}`));
    }
  }, [dispatch, is3DSecure, transactionStatus, transaction?.details?.id, forPayment, showMiniappPaymentsNavigation]);

  useEffect(() => {
    if (showAddCardForm && savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      toggleAddCardForm();
    }
  }, [savePaymentMethodStatus]);

  const onRemovePaymentMethod = useCallback(() => {
    dispatch(
      deletePaymentMethod.request({
        card_id: findCurrentPaymentMethod()?.id.toString(),
        paymentMethods,
      }),
    );
  }, [dispatch, findCurrentPaymentMethod, paymentMethods]);

  useEffect(() => {
    if (deletePaymentMethodsStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request(cartId as string));
    }
  }, [dispatch, cartId, deletePaymentMethodsStatus]);

  const onCancelAddCardClick = useCallback(() => {
    toggleAddCardForm();
  }, [toggleAddCardForm]);

  const buttons = [
    {
      title: intl.formatMessage({ id: 'stay' }),
      handler: onStayClick,
    },
    {
      title: intl.formatMessage({ id: 'leave' }),
      handler: onLeaveClick,
      variant: BUTTON_VARIANT.SECONDARY,
    },
  ];

  useEffect(() => {
    if (isCartFulfilled) {
      track(
        TRACK_EVENTS.FULL_CHECKOUT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          cart_total: total,
          currency_code: currencyType,
          promo_code_applied: !!discountSummary,
          cart_type: cart.type,
          items: cartItemsIds,
          adapter: cart.config?.config?.adapter,
        },
        { sendToPendo: true, sendToHqoTracking: true },
      );
    }
  }, [isCartFulfilled]);

  return (
    <CheckoutContainer>
      {isMobileDevice
        ? isCartFulfilled &&
          !show3DS && (
            <CheckoutMobileContent
              isSwipeModalContent={isSwipeModalContent}
              onBackClick={onBackClick}
              cart={cart}
              onRemovePaymentMethod={onRemovePaymentMethod}
              defaultPaymentMethod={defaultPaymentMethod}
              submittedPaymentMethodId={submittedPaymentMethodId}
              paymentError={paymentError}
              paymentMethods={paymentMethods}
              onAddPaymentMethodClick={onAddPaymentMethodClick}
              onPayClick={onPayClick}
              submitCartStatus={submitCartStatus}
              isCartFulfilled={isCartFulfilled}
              showAddCardForm={showAddCardForm}
              onCancelAddCardClick={onCancelAddCardClick}
              cartId={cartId as string}
              onCloseClick={onCloseClick}
            />
          )
        : isCartFulfilled &&
          !show3DS && (
            <CheckoutDesktopModalWrapper
              visible
              withBackButton
              onPressBack={onBackClick}
              content={
                <CheckoutDesktop
                  cart={cart}
                  onRemovePaymentMethod={onRemovePaymentMethod}
                  currentPaymentMethodId={defaultPaymentMethod}
                  submittedPaymentMethodId={submittedPaymentMethodId}
                  currentPaymentError={paymentError}
                  paymentMethods={paymentMethods}
                  onAddPaymentMethodClick={onAddPaymentMethodClick}
                  onPayClick={onPayClick}
                  submitCartStatus={submitCartStatus}
                  showAddCardForm={showAddCardForm}
                  onCancelAddCardClick={onCancelAddCardClick}
                  cartId={cartId as string}
                />
              }
              onClose={onCloseClick}
            />
          )}
      {is3DSecure && (
        <ThreeDSModal
          setPaymentError={setPaymentError}
          setIs3DSVisible={setShow3DS}
          transactionToken={cart?.transaction?.token}
          spreedlyToken={cart?.ui_enablements?.spreedly_env_token}
        />
      )}
      <StyledBottomMobileModal
        reverseAnimation={reverseAnimation}
        isVisible={showConfirmCloseModal}
        buttons={buttons}
        content={ContentModal()}
        showCloseIcon={false}
      />
    </CheckoutContainer>
  );
};
