import { ACTION_STATUSES, TRANSACTION_TYPES } from 'shared/consts';
import { HistoricalReceiptOverview, HistoricalReceiptSummary } from 'pages/historicalReceipt/components';
import { CancellationExpiredText, HistoricalReceiptContainer, StyledDoublePaneModal } from '../styles';
import React from 'react';
import { DefaultLoader } from 'components/default-loader';
import { ITEM_TYPE, TransactionDetails } from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from 'components/cancel-button';

interface HistoricalReceiptContentProps {
  isShown: boolean;
  itemId: string;
  itemType: ITEM_TYPE;
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
}

export const HistoricalReceiptContent = ({
  isShown,
  itemId,
  itemType,
  isCancellationExpiredState,
  onCancelBookingClick,
  onCloseClick,
  patchTransactionStatus,
  transaction,
}: HistoricalReceiptContentProps) => {
  const intl = useIntl();

  return (
    <>
      {!isShown ? (
        <DefaultLoader />
      ) : (
        <HistoricalReceiptContainer data-testid="historical-receipt-page">
          {itemId && itemType && transaction && (
            <StyledDoublePaneModal
              contentStatus={ACTION_STATUSES.FULFILLED}
              isVisible
              onClose={onCloseClick}
              LeftPaneContent={<HistoricalReceiptOverview transaction={transaction} />}
              RightPaneContent={<HistoricalReceiptSummary transaction={transaction} />}
              Button={
                transaction.type === TRANSACTION_TYPES.RESOURCES_BOOKING && (
                  <CancelButtonBlock
                    onCancel={onCancelBookingClick}
                    status={patchTransactionStatus}
                    expired={isCancellationExpiredState}
                    isReceiptCTA
                  />
                )
              }
              ButtonLabel={
                transaction.details?.cancellation_window_end_minutes >= 0 &&
                transaction.details?.cancellation_window_end_minutes !== null && (
                  <CancellationExpiredText>
                    {intl.formatMessage(
                      { id: 'notice_required' },
                      { hours: transaction.details?.cancellation_window_end_minutes / 60 },
                    )}
                  </CancellationExpiredText>
                )
              }
            />
          )}
        </HistoricalReceiptContainer>
      )}
    </>
  );
};
