import {
  CustomIconCancel,
  CustomIconConfirmed,
  OverviewContainer,
  Subtitle,
  TitleContainer,
  StyledStatusIconTitle,
} from './styles';
import { formatDate, getBookingStartFromTransaction } from 'utils/formatDate';

import { ACTION_STATUSES } from 'shared/consts';
import { BoldText } from 'hocs/bold-text';
import { IconContentProps } from '@hqo/react-components-library';
import React from 'react';
import { TRANSACTION_TYPES } from 'shared/consts/transaction-types';
import { TransactionDetails } from 'store/transactions/types';
import { getReceiptIcon } from 'utils/getReceiptIcon';
import { getReceiptSubtitle } from 'utils/getReceiptSubtitle';
import { getReceiptTitle } from 'utils/getReceiptTitle';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectPatchTransactionsStatus } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';

interface Props {
  transaction: TransactionDetails;
  isSwipeModalContent?: boolean;
}

export const HistoricalReceiptOverview = ({ transaction, isSwipeModalContent = false }: Props): JSX.Element => {
  const intl = useIntl();
  const locale = useLocale();
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);

  const { eventWeekDay, eventDateMonthLong, eventDateDay } = formatDate(
    getBookingStartFromTransaction(transaction),
    locale as string,
    buildingTimeZone,
  );

  const getOverviewTitle = () => {
    if (transaction.type === TRANSACTION_TYPES.RESOURCES_BOOKING) {
      const booking_status = transaction.details.status;
      return {
        title: getReceiptTitle(booking_status as string, patchTransactionStatus),
        subtitle: intl.formatMessage(
          { id: getReceiptSubtitle(booking_status as string, patchTransactionStatus) },
          {
            resourceName: <BoldText>{transaction.details.name}</BoldText>,
            bookingTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
          },
        ),
      };
    }
    return {
      title: 'default_receipt',
      subtitle: null,
    };
  };

  let icon;
  if (transaction.type === TRANSACTION_TYPES.RESOURCES_BOOKING) {
    icon = getReceiptIcon(transaction.details.status as string, patchTransactionStatus);
  } else {
    icon = patchTransactionStatus === ACTION_STATUSES.FULFILLED ? <CustomIconCancel /> : <CustomIconConfirmed />;
  }

  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="resource-booking-receipt-overview">
      <TitleContainer>
        <StyledStatusIconTitle
          title={intl.formatMessage({ id: getOverviewTitle().title })}
          icon={icon as unknown as React.FC<IconContentProps>}
          hideIcon={transaction.details.status === 'Pending'}
        />
      </TitleContainer>
      <Subtitle>{getOverviewTitle().subtitle}</Subtitle>
    </OverviewContainer>
  );
};
