import { CustomIconCancel, CustomIconConfirmed } from 'pages/receipt/components/styles';

import { ACTION_STATUSES } from 'shared/consts';
import { BOOKING_STATUSES } from 'shared/consts/booking-statuses';
import React from 'react';

export const getReceiptIcon = (bookingStatus: string, patchTransactionStatus: ACTION_STATUSES) => {
  if (patchTransactionStatus === ACTION_STATUSES.FULFILLED) {
    return <CustomIconCancel />;
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return <CustomIconConfirmed />;
  }
  return null;
};
