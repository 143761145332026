import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { ITEM_TYPE, TransactionDetails } from 'store/transactions/types';
import { useCallback, useEffect, useState } from 'react';
import {
  selectGetTransactionStatus,
  selectPatchTransactionsStatus,
  selectTransaction,
} from 'store/transactions/selectors';
import { useTimeout } from 'hooks/use-timeout.hook';
import {
  ACTION_STATUSES,
  ERROR_PATH,
  PAYMENT_MINIAPP_POST_MESSAGES,
  TRACK_EVENTS,
  TRACK_EVENT_TYPES,
} from 'shared/consts';
import { push, replace } from 'connected-react-router';
import { getUserTransaction } from 'store/transactions/actions';
import { isCancellationExpired } from 'utils/isCancellationExpired';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { track } from '@hqo/web-tracking';
import { formatTransactionItemsIds } from 'utils/formatTrackEventProps';

interface UseHistoricalReceiptReturnValues {
  isShown: boolean;
  itemId: string;
  itemType: ITEM_TYPE;
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
export const useHistoricalReceipt = (): UseHistoricalReceiptReturnValues => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { forPayment } = useSearchParams();

  const itemId = useSearchParams().itemId as string;
  const itemType = useSearchParams().itemType as ITEM_TYPE;

  const [isShown, setIsShown] = useState(false);
  const [isCancellationExpiredState, setIsCancellationExpiredState] = useState(false);

  const transaction = useSelector(selectTransaction);
  const getTransactionStatus = useSelector(selectGetTransactionStatus);
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice && forPayment === 'true';
  const [isCancelBookingStep, setIsCancelBookingStep] = useState<boolean>(false);

  useTimeout(() => {
    setIsShown(true);
  }, 1000);

  useEffect(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_RECEIPT}`, '*');
  }, []);

  useEffect(() => {
    if (!itemId || !itemType || patchTransactionStatus === ACTION_STATUSES.REJECTED) {
      dispatch(replace(ERROR_PATH));
    } else if (
      itemId &&
      itemType &&
      getTransactionStatus !== ACTION_STATUSES.PENDING &&
      getTransactionStatus !== ACTION_STATUSES.FULFILLED
    ) {
      dispatch(getUserTransaction.request({ itemId, itemType }));
    }
  }, [dispatch, itemId, itemType, isCancelBookingStep, patchTransactionStatus]);

  useEffect(() => {
    if (transaction) {
      setIsCancellationExpiredState(
        isCancellationExpired(
          transaction.details?.start_at as string | null,
          transaction.details?.cancellation_window_end_minutes as number | null,
        ),
      );
    }
  }, [transaction, getTransactionStatus]);

  useEffect(() => {
    if (transaction) {
      track(
        TRACK_EVENTS.HISTORICAL_RECEIPT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          transaction_total: transaction.total_price / 100,
          payment_method: transaction.payment_method.type,
          currency_code: transaction.currency_type,
          promo_code_applied: transaction.total_discount > 0,
          transaction_type: transaction.type,
          items: formatTransactionItemsIds(transaction),
          transaction_history_id: transaction.id,
        },
        { sendToPendo: true, sendToHqoTracking: true },
      );
    }
  }, [transaction]);

  const onCloseClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE}`, '*');
  }, []);

  const onCancelBookingClick = useCallback(() => {
    if (showMiniappPaymentsNavigation && isMobileDevice) {
      setIsCancelBookingStep(true);
    } else {
      dispatch(push(`${pathname.replace('historical-receipt', 'cancel-booking')}${search}`));
    }
  }, [dispatch, showMiniappPaymentsNavigation, isMobileDevice, pathname, search]);

  return {
    isShown,
    itemId,
    itemType,
    isCancellationExpiredState,
    isSwipeModalContent,
    onCancelBookingClick,
    onCloseClick,
    patchTransactionStatus,
    transaction,
    isCancelBookingStep,
    setIsCancelBookingStep,
  };
};

export default useHistoricalReceipt;
