import React from 'react';
import { Container, Question, Answer } from './styles';
import { FormResponse } from 'store/cart/types';

interface UserResponseProps {
  response: FormResponse;
}

export const UserResponse = ({ response }: UserResponseProps): JSX.Element => {
  return (
    <Container>
      <Question>{response.question}</Question>
      <Answer>{response.answer}</Answer>
    </Container>
  );
};
