import { ModalWrapper } from '@hqo/react-components-library';
import { IFRAME_WIDTH } from 'shared/consts';

import styled from 'styled-components';

interface TextContainerProps {
  showAddCardForm: boolean;
}

const modalContainerTopMargin = '17px';

export const StyledModalWrapper = styled(ModalWrapper)`
  width: ${IFRAME_WIDTH};
  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    width: 100%;
  }
`;

export const ModalContainer = styled.div<{ isSwipeModalContent: boolean }>`
  height: ${({ isSwipeModalContent }) =>
    isSwipeModalContent ? `calc(100vh - ${modalContainerTopMargin})` : 'calc(100vh - 139px)'};
  width: auto;
  align-items: flex-start;
  margin: ${({ isSwipeModalContent }) => (isSwipeModalContent ? `${modalContainerTopMargin} 0 0` : '71px 0')};
  position: relative;
  overflow-y: scroll;
  @media screen and (min-width: ${IFRAME_WIDTH}) {
    width: auto;
  }
`;

export const ContentContainer = styled.div<{ isSwipeModalContent: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  ${({ isSwipeModalContent }) => isSwipeModalContent && 'height: 100%'};
`;

export const ButtonContainer = styled.div<{ isSwipeModalContent: boolean }>`
  background: ${({ theme }) => theme.colors.$white};
  position: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 'initial' : 'fixed')};
  right: 0;
  bottom: 0;
  width: ${({ isSwipeModalContent }) => (isSwipeModalContent ? '100%' : 'calc(100% - 30px)')};
  height: 48px;
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? '10px 0' : '10px 15px')};
  ${({ isSwipeModalContent }) => isSwipeModalContent && 'margin-top: auto'}
`;

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ModalContentTitle = styled.div`
  font: 700 24px/28.13px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 20px;
`;

export const ModalContentSubtitle = styled.div`
  font: 400 17px/19.92px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 20px;
`;

export const CartContainer = styled.div`
  padding: 0 16px;
`;

export const TitleContainer = styled.div<{ isSwipeModalContent: boolean }>`
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
`;

export const TextContainer = styled.div<TextContainerProps>`
  margin-top: ${({ showAddCardForm }) => (showAddCardForm ? 0 : '39px')};
  margin-bottom: ${({ showAddCardForm }) => (showAddCardForm ? '25px' : 0)};
  padding: 0 16px;
`;

export const Text = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  text-align: justify;
`;

export const Title = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 15px;
`;
