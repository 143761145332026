import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import {
  IconBlockContainer,
  PaymentMethodContainer,
  PaymentMethodTitle,
  ServiceDetails,
  StyledSummarySubtitle,
  SummaryContainer,
} from './styles';
import { formatDate, getBookingStartFromTransaction } from 'utils/formatDate';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';

import { AdditionalInformation } from 'components/additional-information';
import { Amenities } from 'components/amenities';
import { CancellationInformation } from 'components/cancellation-information';
import { PaymentMethodReceipt } from 'components/payment';
import React from 'react';
import { ReceiptDetails } from 'components/receiptDetails';
import { TRANSACTION_TYPES } from 'shared/consts';
import { TransactionDetails } from 'store/transactions/types';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSavedPaymentMethodLabelFromTransaction } from 'utils/formatSavedPaymentMethodLabelFromTransaction';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';

interface Props {
  transaction: TransactionDetails;
  isSwipeModalContent?: boolean;
}

export const HistoricalReceiptSummary = ({ transaction, isSwipeModalContent = false }: Props) => {
  const intl = useIntl();
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const { eventWeekDay, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    getBookingStartFromTransaction(transaction),
    locale as string,
    buildingTimeZone,
  );
  const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabelFromTransaction(
    transaction?.payment_method,
  );
  const isMobileDevice = useIsSmallViewportWidth();

  return (
    <SummaryContainer data-testid="historical-receipt-summary">
      {transaction?.type !== TRANSACTION_TYPES.MEMBERSHIP && (
        <ServiceDetails isSwipeModalContent={isSwipeModalContent}>
          <StyledSummarySubtitle>
            {intl.formatMessage({
              id: getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).title,
            })}
          </StyledSummarySubtitle>
          <IconBlockContainer>
            <IconBlock
              icon="calendar-alt"
              iconType="fal"
              title={`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
              subtitle={
                getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).eventBlockSubtitle
              }
            />
          </IconBlockContainer>
          {transaction?.type === TRANSACTION_TYPES.RESOURCES_BOOKING && transaction?.details?.user_info?.length ? (
            <AdditionalInformation formResponses={transaction?.details.user_info} />
          ) : null}
          {transaction?.details?.cancellation_description && (
            <CancellationInformation
              cancellationRule={transaction?.details?.cancellation_description.replace(/\n/g, '<br/>')}
            />
          )}
          {!!transaction?.details?.resource?.features?.length && (
            <Amenities
              amenitiesDescription={transaction?.details?.resource?.amenities_description}
              amenities={transaction?.details?.resource?.features}
              isMobileDevice={isMobileDevice}
            />
          )}
        </ServiceDetails>
      )}
      {!!transaction?.total_price && <ReceiptDetails transaction={transaction} isCheckoutView={false} />}
      {!!transaction?.total_price && (
        <PaymentMethodContainer>
          <PaymentMethodTitle>{intl.formatMessage({ id: 'payment' })}</PaymentMethodTitle>
          <PaymentMethodReceipt
            title={intl.formatMessage(
              {
                id: 'saved_payment_method_option',
              },
              {
                cardBrand: capitalizedBrand,
                cardLastFour: transaction?.payment_method?.last_four,
                cardExpMonth: formattedExpMonth,
                cardExpYear: formattedExpYear,
              },
            )}
            paymentMethod={transaction?.payment_method.type?.toUpperCase()}
            creditsAmount={formatCurrency(
              (transaction?.payment_method?.remaining_points || 0) / 100,
              transaction?.currency_type,
              false,
              buildingLocale,
            )}
            total={transaction.total_price}
            amounts={{ totalCharge: transaction.total_charge, totalDiscount: transaction.total_discount }}
          />
        </PaymentMethodContainer>
      )}
    </SummaryContainer>
  );
};
