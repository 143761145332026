/* eslint-disable max-lines-per-function */
import { goBack, replace } from 'connected-react-router';
import { useLocale } from 'hooks/use-locale.hook';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, PATCH_STATUSES, PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { patchTransaction } from 'store/transactions/actions';
import { selectPatchTransactionsStatus, selectTransaction } from 'store/transactions/selectors';
import { formatDate, getBookingStart, getBookingStartFromTransaction } from 'utils/formatDate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { useLocation } from 'react-router-dom';

export interface UseCancelBookingPageProps {
  onBack: VoidFunction;
  onCancelButtonClick: VoidFunction;
  formattedDate: { [arg: string]: string };
  vendorName: string;
  showMiniappPaymentsNavigation: boolean;
  isMobileDevice: boolean;
  patchTransactionsStatus: ACTION_STATUSES;
}

export const useCancelBookingPage = (
  setIsCancelBookingStep?: React.Dispatch<React.SetStateAction<boolean>>,
): UseCancelBookingPageProps => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const cart = useSelector(selectCart);
  const transaction = useSelector(selectTransaction);
  const formattedDate = formatDate(
    cart ? getBookingStart(cart) : getBookingStartFromTransaction(transaction),
    locale as string,
    buildingTimeZone,
  );
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const { pathname, search } = useLocation();
  const vendorName = cart ? cart?.items[0].display_info.title : transaction?.details.name;

  const onMessage = (event: MessageEvent) => {
    if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_GO_BACK) {
      if (showMiniappPaymentsNavigation && isMobileDevice) {
        setIsCancelBookingStep(false);
      } else {
        dispatch(replace(`${pathname.replace('cancel-booking', 'historical-receipt')}${search}`));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CANCEL_BOOKING_PAGE}`, '*');
  }, []);

  const onCancelButtonClick = useCallback(() => {
    dispatch(
      patchTransaction.request({
        transactionId: cart ? cart?.transaction_id?.toString() : transaction.id.toString(),
        status: PATCH_STATUSES.CANCELLED,
      }),
    );
    if (showMiniappPaymentsNavigation && isMobileDevice) {
      setIsCancelBookingStep(false);
      window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE_CANCEL_BOOKING_PAGE}`, '*');
    } else {
      dispatch(goBack());
    }
  }, [transaction, dispatch, cart]);

  const onBack = useCallback(() => {
    if (showMiniappPaymentsNavigation && isMobileDevice) {
      setIsCancelBookingStep(false);
    }
    dispatch(goBack());
  }, [dispatch, showMiniappPaymentsNavigation, isMobileDevice]);

  return {
    onBack,
    onCancelButtonClick,
    formattedDate,
    vendorName,
    showMiniappPaymentsNavigation,
    isMobileDevice,
    patchTransactionsStatus,
  };
};
