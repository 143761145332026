import { Cancel, Confirmed } from '@hqo/react-components-library';

import { IFRAME_WIDTH } from 'shared/consts';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { StatusIconTitle } from '@hqo/react-components-library/dist/molecules/statusTitle';
import styled from 'styled-components';

export const StyledTitleBlock = styled(TitleBlock)`
  .title {
    line-height: 32px;
  }
`;

export const OverviewContainer = styled.div<{ isSwipeModalContent: boolean }>`
  display: grid;
  grid-gap: 18px;
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
  margin: ${({ isSwipeModalContent }) => isSwipeModalContent && '17px 0 0'};
`;

export const Subtitle = styled.div`
  font: 400 16px/19.2px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TitleContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ModalContentTitle = styled.div`
  font: 700 24px/28.13px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 20px;
`;

export const PaymentMethodContainer = styled.div`
  margin: 0 16px;
  padding-top: 16px;
  ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.sharedMediumGrey};`}
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 18px;
  box-sizing: border-box;
  padding-bottom: 140px;
`;

export const PaymentMethodTitle = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CustomIconConfirmed = styled(Confirmed)`
  color: #04623e;
`;

export const CustomIconCancel = styled(Cancel)`
  color: ${({ theme }) => theme.colors.admin.pink};
`;

export const ServiceDetails = styled.div<{ isSwipeModalContent: boolean }>`
  margin: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};

  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.sharedMediumGrey};`}
    padding-top: 22px;
  }
`;

export const IconBlockContainer = styled.div`
  margin: 32px 0;
  display: grid;
  grid-gap: 23px;

  .icon-block-title {
    font-weight: bold;
  }
`;

export const StyledSummarySubtitle = styled.div`
  margin-bottom: 20px;
  font: 700 20px/23px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const BoldText = styled.strong`
  font-weight: 500;
`;

export const TextContainer = styled.div`
  margin-top: 39px;
  padding: 0 16px;
`;

export const CancellationDescription = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  padding: 0 16px;
  margin-bottom: 22px;

  @media only screen and (max-width: ${IFRAME_WIDTH}) {
    margin-bottom: 12px;
  }
`;

export const StyledStatusIconTitle = styled(StatusIconTitle)`
  flex-direction: row-reverse;
  gap: 16px;
`;
