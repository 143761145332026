import React from 'react';
import {
  PaymentMethodContainer,
  Method,
  Icon,
  EditButton,
  InvoiceText,
  PaymentMethodContainerForInvoice,
  PayWithCreditsText,
} from './styles';
import { useIntl } from 'react-intl';
import { PaymentMethodTypes } from 'store/payment/types';

interface Props {
  title: string;
  className?: string;
  withEditButton?: boolean;
  onEditClick?: () => void;
  paymentMethod: PaymentMethodTypes;
}

export const PaymentMethod = ({
  title,
  className,
  withEditButton = false,
  onEditClick,
  paymentMethod,
}: Props): JSX.Element => {
  const intl = useIntl();

  switch (paymentMethod) {
    case PaymentMethodTypes.INVOICE:
      return (
        <PaymentMethodContainerForInvoice className={className}>
          <Icon icon={['far', 'file-invoice-dollar']} size="1x" />
          <InvoiceText>{intl.formatMessage({ id: 'invoice_text' })}</InvoiceText>
        </PaymentMethodContainerForInvoice>
      );
    case PaymentMethodTypes.CREDIT_CARD:
      return (
        <PaymentMethodContainer className={className}>
          <Icon icon={['far', 'credit-card']} size="1x" />
          <Method>{title}</Method>
          {withEditButton && <EditButton onClick={onEditClick}>Edit</EditButton>}
        </PaymentMethodContainer>
      );
    case PaymentMethodTypes.CREDIT_POINTS:
      return (
        <PaymentMethodContainer className={className}>
          <Icon icon={['far', 'stars']} size="1x" />
          <PayWithCreditsText>{title}</PayWithCreditsText>
          {withEditButton && <EditButton onClick={onEditClick}>Edit</EditButton>}
        </PaymentMethodContainer>
      );
    default:
      return null;
  }
};
