import { CartContainer, ItemsContainer, ItemsContainerTitle, PriceContainer } from './styles';

import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';
import { PriceBlock } from 'components/price-block';
import React from 'react';
import { TransactionDetails } from 'store/transactions/types';
import { formatCurrency } from 'utils/formatCurrency';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { TRANSACTION_TYPES } from 'shared/consts';

interface Props {
  transaction: TransactionDetails;
  isCheckoutView: boolean;
}

export const ReceiptDetails = ({ transaction, isCheckoutView }: Props): JSX.Element => {
  const intl = useIntl();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const currencyType = transaction.currency_type;
  const subtotal = transaction.total_price;
  const taxSummary = transaction.total_tax;
  const total = transaction.total_charge;
  const discountApplied = transaction.details.discount_applied;
  const isResourceBookingType = transaction.type === TRANSACTION_TYPES.RESOURCES_BOOKING;
  const discountFormatMessageId = isResourceBookingType ? 'credits_applied' : 'deals';

  return (
    <CartContainer data-testId="receipt-details">
      <ItemsContainer>
        <ItemsContainerTitle isCheckoutView={isCheckoutView}>
          {intl.formatMessage({ id: 'summary' })}
        </ItemsContainerTitle>
        <ItemQuantityBlock
          title={transaction.details.name as string}
          price={formatCurrency((transaction.total_price || 0) / 100, currencyType, false, buildingLocale)}
        />
      </ItemsContainer>
      <PriceContainer isCheckoutView={isCheckoutView}>
        <PriceBlock
          title={intl.formatMessage({ id: 'subtotal' })}
          price={formatCurrency(subtotal / 100, currencyType, false, buildingLocale)}
        />
        <PriceBlock
          title={intl.formatMessage({ id: 'tax' })}
          price={formatCurrency(taxSummary / 100, currencyType, false, buildingLocale)}
        />
        {!!discountApplied && (
          <PriceBlock
            title={intl.formatMessage({ id: discountFormatMessageId })}
            price={formatCurrency(discountApplied / 100, currencyType, true, buildingLocale)}
          />
        )}
        <PriceBlock
          title={intl.formatMessage({ id: 'total' })}
          price={formatCurrency(total / 100, currencyType, false, buildingLocale)}
        />
      </PriceContainer>
    </CartContainer>
  );
};
