import {
  GetUserTransactionPayload,
  PatchTransactionDto,
  RedirectTransactionDetails,
  TransactionDetails,
} from './types';

import { FailureActionPayload } from '../errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getTransaction = createAsyncAction(
  'GET_TRANSACTION_REQUEST',
  'GET_TRANSACTION_SUCCESS',
  'GET_TRANSACTION_FAILURE',
)<string, TransactionDetails, FailureActionPayload>();

export const getRedirectTransaction = createAsyncAction(
  'GET_REDIRECT_TRANSACTION_REQUEST',
  'GET_REDIRECT_TRANSACTION_SUCCESS',
  'GET_REDIRECT_TRANSACTION_FAILURE',
)<string, RedirectTransactionDetails, FailureActionPayload>();

export const patchTransaction = createAsyncAction(
  'PATCH_TRANSACTION_REQUEST',
  'PATCH_TRANSACTION_SUCCESS',
  'PATCH_TRANSACTION_FAILURE',
)<PatchTransactionDto, void, FailureActionPayload>();

export const getUserTransaction = createAsyncAction(
  'GET_USER_TRANSACTION_REQUEST',
  'GET_USER_TRANSACTION_SUCCESS',
  'GET_USER_TRANSACTION_FAILURE',
)<GetUserTransactionPayload, TransactionDetails, FailureActionPayload>();

export const resetPatchTransactionStatus = createAction('CANCEL_TRANSACTION_RESET')<void>();
