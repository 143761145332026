import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TransactionDetails } from 'store/transactions/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  selectGetTransactionStatus,
  selectPatchTransactionsStatus,
  selectTransaction,
} from 'store/transactions/selectors';
import {
  ACTION_STATUSES,
  ERROR_PATH,
  PAYMENT_MINIAPP_POST_MESSAGES,
  TRACK_EVENTS,
  TRACK_EVENT_TYPES,
} from 'shared/consts';
import { push, replace } from 'connected-react-router';
import { getTransaction } from 'store/transactions/actions';
import { isCancellationExpired } from 'utils/isCancellationExpired';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import {
  selectCart,
  selectCartDiscountSummary,
  selectGetCartStatus,
  selectSubmitCartStatus,
} from 'store/cart/selectors';
import { selectGetPaymentMethodsStatus, selectPaymentMethods } from 'store/payment/selectors';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useTimeout } from 'hooks/use-timeout.hook';
import { track } from '@hqo/web-tracking';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { formatCartItemsIds, formatTransactionItemsIds } from 'utils/formatTrackEventProps';

interface UseReceiptReturnValues {
  isShown: boolean;
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const useReceipt = (): UseReceiptReturnValues => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const { forPayment, isMobileDevice: isMobile, transactionUuid } = useSearchParams();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const isMobileDevice = useMemo(
    () => (isMobile ? isMobile === 'true' : isSmallViewportWidth),
    [isMobile, isSmallViewportWidth],
  );
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);
  const discountSummary = useSelector(selectCartDiscountSummary);
  const isCartStatusRejected = useSelector(selectGetCartStatus) === ACTION_STATUSES.REJECTED;
  const isSubmitCartRejected = useSelector(selectSubmitCartStatus) === ACTION_STATUSES.REJECTED;
  const isPaymentMethodsStatusRejected = useSelector(selectGetPaymentMethodsStatus) === ACTION_STATUSES.REJECTED;
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethod = paymentMethods?.find(method => method.default);
  const transaction = useSelector(selectTransaction);
  const getTransactionStatus = useSelector(selectGetTransactionStatus);
  const isTransactionStatusRejected = getTransactionStatus === ACTION_STATUSES.REJECTED;
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const { search, pathname } = useLocation();
  const { showMiniappPaymentsNavigation } = useFlags();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice && forPayment === 'true';
  const receiptItemsIds = cart ? formatCartItemsIds(cart) : formatTransactionItemsIds(transaction);

  const [isShown, setIsShown] = useState(false);
  const [isCancellationExpiredState, setIsCancellationExpiredState] = useState(false);

  useTimeout(() => {
    setIsShown(true);
  }, 1000);

  useEffect(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_RECEIPT}`, '*');
  }, []);

  useEffect(() => {
    if (transaction && isShown) {
      track(
        TRACK_EVENTS.RECEIPT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          cart_total: cart ? total : transaction.total_price / 100,
          payment_method: cart ? paymentMethod?.payment_method_type : transaction.payment_method.type,
          currency_code: cart ? currencyType : transaction.currency_type,
          promo_code_applied: cart ? !!discountSummary : transaction.total_discount > 0,
          cart_type: cart?.type || null,
          transaction_type: transaction.type,
          items: receiptItemsIds,
          transaction_history_id: cart ? cart.transaction_id : transaction.id,
        },
        { sendToPendo: true, sendToHqoTracking: true },
      );
    }
  }, [transaction, isShown]);

  useEffect(() => {
    if (
      transactionUuid &&
      getTransactionStatus !== ACTION_STATUSES.PENDING &&
      getTransactionStatus !== ACTION_STATUSES.FULFILLED
    ) {
      dispatch(getTransaction.request(transactionUuid as string));
    } else if (
      (!cart && isCartStatusRejected) ||
      (!paymentMethods && isPaymentMethodsStatusRejected) ||
      isSubmitCartRejected ||
      isTransactionStatusRejected
    ) {
      dispatch(replace(ERROR_PATH));
    } else if (
      cart?.transaction_uuid &&
      getTransactionStatus !== ACTION_STATUSES.PENDING &&
      getTransactionStatus !== ACTION_STATUSES.FULFILLED
    ) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [
    cart,
    paymentMethods,
    dispatch,
    isCartStatusRejected,
    isPaymentMethodsStatusRejected,
    isSubmitCartRejected,
    isTransactionStatusRejected,
    transactionUuid,
  ]);

  useEffect(() => {
    if (transaction) {
      setIsCancellationExpiredState(
        isCancellationExpired(transaction.details?.start_at, transaction.details?.cancellation_window_end_minutes),
      );
    }
  }, [transaction]);

  const onCloseClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE}`, '*');
  }, []);

  const onCancelBookingClick = useCallback(() => {
    dispatch(push(`${pathname.replace('receipt', 'cancel-booking')}?${search}`));
  }, [dispatch]);

  return {
    isShown,
    cart,
    paymentMethods,
    isCancellationExpiredState,
    isSwipeModalContent,
    onCancelBookingClick,
    onCloseClick,
    patchTransactionStatus,
    transaction,
  };
};

export default useReceipt;
