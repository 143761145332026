import { ApiClient } from './api-client';
import { UserState } from 'store/user/types';
import { ConfigState } from 'store/config/types';
import { Building } from 'store/building/types';

interface ApiClientState {
  user: Pick<UserState, 'token'>;
  config: Pick<ConfigState, 'apiUrl' | 'buildingUuid' | 'locale'>;
  building?: {
    building?: Building;
  };
}

export const apiClient = (
  state: ApiClientState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
  buildingUuidOverride?: string | null,
  localeOverride?: string | null,
): ApiClient => {
  const baseUrl = baseUrlOverride ?? state.config.apiUrl;
  const authToken = authTokenOverride ?? state.user.token;
  const buildingUuid = buildingUuidOverride ?? state.config.buildingUuid;
  const locale =
    localeOverride ?? (state.config?.locale || state.building?.building?.locale || navigator?.language || 'en-US');

  return new ApiClient(baseUrl, authToken, buildingUuid, locale);
};
