import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  PromoCodeCartRequestParams,
  ApplyPromoCodeToCartApiResponse,
  Complete3DSCartApiResponse,
  GetCartApiResponse,
  SubmitCartApiResponse,
  SubmitOrderDto,
  RemovePromoCodeFromCartApiResponse,
} from 'store/cart/types';
import { GetTransactionResponse, PatchTransactionDto, GetUserTransactionPayload } from 'store/transactions/types';
import { SavePaymentMethodResponse, SavedPaymentMethod, UpdatePaymentMethodRequest } from 'store/payment/types';

import { AltBuilding } from 'store/alt-buildings/types';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CompleteCartDto } from '../cart/types';
import { CurrentUserResponse } from 'store/user/types';
import { Observable } from 'rxjs';
import { RedirectTransactionResponse } from '../transactions/types';
import { SavePaymentMethodRequest } from '../payment/types';
import { stringify } from 'qs';
import { PaymentsConfigResponse } from 'store/payments-config/types';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private buildingUuid?: string | null,
    private locale?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'Hqo-Building-UUID': this.buildingUuid,
    };
  }

  private get DefaultParams(): Record<string, string> {
    return {
      lang: this.locale,
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<Array<AltBuilding>>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getPaymentMethods(cartId: string): Observable<AjaxResponse<Array<SavedPaymentMethod>>> {
    const params = stringify({ cartId });

    return ajax.get(`${this.baseUrl}/api/payment-methods?${params}`, this.DefaultHeaders);
  }

  savePaymentMethod(
    savePaymentMethodRequest: SavePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.post(`${this.baseUrl}/api/payment-methods`, savePaymentMethodRequest, this.DefaultHeaders);
  }

  getPaymentsConfig(): Observable<AjaxResponse<PaymentsConfigResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/v1/config`, this.DefaultHeaders);
  }

  getCart(cartId: string): Observable<AjaxResponse<GetCartApiResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/v1/carts/${cartId}`, this.DefaultHeaders);
  }

  submitCart(submitCartDto: SubmitOrderDto): Observable<AjaxResponse<SubmitCartApiResponse>> {
    const params = stringify(
      submitCartDto.locationId
        ? { locationId: submitCartDto.locationId, ...this.DefaultParams }
        : { ...this.DefaultParams },
    );

    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts/${submitCartDto.cart_id}?${params}`,
      { ...submitCartDto, cart_id: undefined, locationId: undefined },
      this.DefaultHeaders,
    );
  }

  applyPromoCodeToCart(payload: PromoCodeCartRequestParams): Observable<AjaxResponse<ApplyPromoCodeToCartApiResponse>> {
    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts/${payload.cartId}/promotions/${payload.promoCode}`,
      null,
      this.DefaultHeaders,
    );
  }

  removePromoCodeFromCart(
    payload: PromoCodeCartRequestParams,
  ): Observable<AjaxResponse<RemovePromoCodeFromCartApiResponse>> {
    return ajax.delete(
      `${this.baseUrl}/api/payments/v1/carts/${payload.cartId}/promotions/${payload.promoCode}`,
      this.DefaultHeaders,
    );
  }

  complete3DSCart(payload: CompleteCartDto): Observable<AjaxResponse<Complete3DSCartApiResponse>> {
    return ajax.post(`${this.baseUrl}/api/payments/complete-3ds`, payload, this.DefaultHeaders);
  }

  deletePaymentMethod(cardId: string): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.delete(`${this.baseUrl}/api/payment-methods/${cardId}`, this.DefaultHeaders);
  }

  getTransaction(transactionUuid: string): Observable<AjaxResponse<GetTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/users/current/transactions/${transactionUuid}`, this.DefaultHeaders);
  }

  getRedirectTransaction(transactionUuid: string): Observable<AjaxResponse<RedirectTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/transaction/${transactionUuid}`, this.DefaultHeaders);
  }

  patchTransaction(patchTransactionDto: PatchTransactionDto) {
    return ajax.patch(
      `${this.baseUrl}/api/transactions/${patchTransactionDto.transactionId}`,
      { status: patchTransactionDto.status },
      this.DefaultHeaders,
    );
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  updatePaymentMethod(
    updatePaymentMethodRequest: UpdatePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.patch(
      `${this.baseUrl}/api/payment-methods/${updatePaymentMethodRequest.card_id}`,
      { default: updatePaymentMethodRequest.default },
      this.DefaultHeaders,
    );
  }

  getUserTransaction({
    itemType,
    itemId,
  }: GetUserTransactionPayload): Observable<AjaxResponse<GetTransactionResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/users/current/transactions/items/${itemType}/${itemId}`,
      this.DefaultHeaders,
    );
  }
}
