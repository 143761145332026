import { ModalContainer, StyledModalWrapper } from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TwoColumnContent } from '@hqo/react-components-library/dist/molecules/twoColumnContent';
import { ACTION_STATUSES } from 'shared/consts';
import { Container } from 'hocs/shared-styles';
import React from 'react';

interface RegisterUserProps {
  isVisible?: boolean;
  onClose?: VoidFunction;
  contentStatus: ACTION_STATUSES;
  Button?: React.ReactNode;
  ButtonLabel?: React.ReactNode;
  LeftPaneContent: React.ReactNode;
  RightPaneContent: React.ReactNode;
  className?: string;
  closeButtonText?: string;
}

export const DoublePaneModal = ({
  isVisible,
  onClose,
  contentStatus,
  Button,
  ButtonLabel,
  LeftPaneContent,
  RightPaneContent,
  className,
  closeButtonText,
}: RegisterUserProps) => {
  const buildContent = (): JSX.Element => (
    <ModalContainer className={className} withButton={!!Button}>
      {contentStatus === ACTION_STATUSES.PENDING ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        contentStatus === ACTION_STATUSES.FULFILLED && (
          <TwoColumnContent
            LeftPaneContent={LeftPaneContent}
            isButtonVisible={!!Button}
            isButtonLabelVisible={!!ButtonLabel}
            Button={Button}
            ButtonLabel={ButtonLabel}
            RightPaneContent={RightPaneContent}
          />
        )
      )}
    </ModalContainer>
  );

  return (
    <StyledModalWrapper
      visible={isVisible}
      onClose={onClose}
      withExitButton={!!onClose}
      content={buildContent()}
      closeButtonText={closeButtonText}
    />
  );
};
