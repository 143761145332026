import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { DeviceProvider } from '@hqo/react-components-library/dist/device';

import { App } from 'components/app';
import { BrowserRouter } from 'react-router-dom';
import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import { ConnectedRouter } from 'connected-react-router';
import { HqoThemeProvider } from '@hqo/react-components-library';
import { IntlProvider } from 'components/intl-provider';
import { LaunchDarklyInitializer } from 'components/launch-darkly';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { history } from 'store/router/history';
import store from 'store';

library.add(fab, fal, far, fas);

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <LaunchDarklyInitializer>
      <HqoThemeProvider>
        <DeviceProvider smallDeviceMaxWidth={CHECKOUT_BREAK_POINT}>
          <AppThemeProvider theme={defaultTheme}>
            <BrowserRouter>
              <ConnectedRouter history={history}>
                <IntlProvider>
                  <App />
                </IntlProvider>
              </ConnectedRouter>
            </BrowserRouter>
          </AppThemeProvider>
        </DeviceProvider>
      </HqoThemeProvider>
    </LaunchDarklyInitializer>
  </ReduxProvider>
);
