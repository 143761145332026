import { TRANSACTION_TYPES } from 'shared/consts';
import { Order } from 'store/cart/types';
import { TransactionDetails } from 'store/transactions/types';

export const formatCartItemsIds = (cart: Order): Array<string> => {
  return cart ? cart.items?.map(item => item.id) : [];
};

export const formatTransactionItemsIds = (transaction: TransactionDetails): Array<string> => {
  switch (transaction?.type) {
    case TRANSACTION_TYPES.MEMBERSHIP:
      return [transaction.details?.membership_id];
    case TRANSACTION_TYPES.SERVICES_BOOKING:
      return [transaction.details?.service_id];
    case TRANSACTION_TYPES.RESOURCES_BOOKING:
      return [transaction.details?.resource_id];
    default:
      return [];
  }
};
