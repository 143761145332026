import styled from 'styled-components';
import { ItemContainerTitle } from './cart.interface';
import { DIMENSIONS } from '@hqo/react-components-library';
import { PriceBlock } from 'components/price-block';
import { CHECKOUT_BREAK_POINT } from 'shared/consts';

export const CartContainer = styled.div`
  margin-top: 29px;
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    margin-top: 0px;
  }
`;

export const ItemsContainer = styled.div<{ isSwipeModalContent: boolean }>`
  background-color: ${({ theme, isSwipeModalContent }) =>
    isSwipeModalContent ? theme.colors.universal.transparent : theme.colors.$greyLight};
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? '25px 0 1px 0' : '25px 16px 1px 16px')};
`;

export const ItemsContainerTitle = styled.div<ItemContainerTitle>`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
  ${({ isCheckoutView }) =>
    `${
      !isCheckoutView &&
      `
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    display: none;
  }
  `
    }`};
`;

export const PriceContainer = styled.div<ItemContainerTitle>`
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? '20px 0' : '20px 16px')};
  display: grid;
  gap: 15px;
  ${({ theme, isCheckoutView }) => !isCheckoutView && `border-bottom: 1px solid ${theme.colors.greys.sharedMediumGrey}`}
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const PromoCodeWrapper = styled.div<ItemContainerTitle>`
  margin-top: ${({ isCheckoutView }) => (isCheckoutView ? `0px` : `16px`)};
  margin-bottom: ${({ isCheckoutView }) => (isCheckoutView ? `0px` : `16px`)};
  margin-left: ${({ forSwipePayment }) => (forSwipePayment === 'true' ? `0px` : `16px`)};
  margin-right: ${({ forSwipePayment }) => (forSwipePayment === 'true' ? `0px` : `16px`)};
  @media only screen and (max-width: ${CHECKOUT_BREAK_POINT}px) {
    margin-top: 16px;
  }
`;

export const TotalPriceBlock = styled(PriceBlock)`
  margin-top: 11px;
`;
