/* eslint-disable no-param-reassign */
import {
  ACTION_STATUSES,
  EXPDATE_LENGTH,
  MIN_USERNAME_LENGTH,
  MIN_ZIP_CODE_LENGTH,
  SPREEDLY_CARD_ID,
  SPREEDLY_CARD_REF,
  SPREEDLY_CVV_ID,
  SPREEDLY_CVV_REF,
} from 'shared/consts';
import {
  AddCardContainer,
  CancelButton,
  CardIcon,
  CommonWrapper,
  InputBorderStyles,
  Label,
  RequiredIcon,
  SaveButton,
  SaveCancelButtonContainer,
  SpaceBetween,
  SpreedlyCVVWrapper,
  SpreedlyCardWrapper,
  SpreedlyErrorLabel,
  SpreedlyIFrameContainer,
  StyledCountryLabel,
  StyledFormField,
  StyledSelect,
  AddPaymentMethodError,
  AddPaymentMethodErrorWrapper,
} from './styles';
import {
  Country,
  SelectedItem,
  SpreedlyCreditCardConfig,
  SpreedlyValidationInputProperties,
} from 'store/add-card/types';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  initializeSpreedly,
  setCreditCardConfigSpreedly,
  setValidationSpreedly,
  tokenizeCreditCardSpreedly,
} from 'store/add-card/actions';
import {
  selectSpreedlyCreditCardConfig,
  selectSpreedlyErrors,
  selectSpreedlyInitializeStatus,
  selectSpreedlyReady,
  selectSpreedlyReloadStatus,
  selectSpreedlyTokenizeCreditCardStatus,
  selectSpreedlyValidation,
} from 'store/add-card/selectors';

import { SelectItem, hqoTheme } from '@hqo/react-components-library';
import countries from 'assets/countries.json';
import { createStructuredSelector } from 'reselect';
import { formatExpirationDate } from 'utils/formatExpirationDate';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useIntl } from 'react-intl';
import { selectBrandTheme } from 'store/theme/selectors';
import { getPaymentMethods, resetSavePaymentMethodStatus } from '../../store/payment/actions';
import { selectSavePaymentMethodStatus } from '../../store/payment/selectors';

export interface AddCardProps {
  spreedlyInitializeStatus: string;
  spreedlyReloadStatus: string;
  spreedlyTokenizeCreditCardStatus: ACTION_STATUSES;
  spreedlyCreditCardConfig: SpreedlyCreditCardConfig;
  spreedlyReady: boolean;
  spreedlyValidation: SpreedlyValidationInputProperties;
  spreedlyEnvKey: string;
  onCancel: VoidFunction;
  cartId: string;
}

type SpreedlySetState = React.Dispatch<React.SetStateAction<boolean>> & {
  readonly __brand_setState__: unique symbol;
};

export const isExpDateValid = (formattedExpDate: string): boolean => {
  if (formattedExpDate.length === EXPDATE_LENGTH) {
    const [expMonth, expYear] = formattedExpDate.split('/');
    const expMonthNumber = Number(expMonth);
    const expYearNumber = Number(expYear);
    const currentUserLocaleMonth = Number(
      Intl.DateTimeFormat()
        .formatToParts()
        .find(part => part.type === 'month').value,
    );
    const currentUserLocaleYear =
      Number(
        Intl.DateTimeFormat()
          .formatToParts()
          .find(part => part.type === 'year').value,
      ) % 100;

    return (
      expYearNumber > currentUserLocaleYear ||
      (expYearNumber === currentUserLocaleYear && expMonthNumber >= currentUserLocaleMonth)
    );
  }
  return false;
};

const AddCard = ({
  spreedlyInitializeStatus,
  spreedlyReloadStatus,
  spreedlyTokenizeCreditCardStatus,
  spreedlyCreditCardConfig,
  spreedlyReady,
  spreedlyValidation,
  spreedlyEnvKey,
  onCancel,
  cartId,
}: AddCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentUser] = useCurrentUser();
  const brandTheme = useSelector(selectBrandTheme);

  const useSpreedlyState = (init: boolean) => useState<boolean>(init) as [boolean, SpreedlySetState];
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);

  const expDateErrorMessage = intl.formatMessage({ id: 'min_exp_date_length' });
  const userNameErrorMessage = intl.formatMessage({ id: 'min_one_char' });
  const zipCodeErrorMessage = intl.formatMessage({ id: 'min_five_char' });

  const countryData: Array<Country> = countries;
  const defaultCountry: Country = {
    name: intl.formatMessage({ id: 'country_default' }),
    code: intl.formatMessage({ id: 'country_default_code' }),
  };

  const defaultFirstName = currentUser?.first_name ?? '';
  const defaultLastName = currentUser?.last_name ?? '';

  const spreedlyCardElementRef = useRef(null);
  const spreedlyCvvElementRef = useRef(null);

  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [expDate, setExpDate] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState<Country>({
    ...defaultCountry,
  });

  const [triggerErrorFirstName, setTriggerErrorFirstName] = useState<boolean>(false);
  const [triggerErrorLastName, setTriggerErrorLastName] = useState<boolean>(false);
  const [triggerErrorZipCode, setTriggerErrorZipCode] = useState<boolean>(false);
  const [triggerErrorExpDate, setTriggerErrorExpDate] = useState<boolean>(false);
  const [displayAddPaymentMethodErrorMessage, setDisplayAddPaymentMethodErrorMessage] = useState<boolean>(false);
  const [triggerSave, setTriggerSave] = useState<boolean>(false);
  const [dirtySpreedlyCardField, setDirtySpreedlyCardField] = useState(false);
  const [dirtySpreedlyCvvField, setDirtySpreedlyCvvField] = useState(false);
  const [isSpreedlyCardFocused, setIsSpreedlyCardFocused] = useSpreedlyState(false);
  const [isSpreedlyCvvFocused, setIsSpreedlyCvvFocused] = useSpreedlyState(false);
  const fonts = brandTheme?.font?.body?.font_family
    ? [`${brandTheme?.font?.body?.font_family}`, ...hqoTheme.fonts]
    : hqoTheme.fonts;
  const fontsString = fonts.join(',');
  const spreedlyFontColor = brandTheme?.primary_font_color ?? hqoTheme.colors.fontColor;
  const SpreedlyIFrameStyle = `width:100%;height:100%;font:14px/22px ${fontsString};color:${spreedlyFontColor};`;

  // Event states
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(
      initializeSpreedly.request({
        envKey: spreedlyEnvKey,
        numberEl: SPREEDLY_CARD_ID,
        cvvEl: SPREEDLY_CVV_ID,
      }),
    );
  }, []);

  // Toggle loading state based on async action status of spreedly
  useEffect(() => {
    setIsLoading(
      !spreedlyReady ||
        spreedlyInitializeStatus !== ACTION_STATUSES.FULFILLED ||
        spreedlyReloadStatus !== ACTION_STATUSES.FULFILLED ||
        spreedlyTokenizeCreditCardStatus === ACTION_STATUSES.PENDING ||
        savePaymentMethodStatus === ACTION_STATUSES.PENDING,
    );
  }, [spreedlyInitializeStatus, spreedlyReloadStatus, spreedlyTokenizeCreditCardStatus, savePaymentMethodStatus]);

  useEffect(() => {
    if (!isLoading && triggerSave && savePaymentMethodStatus === ACTION_STATUSES.REJECTED) {
      setDisplayAddPaymentMethodErrorMessage(true);
    }
  }, [isLoading, savePaymentMethodStatus, triggerSave]);

  // Dynamically handle border styling for iframe container borders
  const styleSpreedlyIFrameBorder = useCallback(
    (
      spreedlyElement: React.MutableRefObject<HTMLElement>,
      type: string,
      setIsFocused: SpreedlySetState,
      isValidInput: boolean,
    ) => {
      const spreedlyElementValid = spreedlyElement?.current?.style?.border != null;

      switch (type) {
        case 'input':
        case 'focus':
          if (spreedlyElementValid) {
            spreedlyElement.current.style.border = isValidInput ? InputBorderStyles.focus : InputBorderStyles.error;
            setIsFocused(true);
          }
          break;
        case 'blur':
          if (spreedlyElementValid) {
            spreedlyElement.current.style.border = isValidInput ? '' : InputBorderStyles.error;
            setIsFocused(false);
          }
          break;
        default:
          break;
      }
    },
    [spreedlyValidation.validCvv, spreedlyValidation.validNumber],
  );

  // Spreedly iframe specific lifecycle events
  useEffect(() => {
    if (
      (spreedlyReady && spreedlyInitializeStatus === ACTION_STATUSES.FULFILLED) ||
      spreedlyReloadStatus === ACTION_STATUSES.FULFILLED
    ) {
      window.Spreedly.setStyle(SPREEDLY_CARD_REF, SpreedlyIFrameStyle);
      window.Spreedly.setStyle(SPREEDLY_CVV_REF, SpreedlyIFrameStyle);
      window.Spreedly.setLabel(SPREEDLY_CARD_REF, intl.formatMessage({ id: 'card_number' }));
      window.Spreedly.setLabel(SPREEDLY_CVV_REF, intl.formatMessage({ id: 'cvv' }));
      window.Spreedly.setPlaceholder(SPREEDLY_CARD_REF, intl.formatMessage({ id: 'card_number_placeholder' }));
      window.Spreedly.setPlaceholder(SPREEDLY_CVV_REF, intl.formatMessage({ id: 'cvv_placeholder' }));

      // Dynamically handle border styling for iframe input fields
      window.Spreedly.on(
        'fieldEvent',
        (name: string, eventType: string, _activeElement: string, inputData: SpreedlyValidationInputProperties) => {
          if (eventType === 'input') {
            dispatch(setValidationSpreedly(inputData));
          }
          switch (name) {
            case SPREEDLY_CARD_REF:
              if (!dirtySpreedlyCardField && eventType === 'input') setDirtySpreedlyCardField(true);
              styleSpreedlyIFrameBorder(
                spreedlyCardElementRef,
                eventType,
                setIsSpreedlyCardFocused,
                spreedlyValidation.validNumber,
              );
              break;
            case SPREEDLY_CVV_REF:
              if (!dirtySpreedlyCvvField && eventType === 'input') setDirtySpreedlyCvvField(true);
              styleSpreedlyIFrameBorder(
                spreedlyCvvElementRef,
                eventType,
                setIsSpreedlyCvvFocused,
                spreedlyValidation.validCvv,
              );
              break;
            default:
              // Name will always be either 'number' or 'cvv'. Noop by default.
              break;
          }
        },
      );
    }
  }, [spreedlyReady, spreedlyInitializeStatus, spreedlyReloadStatus, spreedlyValidation]);

  useEffect(() => {
    if (!spreedlyValidation.validNumber && dirtySpreedlyCardField) {
      spreedlyCardElementRef.current.style.border = InputBorderStyles.error;
    } else if (spreedlyValidation.validNumber) {
      spreedlyCardElementRef.current.style.border = isSpreedlyCardFocused
        ? InputBorderStyles.focus
        : InputBorderStyles.default;
    }

    if (!spreedlyValidation.validCvv && dirtySpreedlyCvvField) {
      spreedlyCvvElementRef.current.style.border = InputBorderStyles.error;
    } else if (spreedlyValidation.validCvv) {
      spreedlyCvvElementRef.current.style.border = isSpreedlyCvvFocused
        ? InputBorderStyles.focus
        : InputBorderStyles.default;
    }
  }, [spreedlyValidation.validCvv, spreedlyValidation.validNumber]);

  const setFormatExpDate = useCallback(
    (rawExpDate: string) => {
      const monthIndex = 0;
      const yearIndex = 1;
      const maxYearLength = 4;
      const formattedExpDate = formatExpirationDate(rawExpDate);
      const fields = formattedExpDate.split('/');
      const formattedMonth = fields[monthIndex];
      const formattedYear =
        fields[yearIndex] && fields[yearIndex].length < maxYearLength ? `20${fields[yearIndex]}` : fields[yearIndex];

      setExpDate(formattedExpDate);
      setMonth(formattedMonth);
      setYear(formattedYear);
    },
    [formatExpirationDate, setExpDate, setMonth, setYear],
  );

  const isUserNameValid = useCallback(
    (userName: string): boolean => {
      return userName.length >= MIN_USERNAME_LENGTH;
    },
    [MIN_USERNAME_LENGTH],
  );

  const isZipCodeValid = useCallback(
    (formattedZipCode: string): boolean => {
      return formattedZipCode.length >= MIN_ZIP_CODE_LENGTH;
    },
    [MIN_ZIP_CODE_LENGTH],
  );

  const handleOnChangeExpDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorExpDate(false);
      setFormatExpDate(e.target.value);
    },
    [setTriggerErrorExpDate],
  );

  const handleOnChangeFirstName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorFirstName(false);
      setFirstName(e.target.value);
    },
    [setTriggerErrorFirstName, setFirstName],
  );

  const handleOnChangeLastName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorLastName(false);
      setLastName(e.target.value);
    },
    [setTriggerErrorLastName, setLastName],
  );

  const handleOnChangeZipCode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTriggerErrorZipCode(false);
      setZipCode(e.target.value);
    },
    [setTriggerErrorZipCode, setZipCode],
  );

  const handleOnClickSave = useCallback(() => {
    dispatch(resetSavePaymentMethodStatus());
    setDisplayAddPaymentMethodErrorMessage(false);
    dispatch(
      setCreditCardConfigSpreedly({
        first_name: firstName,
        last_name: lastName,
        month,
        year,
        country: country.name,
        zip: zipCode,
      }),
    );

    dispatch(tokenizeCreditCardSpreedly.request(spreedlyCreditCardConfig));
    setTriggerSave(true);
  }, [dispatch, spreedlyCreditCardConfig, firstName, lastName, month, year, country, zipCode]);

  useEffect(() => {
    if (savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request(cartId));
    }
  }, [dispatch, cartId, savePaymentMethodStatus]);

  // Helpers for styling for Spreedly iframe input container
  const handleSpreedlyCardOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (spreedlyValidation.validNumber) event.currentTarget.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (spreedlyValidation.validCvv) event.currentTarget.style.border = InputBorderStyles.hover;
    },
    [spreedlyValidation.validCvv],
  );

  const handleSpreedlyCardOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (spreedlyValidation.validNumber)
        event.currentTarget.style.border = isSpreedlyCardFocused ? InputBorderStyles.focus : InputBorderStyles.default;
    },
    [isSpreedlyCardFocused, spreedlyValidation.validNumber],
  );

  const handleSpreedlyCvvOnMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (spreedlyValidation.validCvv)
        event.currentTarget.style.border = isSpreedlyCvvFocused ? InputBorderStyles.focus : InputBorderStyles.default;
    },
    [isSpreedlyCvvFocused, spreedlyValidation.validCvv],
  );

  const handleKeyboardEnter = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  }, []);

  const handleFirstNameOnBlur = useCallback(() => {
    setTriggerErrorFirstName(true);
  }, [setTriggerErrorFirstName]);

  const handleLastNameOnBlur = useCallback(() => {
    setTriggerErrorLastName(true);
  }, [setTriggerErrorLastName]);

  const handleZipCodeOnBlur = useCallback(() => {
    setTriggerErrorZipCode(true);
  }, [setTriggerErrorZipCode]);

  const handleExpDateOnBlur = useCallback(() => {
    setTriggerErrorExpDate(true);
  }, [setTriggerErrorExpDate]);

  const handleOnChangeCountry = useCallback(
    (event: ChangeEvent<SelectedItem>) =>
      setCountry(
        countryData.find((countryEntry: Country) => countryEntry.code === event.target.value) || defaultCountry,
      ),
    [setCountry],
  );

  return (
    <AddCardContainer>
      <CommonWrapper>
        <StyledFormField
          autoCapitalize="words"
          data-testid="first-name-field"
          errorText={!isUserNameValid(firstName) && triggerErrorFirstName && userNameErrorMessage}
          fieldType="input"
          label={intl.formatMessage({ id: 'first_name' })}
          onBlur={handleFirstNameOnBlur}
          onChange={handleOnChangeFirstName}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'first_name_placeholder' })}
          value={firstName}
          variant="master"
          required
        />
        <SpaceBetween />
        <StyledFormField
          autoCapitalize="words"
          data-testid="last-name-field"
          errorText={!isUserNameValid(lastName) && triggerErrorLastName && userNameErrorMessage}
          fieldType="input"
          label={intl.formatMessage({ id: 'last_name' })}
          onBlur={handleLastNameOnBlur}
          onChange={handleOnChangeLastName}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'last_name_placeholder' })}
          value={lastName}
          variant="master"
          required
        />
      </CommonWrapper>
      <Label>
        {intl.formatMessage({ id: 'card_number' })}
        <RequiredIcon>*</RequiredIcon>
      </Label>
      <SpreedlyCardWrapper>
        <CardIcon icon={['fal', 'credit-card']} />
        <SpreedlyIFrameContainer
          data-testid="spreedly-card-field"
          ref={spreedlyCardElementRef}
          id={SPREEDLY_CARD_ID}
          onMouseEnter={handleSpreedlyCardOnMouseEnter}
          onMouseLeave={handleSpreedlyCardOnMouseLeave}
        />
      </SpreedlyCardWrapper>
      {!spreedlyValidation.validNumber && dirtySpreedlyCardField && (
        <SpreedlyErrorLabel>{intl.formatMessage({ id: 'invalid_card_number' })}</SpreedlyErrorLabel>
      )}
      <CommonWrapper>
        <SpreedlyCVVWrapper>
          <Label>
            {intl.formatMessage({ id: 'cvv' })}
            <RequiredIcon>*</RequiredIcon>
          </Label>
          <SpreedlyIFrameContainer
            data-testid="spreedly-cvv-field"
            ref={spreedlyCvvElementRef}
            id={SPREEDLY_CVV_ID}
            onMouseEnter={handleSpreedlyCvvOnMouseEnter}
            onMouseLeave={handleSpreedlyCvvOnMouseLeave}
          />
          {!spreedlyValidation.validCvv && dirtySpreedlyCvvField && (
            <SpreedlyErrorLabel>{intl.formatMessage({ id: 'invalid_cvv' })}</SpreedlyErrorLabel>
          )}
        </SpreedlyCVVWrapper>
        <SpaceBetween />
        <StyledFormField
          data-testid="exp-date-field"
          errorText={!isExpDateValid(expDate) && triggerErrorExpDate && expDateErrorMessage}
          fieldType="input"
          inputMode="numeric"
          label={intl.formatMessage({ id: 'exp_date' })}
          onBlur={handleExpDateOnBlur}
          onChange={handleOnChangeExpDate}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'exp_date_placeholder' })}
          value={expDate}
          variant="master"
          required
        />
      </CommonWrapper>
      <CommonWrapper>
        <StyledFormField
          data-testid="zip-code-field"
          errorText={!isZipCodeValid(zipCode) && triggerErrorZipCode && zipCodeErrorMessage}
          fieldType="input"
          label={intl.formatMessage({ id: 'billing_zip_code' })}
          onBlur={handleZipCodeOnBlur}
          onChange={handleOnChangeZipCode}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'zip_code' })}
          value={zipCode}
          variant="master"
          required
        />
        <SpaceBetween />
        <StyledSelect
          data-testid="country-select-field"
          onChange={handleOnChangeCountry}
          selectedValueLabel={country?.name || defaultCountry.name}
          titleComponent={
            <StyledCountryLabel>
              {intl.formatMessage({ id: 'country_or_region' })}
              <RequiredIcon>*</RequiredIcon>
            </StyledCountryLabel>
          }
          value={country?.code || defaultCountry.code}
        >
          {!country && (
            <SelectItem key={defaultCountry.code} renderedValue={defaultCountry.name} value={defaultCountry.code} />
          )}
          {countryData.map((countryEntry: Country) => (
            <SelectItem key={countryEntry.code} renderedValue={countryEntry.name} value={countryEntry.code} />
          ))}
        </StyledSelect>
      </CommonWrapper>
      {displayAddPaymentMethodErrorMessage && (
        <AddPaymentMethodErrorWrapper data-testid="add-payment-method-error-wrapper">
          <AddPaymentMethodError>{intl.formatMessage({ id: 'add_payment_method_error' })}</AddPaymentMethodError>
        </AddPaymentMethodErrorWrapper>
      )}
      <SaveCancelButtonContainer>
        <CancelButton
          data-testid="cancel-button"
          loading={isLoading}
          onClick={onCancel}
          text={intl.formatMessage({ id: 'cancel' })}
          variant="cancelButton"
        />
        <SaveButton
          data-testid="save-button"
          disabled={
            !isUserNameValid(firstName) ||
            !isUserNameValid(lastName) ||
            !isExpDateValid(expDate) ||
            !isZipCodeValid(zipCode) ||
            !spreedlyValidation.validCvv ||
            !spreedlyValidation.validNumber ||
            isLoading
          }
          loading={isLoading}
          onClick={handleOnClickSave}
          text={intl.formatMessage({ id: 'save' })}
          variant="secondary"
        />
      </SaveCancelButtonContainer>
    </AddCardContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  spreedlyInitializeStatus: selectSpreedlyInitializeStatus,
  spreedlyReloadStatus: selectSpreedlyReloadStatus,
  spreedlyTokenizeCreditCardStatus: selectSpreedlyTokenizeCreditCardStatus,
  spreedlyCreditCardConfig: selectSpreedlyCreditCardConfig,
  spreedlyReady: selectSpreedlyReady,
  spreedlyValidation: selectSpreedlyValidation,
  spreedlyErrors: selectSpreedlyErrors,
});

export default connect(mapStateToProps)(AddCard);
