import {
  ACTION_STATUSES,
  EXPAND_ADD_CARD,
  ORDER_TYPES,
  PAYMENT_MINIAPP_POST_MESSAGES,
  TRACK_EVENT_TYPES,
  TRACK_EVENTS,
} from 'shared/consts';
import {
  ButtonContainer,
  CloseButtonContainer,
  ContentContainer,
  ErrorLabel,
  ErrorWrapper,
  QuickCheckoutContent,
  StyledErrorIcon,
  StyledPaymentBlock,
  StyledPaymentMethod,
  StyledPriceBlock,
  StyledTitleBlock,
} from './styles';
import { CREDIT_PAYMENT_TYPE_ID, CREDIT_POINTS, INVOICE } from 'shared/consts/payment-method-types';
import { Item, Order, VERTICAL_API_CODES } from 'store/cart/types';
import { PaymentMethodTypes, SavedPaymentMethod } from 'store/payment/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { formatDate, getBookingStart } from 'utils/formatDate';
import { resetComplete3DSCart, submitCart } from 'store/cart/actions';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';
import {
  selectCartDiscountSummary,
  selectComplete3DSCartStatus,
  selectSubmitCartError,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
} from 'store/cart/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { CheckoutButton } from './checkoutButton';
import { CloseButton } from 'components/modals/checkoutModalWrapper/closeButton';
import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import { getPrice } from 'utils/getPrice';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { replace } from 'connected-react-router';
import { track } from '@hqo/web-tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { getTransaction } from 'store/transactions/actions';
import { selectGetTransactionStatus, selectTransaction } from 'store/transactions/selectors';
import { AppliedCreditsMethod } from './applied-credits-method';

interface Props {
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  setProcessPaymentFailed: () => void;
  isProcessPaymentFailed: boolean;
  currentPaymentMethodId: string;
  setOffsetHeight: (height: number) => void;
}

const renderCheckoutButton = (
  buttonText: string,
  isPaymentMethodsAvailable: boolean | number,
  isFreeCart: boolean,
  submitCartStatus: string,
  onCTAClick: () => void,
) => {
  return (
    <ButtonContainer isNext={!isPaymentMethodsAvailable} isFreeCart={isFreeCart}>
      <CheckoutButton
        text={buttonText}
        isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
        isDisabled={submitCartStatus === ACTION_STATUSES.PENDING || submitCartStatus === ACTION_STATUSES.FULFILLED}
        onClick={onCTAClick}
      />
    </ButtonContainer>
  );
};

export const Container = ({
  cart,
  paymentMethods,
  currentPaymentMethodId,
  setProcessPaymentFailed,
  isProcessPaymentFailed,
  setOffsetHeight,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const contentRef = useRef(null);
  const { showMiniappPaymentsNavigation } = useFlags();

  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const complete3DSCartStatus = useSelector(selectComplete3DSCartStatus);
  const cartError = useSelector(selectSubmitCartError);
  const locale = useLocale();
  const transaction = useSelector(selectTransaction);
  const transactionStatus = useSelector(selectGetTransactionStatus);

  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const discountSummary = useSelector(selectCartDiscountSummary);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);
  const { forPayment, isMobileDevice: isMobile } = useSearchParams();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const isMobileDevice = useMemo(
    () => (isMobile ? isMobile === 'true' : isSmallViewportWidth),
    [isMobile, isSmallViewportWidth],
  );
  const cartItemsIds = formatCartItemsIds(cart);

  useEffect(() => {
    if (showMiniappPaymentsNavigation && submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart.transaction_uuid, dispatch, showMiniappPaymentsNavigation, submitCartStatus]);

  const isPaymentMethodsAvailable = !!paymentMethods?.length;
  const isFreeCart = !cart.total_summary.total;

  const paymentMethod = useMemo<SavedPaymentMethod>(() => {
    const creditPointsMethod = paymentMethods?.find(method => method?.payment_method_type === CREDIT_POINTS);
    if (creditPointsMethod && creditPointsMethod.points_balance >= cart.total_summary.total) {
      return creditPointsMethod;
    }

    return paymentMethods?.find(
      method => method?.id?.toString() === currentPaymentMethodId || method?.payment_method_type === INVOICE,
    );
  }, [paymentMethods]);

  const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod) || {};
  const { eventWeekDay, eventDateMonthLong, formatEventStartTime, eventDateDay } = formatDate(
    getBookingStart(cart),
    locale as string,
    buildingTimeZone,
  );

  const onLeaveClick = useCallback(() => {
    window.parent.postMessage(`${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE}`, '*');
  }, []);

  const isVisiblePaymentMethodBlock =
    !isFreeCart ||
    (!isFreeCart && paymentMethod?.payment_method_type === INVOICE) ||
    cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const { locationId } = useSearchParams();

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED) {
      setProcessPaymentFailed();
    }
  }, [setProcessPaymentFailed, submitCartStatus]);

  useEffect(() => {
    const isTransactionResolved = showMiniappPaymentsNavigation
      ? transactionStatus === ACTION_STATUSES.FULFILLED
      : true;

    const hasSuccessfullyPurchasedWithCreditCard =
      (!submit3DSecure || paymentMethod?.id.toString() === CREDIT_PAYMENT_TYPE_ID) &&
      submitCartStatus === ACTION_STATUSES.FULFILLED &&
      isTransactionResolved;

    if (hasSuccessfullyPurchasedWithCreditCard && showMiniappPaymentsNavigation) {
      window.parent.postMessage(
        {
          message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_DONE_BUTTON_CLICK}`,
          transactionId: transaction?.details.id,
          transactionUuid: cart?.transaction_uuid,
        },
        '*',
      );
    }

    const shouldNavigateToReceipt =
      !(showMiniappPaymentsNavigation && isMobileDevice) ||
      cart?.config?.vertical?.api_code === VERTICAL_API_CODES.SERVICE_BOOKING;

    if (hasSuccessfullyPurchasedWithCreditCard && shouldNavigateToReceipt) {
      dispatch(replace(`/receipt${location.search}`));
    }
  }, [
    showMiniappPaymentsNavigation,
    submitCartStatus,
    dispatch,
    paymentMethod,
    isMobileDevice,
    cart,
    transactionStatus,
    transaction?.details.id,
  ]);

  useEffect(() => {
    if (contentRef.current) {
      window.parent.postMessage(
        {
          message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT}`,
        },
        '*',
      );
      setOffsetHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef, currentPaymentMethodId]);

  useEffect(() => {
    track(
      TRACK_EVENTS.QUICK_CHECKOUT_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, []);

  const onEditClick = useCallback(() => {
    if (showMiniappPaymentsNavigation && isMobileDevice) {
      window.parent.postMessage(
        {
          message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_EDIT_BUTTON_CLICK}`,
        },
        '*',
      );
    }
    dispatch(replace(`/checkout${location.search}`));
  }, [dispatch, location.search, showMiniappPaymentsNavigation, isMobileDevice]);

  const trackCheckoutClick = useCallback(() => {
    track(
      TRACK_EVENTS.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: paymentMethod?.payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, [cart, paymentMethod, cartItemsIds, currencyType, discountSummary]);

  const onCTAClick = useCallback(() => {
    if (
      forPayment &&
      forPayment === 'false' &&
      showMiniappPaymentsNavigation &&
      !isPaymentMethodsAvailable &&
      (!isFreeCart || cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP)
    ) {
      window.parent.postMessage(
        {
          message: `${PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_EDIT_BUTTON_CLICK}`,
        },
        '*',
      );
      dispatch(replace(`/checkout${location.search}`));
    } else if (isFreeCart && cart.type !== ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      trackCheckoutClick();
      dispatch(
        submitCart.request({
          cart_id: cart.id as string,
          locationId: locationId as string,
        }),
      );
    } else if (isPaymentMethodsAvailable) {
      trackCheckoutClick();
      const paymentInfo = {
        cart_id: cart.id as string,
        payment_method_id: paymentMethod?.id.toString(),
        locationId: locationId as string,
      };
      if (complete3DSCartStatus === ACTION_STATUSES.REJECTED) {
        dispatch(resetComplete3DSCart());
      }
      dispatch(
        submitCart.request(
          submit3DSecure
            ? {
                ...paymentInfo,
                browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
                attempt_3dsecure: true,
                three_ds_version: '2',
                callback_url: `${window.location.origin}/redirect${location.search}&paymentId=${paymentInfo.payment_method_id}`,
                redirect_url: `${window.location.origin}/redirect${location.search}&paymentId=${paymentInfo.payment_method_id}`,
              }
            : paymentInfo,
        ),
      );
    } else if (!isPaymentMethodsAvailable && (!isFreeCart || cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP)) {
      dispatch(replace(`/checkout${location.search}&${EXPAND_ADD_CARD}=true`));
    }
  }, [
    paymentMethod,
    cart,
    currentPaymentMethodId,
    dispatch,
    paymentMethods,
    complete3DSCartStatus,
    showMiniappPaymentsNavigation,
    trackCheckoutClick,
  ]);

  const resolveSubtitleId = () => {
    switch (cart.type) {
      case ORDER_TYPES.RESOURCE_BOOKING:
        return 'you_are_about_book_resource_booking';
      case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
        return 'you_are_about_book_membership';
      default:
        return 'you_are_about_book';
    }
  };

  const resolveCheckoutButtonText = () => {
    if (!isPaymentMethodsAvailable && (!isFreeCart || cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP)) {
      return 'add_payment_method';
    }
    if (cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      return isFreeCart ? 'confirm_and_checkout' : 'checkout_and_pay';
    }
    return 'book_service';
  };

  const resolveCheckoutItemSubtitleText = (item: Item) => {
    if (cart.type === ORDER_TYPES.RESOURCE_BOOKING) {
      return item.display_info.subtitle;
    }
    if (cart.type !== ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      return intl.formatMessage({ id: 'with' }, { instructorName: item.display_info.description1 });
    }
    return '';
  };

  return (
    <ContentContainer>
      <CloseButtonContainer>
        <CloseButton onClose={onLeaveClick} />
      </CloseButtonContainer>
      <QuickCheckoutContent className="quick-checkout-content" ref={contentRef}>
        <StyledTitleBlock
          spacing={20}
          title={intl.formatMessage({ id: 'checkout' })}
          subtitle={intl.formatMessage(
            { id: resolveSubtitleId() },
            cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP
              ? {
                  serviceName: cart.items?.[0]?.display_info.title,
                }
              : {
                  instructorName: cart.items?.[0]?.display_info?.description1,
                  serviceName: cart.items?.[0]?.display_info.title,
                  serviceTime: `${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
                    id: 'at',
                  })} ${formatEventStartTime}`,
                },
          )}
        />
        <StyledPriceBlock>
          {cart.items?.map(item => (
            <ItemQuantityBlock
              key={item.id}
              title={item.display_info.title}
              subtitle={resolveCheckoutItemSubtitleText(item)}
              price={getPrice(isFreeCart, cart, intl.formatMessage({ id: 'free' }), buildingLocale)}
            />
          ))}
        </StyledPriceBlock>
        <StyledPaymentBlock>
          {isVisiblePaymentMethodBlock && (
            <StyledPaymentMethod
              title={
                currentPaymentMethodId &&
                intl.formatMessage(
                  {
                    id: 'saved_payment_method_option',
                  },
                  {
                    cardBrand: capitalizedBrand,
                    cardLastFour: paymentMethod?.card_last_four,
                    cardExpMonth: formattedExpMonth,
                    cardExpYear: formattedExpYear,
                  },
                )
              }
              paymentMethod={paymentMethod?.payment_method_type as PaymentMethodTypes}
              withEditButton
              onEditClick={onEditClick}
            />
          )}
          <AppliedCreditsMethod cart={cart} />
          {isProcessPaymentFailed && (
            <ErrorWrapper>
              <ErrorLabel>{intl.formatMessage({ id: getCartErrorMessage(cartError) })}</ErrorLabel>
              <StyledErrorIcon size="sm" variant="pink" />
            </ErrorWrapper>
          )}
        </StyledPaymentBlock>
      </QuickCheckoutContent>
      {renderCheckoutButton(
        intl.formatMessage({ id: resolveCheckoutButtonText() }),
        isPaymentMethodsAvailable,
        isFreeCart,
        submitCartStatus,
        onCTAClick,
      )}
    </ContentContainer>
  );
};
