import styled from 'styled-components';

export const StyledPromoCodeLink = styled.button`
  background: none;
  border: none;
  padding: 0px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
  opacity: 0.5;
  ${({ disabled }) =>
    !disabled &&
    `
      opacity: 1;
      cursor: pointer;
      &:hover,
      &:focus {
        font-weight: 700;
        outline: none;
      }
    `}
`;
