import * as transactionsActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { TransactionState } from './types';

export const initialState: TransactionState = {
  transactionId: null,
  transaction: null,
  getTransaction: {
    status: null,
    error: null,
  },
  patchTransaction: {
    status: null,
    error: null,
  },
  getRedirectTransaction: {
    status: null,
    error: null,
  },
  redirectTransaction: null,
};

const getTransactionRequestHandler = (state: TransactionState): TransactionState => ({
  ...state,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getTransactionSuccessHandler = (
  state: TransactionState,
  { payload }: ActionType<typeof transactionsActions.getTransaction.success>,
): TransactionState => ({
  ...state,
  transaction: payload,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getTransactionFailureHandler = (
  state: TransactionState,
  { payload: error }: ActionType<typeof transactionsActions.getTransaction.failure>,
): TransactionState => ({
  ...state,
  getTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getRedirectTransactionRequestHandler = (state: TransactionState): TransactionState => ({
  ...state,
  getRedirectTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getRedirectTransactionSuccessHandler = (
  state: TransactionState,
  { payload }: ActionType<typeof transactionsActions.getRedirectTransaction.success>,
): TransactionState => ({
  ...state,
  redirectTransaction: payload,
  getRedirectTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getRedirectTransactionFailureHandler = (
  state: TransactionState,
  { payload: error }: ActionType<typeof transactionsActions.getRedirectTransaction.failure>,
): TransactionState => ({
  ...state,
  getRedirectTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const patchTransactionRequestHandler = (state: TransactionState): TransactionState => ({
  ...state,
  patchTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const patchTransactionSuccessHandler = (state: TransactionState): TransactionState => ({
  ...state,
  patchTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const resetPatchTransaction = (state: TransactionState): TransactionState => ({
  ...state,
  patchTransaction: {
    error: null,
    status: null,
  },
});

const patchTransactionFailureHandler = (
  state: TransactionState,
  { payload: error }: ActionType<typeof transactionsActions.patchTransaction.failure>,
): TransactionState => ({
  ...state,
  patchTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getUserTransactionRequestHandler = (state: TransactionState): TransactionState => ({
  ...state,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getUserTransactionSuccessHandler = (
  state: TransactionState,
  { payload }: ActionType<typeof transactionsActions.getUserTransaction.success>,
): TransactionState => ({
  ...state,
  transaction: payload,
  getTransaction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getUserTransactionFailureHandler = (
  state: TransactionState,
  { payload: error }: ActionType<typeof transactionsActions.getUserTransaction.failure>,
): TransactionState => ({
  ...state,
  getTransaction: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const transactionReducer = createReducer(initialState)
  .handleAction(transactionsActions.getTransaction.request, getTransactionRequestHandler)
  .handleAction(transactionsActions.getTransaction.success, getTransactionSuccessHandler)
  .handleAction(transactionsActions.getTransaction.failure, getTransactionFailureHandler)
  .handleAction(transactionsActions.getRedirectTransaction.request, getRedirectTransactionRequestHandler)
  .handleAction(transactionsActions.getRedirectTransaction.success, getRedirectTransactionSuccessHandler)
  .handleAction(transactionsActions.getRedirectTransaction.failure, getRedirectTransactionFailureHandler)
  .handleAction(transactionsActions.patchTransaction.request, patchTransactionRequestHandler)
  .handleAction(transactionsActions.patchTransaction.success, patchTransactionSuccessHandler)
  .handleAction(transactionsActions.patchTransaction.failure, patchTransactionFailureHandler)
  .handleAction(transactionsActions.resetPatchTransactionStatus, resetPatchTransaction)
  .handleAction(transactionsActions.getUserTransaction.request, getUserTransactionRequestHandler)
  .handleAction(transactionsActions.getUserTransaction.success, getUserTransactionSuccessHandler)
  .handleAction(transactionsActions.getUserTransaction.failure, getUserTransactionFailureHandler);

export default transactionReducer;
