import React from 'react';
import { Router } from 'components/router';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from 'hocs/shared-styles';

import { usePendo } from 'hooks/use-pendo.hook';
import { usePostMessageInit } from 'hooks/use-postMessage-init.hook';

export const App: React.FC = (): JSX.Element => {
  const shouldWaitForLogin = useExternalLogin();
  usePendo();
  usePostMessageInit();

  if (shouldWaitForLogin) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return <Router />;
};
