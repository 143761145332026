import { IFrameErrorSpreedly, SpreedlyError } from '../add-card/types';

import { ErrorNotification } from './types';
import { createAction } from 'typesafe-actions';

export const showErrorNotification = createAction('notifications/SHOW_ERROR')<Omit<ErrorNotification, 'uuid'>>();
export const hideErrorNotifications = createAction('notifications/HIDE_ERRORS')<void>();

export const showGenericErrorNotification = createAction('notifications/SHOW_GENERIC_ERROR')<
  Omit<ErrorNotification, 'uuid'> | IFrameErrorSpreedly | Array<SpreedlyError>
>();
export const hideGenericErrorNotifications = createAction('notifications/SHOW_GENERIC_ERRORS')<void>();
