import { useSearchParams } from 'hooks/use-search-params.hook';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { AuthParams } from 'shared/types';
import { getCurrentUser, postMessageLogin } from 'store/user/actions';
import { User } from 'store/user/types';

export const usePostMessageInit = (): void => {
  const dispatch = useDispatch();
  const { authToken, apiUrl } = useSearchParams<AuthParams>();

  const onMessage = (event: MessageEvent) => {
    if (event.data?.message?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_INIT_RESPONSE) {
      dispatch(postMessageLogin({ authToken, apiUrl }));
      dispatch(getCurrentUser.success({ user: event.data?.message?.user as unknown as User }));
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    window.parent.postMessage(
      {
        message: PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_INIT,
      },
      '*',
    );
  }, []);
};
