import { CartState } from './types';
import { ACTION_STATUSES, DEFAULT_3DSECURE_VERSION } from 'shared/consts';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const cartSelector = (state: RootState): CartState => state.cart;

export const selectCart = createSelector([cartSelector], cart => cart.cart);

export const selectCartId = createSelector([selectCart], cart => cart.id);

export const selectTriggerTransaction = createSelector([cartSelector], cart => cart.transaction);

export const selectGetCartStatus = createSelector([cartSelector], cart => cart.getCart.status);

export const selectGetCartError = createSelector([cartSelector], cart => cart.getCart.error);

export const selectSubmitCartStatus = createSelector([cartSelector], cart => cart.submitCart.status);

export const selectSubmitCartError = createSelector([cartSelector], cart => cart.submitCart.error);

export const selectComplete3DSCartStatus = createSelector([cartSelector], cart => cart.complete3DSCart.status);

export const selectComplete3DSCartError = createSelector([cartSelector], cart => cart.complete3DSCart.error);

export const select3DSecure = createSelector([selectCart], cart => {
  return {
    is3DSecure: !!cart?.config?.payment_gateway?.supports_3dsecure,
    version: cart?.config?.payment_gateway?.version_3dsecure ?? DEFAULT_3DSECURE_VERSION,
  };
});

export const selectConfigPaymentGatewayId = createSelector([selectCart], cart => {
  return cart?.config?.payment_gateway_id;
});

export const shouldSubmit3DSecure = createSelector(
  [selectCart],
  cart => !!cart?.config?.payment_gateway?.supports_3dsecure && cart?.total_summary?.total > 0,
);

export const select3DSTransactionState = createSelector([selectCart], cart => cart?.transaction?.state);

export const select3DSTransactionToken = createSelector([selectCart], cart => cart?.transaction?.token);

export const selectConfigEnablePromotions = createSelector(
  [selectCart],
  cart => cart?.config?.config?.enable_promotions,
);

export const selectApplyPromoCodeToCartStatus = createSelector(
  [cartSelector],
  cart => cart?.applyPromoCodeToCart?.status,
);

export const selectIsApplyPromoCodeToCartStatusFulfilled = createSelector(
  [selectApplyPromoCodeToCartStatus],
  applyPromoCodeToCartStatus => applyPromoCodeToCartStatus === ACTION_STATUSES.FULFILLED,
);

export const selectIsApplyPromoCodeToCartStatusRejected = createSelector(
  [selectApplyPromoCodeToCartStatus],
  applyPromoCodeToCartStatus => applyPromoCodeToCartStatus === ACTION_STATUSES.REJECTED,
);

export const selectIsApplyPromoCodeToCartStatusPending = createSelector(
  [selectApplyPromoCodeToCartStatus],
  applyPromoCodeToCartStatus => applyPromoCodeToCartStatus === ACTION_STATUSES.PENDING,
);

export const selectCartDiscountSummary = createSelector(
  [cartSelector],
  cart => cart?.cart?.total_summary?.discount_summary,
);

export const selectRemovePromoCodeFromCartStatus = createSelector(
  [cartSelector],
  cart => cart?.removePromoCodeFromCart?.status,
);

export const selectIsRemovePromoCodeFromCartStatusFulfilled = createSelector(
  [selectRemovePromoCodeFromCartStatus],
  removePromoCodeFromCartStatus => removePromoCodeFromCartStatus === ACTION_STATUSES.FULFILLED,
);

export const selectIsRemovePromoCodeFromCartStatusRejected = createSelector(
  [selectRemovePromoCodeFromCartStatus],
  removePromoCodeFromCartStatus => removePromoCodeFromCartStatus === ACTION_STATUSES.REJECTED,
);

export const selectIsRemovePromoCodeFromCartStatusPending = createSelector(
  [selectRemovePromoCodeFromCartStatus],
  removePromoCodeFromCartStatus => removePromoCodeFromCartStatus === ACTION_STATUSES.PENDING,
);
