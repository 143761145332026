import { ACTION_STATUSES, TRANSACTION_TYPES } from 'shared/consts';
import { HistoricalReceiptOverview, HistoricalReceiptSummary } from 'pages/historicalReceipt/components/index';
import {
  HistoricalReceiptContainer,
  ButtonContainer,
  CancellationExpiredText,
  DoublePane,
  LeftPane,
  RightPane,
} from '../styles';
import React from 'react';
import { DefaultLoader } from 'components/default-loader';
import { ITEM_TYPE, TransactionDetails } from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from 'components/cancel-button';

interface SwipeHistoricalReceiptContentProps {
  isShown: boolean;
  itemId: string;
  itemType: ITEM_TYPE;
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionStatus: ACTION_STATUSES;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
}

export const SwipeHistoricalReceiptContent = ({
  isShown,
  isSwipeModalContent,
  itemId,
  itemType,
  isCancellationExpiredState,
  onCancelBookingClick,
  patchTransactionStatus,
  transaction,
}: SwipeHistoricalReceiptContentProps) => {
  const intl = useIntl();
  const isCancellationExpiredTextVisible =
    transaction?.details?.cancellation_window_end_minutes >= 0 &&
    transaction?.details?.cancellation_window_end_minutes !== null;

  return (
    <>
      {!isShown ? (
        <DefaultLoader />
      ) : (
        <HistoricalReceiptContainer data-testid="historical-receipt-swipe-modal">
          {itemId && itemType && transaction && (
            <>
              <DoublePane isCancellationExpiredTextVisible={isCancellationExpiredTextVisible}>
                <LeftPane className="left-pane">
                  <HistoricalReceiptOverview isSwipeModalContent={isSwipeModalContent} transaction={transaction} />
                </LeftPane>
                <RightPane className="right-pane">
                  <HistoricalReceiptSummary isSwipeModalContent={isSwipeModalContent} transaction={transaction} />
                </RightPane>
              </DoublePane>
              <ButtonContainer isButtonLabelVisible className="button-container" data-testid="double-pane-button">
                {isCancellationExpiredTextVisible && (
                  <CancellationExpiredText>
                    {intl.formatMessage(
                      { id: 'notice_required' },
                      { hours: transaction.details?.cancellation_window_end_minutes / 60 },
                    )}
                  </CancellationExpiredText>
                )}
                {transaction.type === TRANSACTION_TYPES.RESOURCES_BOOKING && (
                  <CancelButtonBlock
                    onCancel={onCancelBookingClick}
                    status={patchTransactionStatus}
                    expired={isCancellationExpiredState}
                    isReceiptCTA
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </HistoricalReceiptContainer>
      )}
    </>
  );
};
