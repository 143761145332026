import { BackButtonContainer, BackIcon, TextContainer } from '../styles';
import React, { FunctionComponent } from 'react';

interface Props {
  onPressBack?: VoidFunction;
}

export const BackButton: FunctionComponent<Props> = ({ onPressBack }: Props) => {
  return (
    <BackButtonContainer onClick={onPressBack}>
      <BackIcon icon={['fas', 'arrow-left']} data-testid="modal-wrapper-back-button" />
      <TextContainer>Back</TextContainer>
    </BackButtonContainer>
  );
};
